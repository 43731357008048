.meister {
  display: flex;
  flex-direction: column;
  position: relative;
  overflow: hidden;
  .go-back-arrow {
    z-index: 10;
    left: 16px;
    top: 16px;
    position: absolute;
  }
  .meister-slider-alt-image {
    width: 100%;
    max-width: 750px;
    min-width: 375px;
    img {
      display: block;
      width: 100%;
    }
  }
  .meister-content {
    transform: translateY(-5vh);
    > div {
      margin: 0 16px;
    }
    // >ul{

    //   margin:0
    // }
    .gray-bar {
      margin: 0;
      font-size: 0;
      text-indent: -9999px;
      height: 7px;
      background-color: rgba(247, 248, 249, 1);
    }
    .gray-thin-bar {
      margin: 0;
      font-size: 0;
      text-indent: -9999px;
      height: 1px;
      background-color: $grey-1;
    }
  }

  // .carousel{
  //   position: relative;
  //   margin:0;
  //   overflow:scroll;
  //   .carousel-point{
  //     z-index: 10;
  //     right:5%;
  //     bottom:40%;
  //     position: absolute;
  //     width:36px;
  //     height:19px;
  //     line-height:21px;
  //     border-radius: 9.5px;
  //     letter-spacing: 1.5px;
  //     background-color:rgba(0,0,0,0.7);
  //     color:$white;
  //     text-align: center;
  //   }
  // .go-back-arrow{
  //   z-index: 10;
  //   left:5%;
  //   top:20%;
  //   position: absolute;
  // }
  //   ul{
  //     display:flex;
  //     position: relative;
  //     &:after{
  //       content:"";
  //       display:block;
  //       clear:both;
  //     }

  //     li{
  //       &.error-image{
  //         display:none;
  //       }
  //       float:left;
  //       display: flex;
  //       max-width: 750px;
  //       // min-width: 375px;
  //       flex-basis: 375px;
  //       flex-grow: 1;

  //       p{
  //         img{
  //           display:block;
  //           width:100%;
  //         }
  //       }

  //     }
  //   }
  // }

  .meister-head {
    position: relative;
    .carousel-point {
      z-index: 10;
      right: 0;
      top: -40px;
      position: absolute;
      width: 48px;
      height: 27px;
      line-height: 31px;
      border-radius: 13.5px;
      letter-spacing: 1.5px;
      background-color: rgba(0, 0, 0, 0.7);
      color: $white;
      text-align: center;
    }
    .meister-profile {
      padding: 24px 0;
      box-shadow: 0 2px 8px rgba(149, 149, 149, 0.11);
      border-radius: 10px;
      background-color: $white;
      text-align: center;
      .meister-name {
        font-family: "Pretendard-SemiBold";
        font-size: 26px;
        line-height: 40px;
        color: $grey-9;
        letter-spacing: -0.78px;
      }
      .meister-solt {
        font-family: "Pretendard-Medium";
        color: $grey-5;
        line-height: 17px;
        font-size: 14px;
        margin: 9px 0 22px;
      }
      .meister-desc {
        font-family: "Pretendard-Regular";
        margin: 0 38px;
        font-size: 13px;
        text-align: left;
        line-height: 20px;
        letter-spacing: -0.32px;
        color: $grey-8;
      }
    }
    .most-repair {
      padding-top: 30px;
      .main-title {
        p {
          &:first-child {
            font-family: "Pretendard-SemiBold";
            font-size: 18px;
            line-height: 24px;
            letter-spacing: -0.45px;
            color: $grey-9;
          }
          &:last-child {
            width: 24px;
            img {
              display: block;
              width: 100%;
            }
          }
          display: inline-block;
          vertical-align: top;
        }
      }
      .most-cate {
        padding: 16px 0 14px;
        li {
          display: inline-block;
          margin-right: 5px;
          margin-bottom: 7px;
          padding: 0 11px;
          line-height: 32px;
          text-align: center;
          font-size: 13px;
          letter-spacing: -0.39px;
          color: $grey-8;
          border-radius: 11px;
          border: 1px solid $grey-1;
        }
      }
    }
  }
  .meister-review {
    .main-title {
      margin-top: 18px;
      font-family: "Pretendard-SemiBold";
      font-size: 18px;
      line-height: 25px;
      letter-spacing: -0.45px;
      color: $grey-9;
      span {
        display: inline-block;
        margin-left: 4px;
        font-family: "Pretendard-Regular";
        color: $grey-5;
        letter-spacing: -0.54px;
      }
    }
    .review-point {
      &:after {
        content: "";
        display: block;
        clear: both;
      }
      display: flex;
      align-items: center;
      margin-top: 25px;
      .star-area {
        width: 39%;
        float: left;
        text-align: center;
        margin-top: 20px;
        .point-avg {
          span {
            &.total-point {
              color: $grey-2;
            }
            &.dash {
              color: $grey-2;
              margin: 0 5px;
            }
            font-family: "Pretendard-Bold";
            font-size: 26px;
            color: $grey-9;
            display: inline-block;
          }
        }
        .star-avg {
          margin-top: 5px;
          p {
            display: inline-block;
            width: 20px;
            img {
              display: block;
              width: 100%;
            }
          }
        }
      }
      .graph-area {
        width: 59%;
        float: left;
        text-align: center;
        display: flex;
        justify-content: space-around;
        .garph-point {
          display: inline-block;
          margin-right: 12px;
          padding-top: 18px;
          position: relative;
          .bg-bar {
            position: relative;
            height: 50px;
            text-align: center;
            margin-bottom: 6px;

            p {
              &.gray-bg {
                background-color: $grey-1;
                height: 50px;
              }
              &.active-bg {
                transition: height 0.3s linear;
                height: 0;
                background-color: $main-purple-0;
              }
              right: 3px;
              bottom: 0;
              position: absolute;
              width: 5px;
              border-radius: 5px;
              display: inline-block;
              font-size: 0;
              text-indent: -9999px;
            }
          }
          .most-user-gived {
            position: absolute;
            top: 1px;
            left: -4px;
            width: 24px;
            line-height: 18px;
            text-align: center;
            background-color: $main-purple-0;
            color: $white;
            font-family: "Pretendard-Medium";
            font-size: 11px;
            letter-spacing: -0.33px;
            border-radius: 9px;
          }
          > p {
            text-align: center;
            font-family: "Pretendard-Regular";
            color: $grey-8;
            letter-spacing: -0.3px;
            font-size: 10px;
          }
        }
      }
      .vertical-gray-bar {
        float: left;
        font-size: 0;
        text-indent: -9999px;
        width: 1px;
        margin: 0 0.5%;
        height: 30px;
        background-color: $grey-1;
      }
    }
    .review-desc {
      margin-top: 30px;
      .desc {
        display: flex;
        flex-direction: row;
        align-items: center;
        margin-bottom: 10px;
        > p {
          &:last-child {
            font-size: 13px;
            color: $grey-6;
            letter-spacing: -0.39px;
          }
          display: inline-block;
          vertical-align: middle;
          font-family: "Pretendard-Medium";
        }
        p:first-child ~ p {
          font-size: 15px;
          line-height: 26px;
        }

        .review-tag {
          width: 56px;
          line-height: 24px;
          margin-right: 6px;

          letter-spacing: -0.33px;
          font-size: 11px;
          text-align: center;
          border-radius: 12px;
          border: 1px solid $main-purple-light-4;

          color: $main-purple-0;
        }
        .dotted-pattern {
          width: 122px;
          height: 1px;
          margin: 0 10px;
          flex-grow: 1;
          font-size: 0;
          text-indent: -9999px;
          border-top: 1px dotted $grey-2;
        }
        .review-percentage {
          margin-right: 3px;
        }
      }
    }
  }
  .repair-history {
    .title-area {
      padding: 18px 0 13.5px;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      > p {
        font-family: "Pretendard-SemiBold";
        font-size: 18px;
        letter-spacing: -0.45px;
        color: $grey-9;
        span {
          font-size: 18px;
          color: $grey-5;
          letter-spacing: -0.54px;
          font-family: "Pretendard-Regular";
        }
      }
      > div {
        display: flex;
        flex-direction: row;
        cursor: pointer;
        p {
          &:first-child {
            width: 24px;

            img {
              width: 100%;
              display: block;
            }
          }
          &:last-child {
            line-height: 24px;
            font-size: 14px;
            font-family: "Pretendard-Medium";
            letter-spacing: -0.42px;
            color: $grey-8;
          }
        }
      }
    }
  }
  .history-area {
    // padding-top:23.5px;
    margin: 0;
    > li {
      border-bottom: 1px solid rgba(232, 236, 237, 1);
      padding: 0 16px;
    }
    .history-title {
      padding-top: 23.5px;
      display: flex;
      align-items: center;
      &:after {
        content: "";
        display: block;
        clear: both;
      }
      .brand-logo {
        width: 50px;
        float: left;
        img {
          display: block;
          width: 100%;
        }
      }
      > div {
        float: left;
        margin-left: 14px;
        .sub-title {
          font-family: "Pretendard-SemiBold";
          font-size: 16px;
          letter-spacing: -0.4px;
          color: $grey-9;
        }
        .history-date {
          margin-top: 5px;
          color: $grey-5;
          font-size: 11px;
          letter-spacing: -0.28px;
        }
      }
    }
    .history-desc {
      .text {
        margin: 20px 0 13px;
        font-family: "Pretendard-Regular";
        font-size: 13px;
        letter-spacing: -0.32px;
        color: $grey-8;
      }
      ul {
        li {
          display: inline-block;
          margin-right: 5px;
          margin-top: 7px;
          padding: 0 11px;
          line-height: 32px;
          text-align: center;
          font-size: 13px;
          letter-spacing: -0.39px;
          color: $grey-9;
          border-radius: 4px;
          border: 1px solid $grey-1;
        }
      }
    }
    .history-carousel {
      // overflow-x:scroll;
      margin-top: 24px;
      margin-bottom: 47px;
      .before-after {
        display: flex;
        flex-wrap: nowrap;
        .slick-slider {
          width: 100%;
          img {
            border-radius: 6px;
          }
        }
        .before-after-slider {
          &:after {
            content: "";
            display: block;
            clear: both;
          }
          width: 320px;
          flex-shrink: 0;
          margin-right: 10px;
          > div {
            float: left;
            position: relative;
            // width: calc(100vw - 32px);
            background-color: white;
            p {
              &.before-icon {
                width: 44px;
                position: absolute;
                left: 8px;
                border-radius: 8.5px;
                background-color: rgba(0, 0, 0, 0.5);
                &.off {
                  display: none;
                }
              }
              &.after-icon {
                width: 44px;
                position: absolute;
                right: 8px;
                border-radius: 8.5px;
                background-color: rgba(0, 0, 0, 0.5);
                &.off {
                  display: none;
                }
              }
              &.img {
                // width: fit-content;
                // position: relative;
                margin: auto;
                img {
                  width: 100%;
                  // width: fit-content;
                  display: block;
                  // max-width: calc(100vw - 32px);
                  // max-height: calc((100vw - 32px) / 4 * 3);
                  aspect-ratio: 4 / 3;
                  object-fit: cover;
                }
              }
              text-align: center;
              line-height: 17px;
              font-size: 10px;
              color: $white;
              bottom: 8px;
            }
          }
        }
        // li{
        //   &:after{
        //     content: "";
        //     display:block;
        //     clear:both;
        //   }
        //   width:320px;
        //   flex-shrink: 0;
        //   margin-right:10px;
        //   >div {
        //     float:left;
        //     position: relative;
        //     p{
        //       &.before-icon{
        //         width:44px;
        //         position: absolute;
        //         left:8px;
        //         border-radius: 8.5px;
        //         background-color:rgba(0,0,0,0.5);
        //       }
        //       &.after-icon{
        //         width:44px;
        //         position: absolute;
        //         right:8px;
        //         border-radius: 8.5px;
        //         background-color:rgba(0,0,0,0.5);
        //       }
        //       &.img{
        //         img{
        //           width:100%;
        //           display: block;
        //         }
        //       }
        //       text-align: center;
        //       line-height:17px;              font-size:10px;
        //       color:$white;
        //       bottom:8px;
        //     }
        //   }

        // }
      }
    }
    .slick-dots {
      // text-align: center;
      li {
        margin-left: 3px;
        width: 4px;
        height: 0px;
        border-radius: 2px;
        button {
          &:before {
            content: "";
            width: 6px;
            height: 6px;
            border-radius: 3px;
            background-color: $grey-9;
          }
          margin-right: 2px;
          // background-color: $grey-9;

          width: 6px;
          height: 6px;
          border-radius: 3px;
          padding: 0;
        }
      }
    }
  }
}
.meister-history-slider {
  z-index: 50;
  position: fixed;
  left: 50%;
  top: 50%;
  max-width: 750px;
  min-width: 375px;
  width: 100%;
  transform: translate(-50%, -50%);
  background-color: rgba(0, 0, 0, 1);
  .review-slider {
    // position: fixed;
    // left:50%;
    // top:50%;
    // transform: translate(-50%,-50%);
    height: 100vh;
    .review-slider-title {
      position: absolute;
      width: 100%;
      text-align: center;
      z-index: 10000;
      background-color: #000;
      height: 50px;
      .go-back-arrow {
        position: absolute;
        left: 10px;
        top: 10px;
      }
      .slider-point {
        font-size: 18px;
        color: #fff;
        display: inline-block;
        padding-top: 13px;
      }
    }
    .slick-slider {
      left: 50%;
      top: calc(50% + 50px);
      transform: translate(-50%, -50%);
    }
    img {
      width: 100%;
    }
  }
}
