.modify-member {
  width: 100%;
  height: 100%;
  color: $grey-9;

  .login-type {
    min-height: 92px;
    display: flex;
    flex-direction: column;
    border-bottom: 1px solid $grey-1;
    // box-shadow: 0 2px 2px 0 rgba($grey-8, 0.2);
    padding: 12px 16px;

    .title {
      font-size: 16px;
      line-height: 25px;
      letter-spacing: -0.4px;
      font-family: "Pretendard-SemiBold";
      margin-bottom: 16px;
    }

    .body-wrapper {
      display: flex;
      align-items: center;

      .body {
        font-size: 15px;
        line-height: 24px;
        letter-spacing: -0.38px;
        font-family: "Pretendard-Medium";
        margin-left: 4px;
      }
    }
  }

  .box-line {
    width: 100%;
    height: 8px;
    background-color: $grey-0;
  }

  .member-info {
    min-height: 210px;
    display: flex;
    flex-direction: column;
    padding: 12px 16px;
    border-bottom: 1px solid $grey-1;
    // box-shadow: 0 2px 2px 0 rgba($grey-8, 0.2);

    .title {
      font-size: 16px;
      line-height: 25px;
      letter-spacing: -0.4px;
      font-family: "Pretendard-SemiBold";
      margin-bottom: 24px;
    }

    .body {
      display: flex;
      font-size: 15px;
      line-height: 24px;
      letter-spacing: -0.38px;
      font-family: "Pretendard-Regular";
      color: $grey-8;
      margin-bottom: 16px;

      p {
        min-width: 50px;
        &:last-child {
          margin-left: 36px;
        }
      }

    }
  }
  
  .change, .withdraw {
    padding: 24px 16px 8px;
    font-size: 14px;
    font-family: "Pretendard-Medium";
  }
}