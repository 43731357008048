.mypage {
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: calc(var(--vh, 1vh) * 100);
  padding-bottom: 48px;

  .userInfo {
    width: 100%;
    min-height: 190px;
    border-bottom: 1px solid $grey-1;
    // border-bottom: 1px solid;
    // box-shadow: 0 2px 2px 0 rgba($grey-8, 0.2);
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 24px 0 4px;
    flex: 0 0 auto;

    .user {
      font-size: 18px;
      line-height: 29px;
      letter-spacing: -0.45px;
      color: $grey-9;
      font-family: "Pretendard-Medium";
      padding: 0 24px;

      .user-name {
        font-family: "Pretendard-Bold";
      }
    }

    .link-login {
      font-size: 18px;
      line-height: 29px;
      letter-spacing: -0.4px;
      font-family: "Pretendard-Bold";
      padding: 0 24px;
      display: flex;
      align-items: center;
    }

    .info {
      display: flex;
      min-width: 327px;
      // width: 100%;
      min-height: 80px;
      height: 100%;
      // padding: 12px;
      margin: 24px;
      font-size: 13px;
      color: $grey-6;
      font-family: "Pretendard-Regular";
      border: 1px solid $grey-1;
      border-radius: 8px;

      .cash {
        width: 50%;
        margin: 16px;

        .info-coin {
          display: flex;
          align-items: center;
        }
        .point {
          display: flex;
          justify-content: flex-end;
          font-size: 18px;
          color: $grey-9;
          font-family: "Pretendard-Bold";
        }
      }
      .coupon {
        width: 50%;
        margin: 16px;
        border-left: 1px solid $grey-1;
        padding-left: 16px;

        .info-coin {
          display: flex;
          align-items: center;
        }

        .point {
          display: flex;
          justify-content: flex-end;
          font-size: 18px;
          color: $grey-9;
          font-family: "Pretendard-Bold";
        }
      }
    }
  }

  .box-line {
    width: 100%;
    height: 8px;
    background-color: $grey-0;
    flex: 0 0 auto;
  }

  .tab-menu {
    flex: 0 0 auto;
    width: 100%;
    height: fit-content;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0 24px;
    border-bottom: 1px solid $grey-1;
    // box-shadow: 0 2px 2px 0 rgba($grey-8, 0.2);

    .menu {
      width: 100%;
      height: 50px;
      // border-bottom: 1px solid $grey-5;
      line-height: 50px;
      cursor: pointer;
    }
  }

  .version {
    flex: 1 0 auto;
    flex-direction: column;
    width: 100%;
    background-color: $grey-0;
    display: flex;
    row-gap: 18px;
    padding: 18px 24px;
    a {
      width: fit-content;
    }
    span {
      width: fit-content;
      font-family: "Pretendard-Regular";
      font-size: 13px;
      color: $grey-5;
    }
  }
}
