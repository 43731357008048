.user-guide {
  width: 100%;
  background-color: $white;
  padding-bottom: 24px;

  .up-btn {
    position: fixed;
    // bottom: 132px;
    bottom: 100px;
    // right: 14px;
    right: 34px;
    z-index: 20;
    background-color: $grey-2;
    opacity: 0.5;
    border-radius: 50%;
    border: none;
    width: 42px;
    height: 42px;

    &.mobile {
      // color: red;
      bottom: 156px;
      right: 20px;
    }
  }

  .title {
    font-size: 28px;
    line-height: 36px;
    letter-spacing: -0.7px;
    color: $grey-9;
    font-family: "Pretendard-Medium";
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 44px 0 50px;
    text-align: center;
    padding: 0 16px;

    .body {
      font-size: 14px;
      line-height: 22px;
      letter-spacing: -0.35px;
      color: $grey-9;
      font-family: "Pretendard-Regular";
      min-width: 292px;
      height: 39px;
      margin-top: 18px;
    }
  }

  .content {
    .unit {
      display: flex;
      flex-direction: column;
      align-items: center;
      font-size: 14px;
      line-height: 22px;
      letter-spacing: -0.35px;
      font-family: "Pretendard-Regular";
      color: $grey-8;
      text-align: center;
      margin-bottom: 52px;

      .step {
        font-size: 16px;
        // line-height: 46px;
        letter-spacing: -0.4px;
        font-family: "Pretendard-Bold";
        color: $main-purple-dark-1;
        border-bottom: 2px solid $main-purple-dark-1;
        margin-bottom: 30px;
      }
      .text {
        line-height: 36px;
        font-size: 24px;
        letter-spacing: -0.6px;
        font-family: "Pretendard-Bold";
        color: $grey-9;
        margin-bottom: 18px;
      }
      .sub {
        font-size: 11px;
        line-height: 22px;
        letter-spacing: -0.28px;
        color: $grey-5;
      }
    }
  }

  .entered-btn {
    min-width: 343px;
    height: 56px;
    background-color: $main-purple-0;
    color: $white;
    border-radius: 6px;
    font-size: 16px;
    line-height: 25px;
    letter-spacing: -0.16px;
    font-family: "Pretendard-SemiBold";
    margin: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .entered-wrapper {
    // border-top: 1px solid $grey-1;
    background-color: $white;
    position: fixed;
    bottom: 0;
    min-width: 343px;
    width: 100%;
    max-width: 750px;
    min-height: 72px;
    z-index: 10;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 16px;

    .request-entered {
      width: 100%;
      min-height: 48px;
      border: none;
      border-radius: 4px;
      font-size: 16px;
      line-height: 24px;
      font-family: "Pretendard-Medium";
      background-color: $main-purple-dark-1;
      color: $white;
      cursor: pointer;
    }
  }
}
