.id-pw-find{
  display:none;
  position:fixed;
  top:0;
  // left:50%;
  // top:50%;
  // transform:translate(-50%,-50%);
  background-color: $white;
  z-index:30;
  max-width: 750px;
  width:100%;
  height:100%;
  overflow-y:scroll;
  .id-pw-find-header{
    height:56px;
    padding:0 16px;
    display:flex;
    flex-direction: row;
    justify-content: space-between;
    >div{
      width:24px;
      height:24px;
      margin:16px 0;
      background-image: url("../../assets/img/icBack24Black.svg");
      cursor:pointer;
    }
  }
  .id-find{
    padding-top:46px;
    
  }
  .user-id-pw-find{
    max-width:375px;
    margin:0 auto 79px;
    .main-title{
      font-family:"Pretendard-Bold";
      font-size:25px;
      letter-spacing: -0.75px;
      color:$grey-9;
      line-height:40px;
      margin-bottom:24px;
    }
    >div{
      width:331px;
      margin:0 auto;
      .input-area{
        position:relative;
        label{
          position:absolute;
          top:11px;
          right:8px;
        }
      }
      input{
        &:focus{
          border-color:$main-purple-light-4;
        }
        width:100%;
        border:1px solid $grey-1;
        color:$grey-5;
        font-family:"Pretendard-Regular";
        font-size:15px;
        letter-spacing: -0.45px;
        line-height:44px;
        text-indent:15px;
        margin-bottom:8px;
        caret-color: $main-purple-dark-1;
        outline:none;
        border-radius: 4px;
      }
      input[type="tel"]{
        margin-bottom:14px;
      }
      input[type="button"]{
        background-color:$main-purple-dark-1;
        color:$white;
        font-family:"Pretendard-SemiBold";
        border-radius: 7px;
        cursor:pointer;
        margin-bottom:0;
      }
      .find-result{
        font-size:15px;
        font-family:"Pretendard-Regular";
        color:$blue-500;
        letter-spacing: -0.45px;
        margin-top:16px;
        span{
          display:inline-block;
          font-family:"Pretendard-SemiBold";
        }
      }
      .find-result-error{
        font-size:15px;
        font-family:"Pretendard-Regular";
        color:$blue-500;
        letter-spacing: -0.45px;
        margin-top:16px;
        color:$red-500;
      }
    }
  }
}