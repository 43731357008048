.Promotion {
  .contents-list {
    padding: 16px;
  }
}

.PromotionItem {
  cursor: pointer;
  .img {
    width: 100%;
    overflow: hidden;
    margin-bottom: 12px;
    position: relative;
    img {
      width: 100%;
      border-radius: 8px;
    }
    .blur {
      width: 100%;
      height: 100%;
      position: absolute;
      left: 0;
      top: 0;
      background: rgba(255, 255, 255, 0.7);
      display: flex;
      justify-content: center;
      align-items: center;
      line-height: 20px;
      font-size: 14px;
      letter-spacing: -0.5px;
      font-family: "Pretendard-Medium";
      color: $grey-900;
    }
  }
  .title {
    font-family: "Pretendard-Bold";
    font-size: 16px;
    line-height: 26px;
    letter-spacing: -0.5px;
    margin-bottom: 4px;
    color: $grey-900;
  }
  .date {
    font-family: "Pretendard-Regular";
    font-size: 12px;
    line-height: 16px;
    color: $grey-700;
    padding-bottom: 16px;
    margin-bottom: 16px;
  }
}
