.test-square::after {
  content: "";
  display: block;
  padding-bottom: 100%;
}
.cleaning-guide {
  font-family: "Pretendard-Regular";
  padding: 19px 16px 16px;
  background-color: #f7f8f9;
  .title {
    display: flex;
    align-items: center;
    font-size: 14px;
    color: $main-purple-0;
    font-weight: 700;
    margin-bottom: 15px;
    letter-spacing: -0.5px;
    span {
      display: block;
      margin-left: 3px;
    }
  }
  .desc {
    font-size: 14px;
    letter-spacing: -0.5px;
    line-height: 24px;
    color: #212121;
  }
}
.request-details {
  width: 100%;
  height: 100%;
  padding-bottom: 96px;
  font-family: "Pretendard-Regular";

  .step-percent {
    width: 100%;
    border-top: 2px solid $main-purple-0;
  }

  .step {
    font-family: "Pretendard-Medium";
    font-size: 14px;
    line-height: 20px;
    letter-spacing: -0.5px;
    // padding: 16px;
    padding: 16px 16px 0;
  }
  .details-wrap-hidden {
    overflow: hidden;
    height: 64px;
  }

  .details-content {
    display: flex;
    flex-direction: column;
    // padding: 16px 16px 48px;
    padding: 16px;
    border-bottom: 1px solid $grey-1;

    .step-content {
      font-size: 20px;
      line-height: 32px;
      letter-spacing: -0.5px;
      font-family: "Pretendard-Bold";
      color: $grey-9;
    }

    .add-img {
      // min-width: 343px;
      width: 100%;
      min-height: 48px;
      border: 1px solid $main-purple-light-4;
      border-radius: 4px;
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 16px 0;
      background-color: transparent;
      box-shadow: 0 2px 4px 0 rgba($main-purple-light-2, 0.4);
      cursor: pointer;

      label {
        width: 100%;
      }

      p {
        font-size: 16px;
        line-height: 16px;
        letter-spacing: -0.5px;
        font-family: "Pretendard-Medium";
        color: $main-purple-dark-1;
      }
    }

    .details-explain {
      min-width: 343px;
      width: 100%;
      min-height: 106px;
      // border: 1px solid;
      border-radius: 6px;
      background-color: $grey-0;
      font-size: 14px;
      line-height: 24px;
      letter-spacing: -0.5px;
      padding: 20px 16px 16px;
      font-family: "Pretendard-Regular";

      p {
        margin: 6px 0;
      }

      .caution {
        color: $main-purple-0;
        line-height: 14px;
        font-family: "Pretendard-Medium";
        margin-left: 6px;
      }
    }

    .guide-wrapper {
      display: flex;
      align-items: center;
      padding: 16px 0;

      p {
        font-size: 14px;
        // line-height: 24px;
        letter-spacing: -0.5px;
        color: $grey-9;
        font-family: "Pretendard-Regular";
        border-bottom: 1px solid $grey-9;
        margin-left: 4px;
      }
    }
  }

  .box-line {
    width: 100%;
    height: 8px;
    background-color: $grey-0;
  }

  .view-files-data {
    position: relative;
    padding: 0 16px;

    .presence-wrapper {
      padding: 8px 0;

      .presence-explain {
        display: flex;
        align-items: center;
        margin: 8px 0 14px;

        .presence {
          font-size: 16px;
          line-height: 26px;
          letter-spacing: -0.5px;
          font-family: "Pretendard-Bold";
        }

        .presence-warn {
          font-size: 12px;
          line-height: 12px;
          color: $red-500;
          // margin-left: 8px;
        }
      }

      .presence-input {
        position: relative;
        display: flex;

        .img-wrapper {
          width: 30%;
          // height: 30%;
          max-width: 210px;
          max-height: 210px;
          overflow: hidden;
          display: flex;
          align-items: center;
          border: 1px solid $grey-1;
          border-radius: 4px;
          min-width: 120px;
          min-height: 120px;
          aspect-ratio: 9/9;

          .input-img {
            min-width: 120px;
            min-height: 120px;
          }
        }

        .add-note {
          // min-width: 215px;
          // min-height: 120px;
          // width: 70%;
          // border: 1px solid $grey-1;
          // border-radius: 4px;
          // margin-left: 4px;
          // padding: 12px;
          width: 100%;
          height: 95%;
          border: none;
          font-family: "Pretendard-Regular";
          font-size: 14px;
          line-height: 22px;
          letter-spacing: -0.5px;
          outline: none;
        }

        .text-of-area {
          position: absolute;
          font-size: 12px;
          line-height: 20px;
          letter-spacing: -0.5px;
          color: $grey-3;
          // left: 38%;
          // right: 12%;
          right: 52px;
          bottom: 4%;
        }

        button {
          position: absolute;
          // right: 4%;
          right: 16px;
          bottom: 4%;
          border: none;
          background-color: transparent;
          color: $grey-6;
          line-height: 20px;
        }
      }
    }

    .reform-wrapper {
      // padding: 8px 16px;
      // width: 30%;
      // height: 1;
      min-width: 120px;
      min-height: 120px;
      // min-width: 250px;
      // min-height: 250px;
      max-width: 210px;
      max-height: 210px;
      // max-width: 32vw;
      // max-height: 32vw;
      aspect-ratio: 9/9;
      border: 1px solid $grey-1;
      overflow: hidden;
      // margin: 0 6%;
      border-radius: 4px;

      // &:first-child {
      //   padding-left: 16px;
      // }

      .presence-explain {
        display: flex;
        align-items: center;
        margin: 8px 0 14px;

        .presence {
          font-size: 16px;
          line-height: 26px;
          letter-spacing: -0.5px;
          font-family: "Pretendard-Bold";
        }

        .presence-warn {
          font-size: 12px;
          line-height: 12px;
          color: $red-500;
          // margin-left: 8px;
        }
      }

      .img-wrapper-reform {
        max-width: 210px;
        max-height: 210px;
        min-width: 120px;
        min-height: 120px;
        overflow: hidden;

        .input-img {
          min-width: 120px;
          min-height: 120px;
        }
      }
    }

    .additional-input {
      // height: 200px;
      // padding: 0 16px;
      margin-top: 40px;

      .input-explain {
        display: flex;
        align-items: center;

        .additional {
          font-size: 16px;
          line-height: 26px;
          letter-spacing: -0.5px;
          font-family: "Pretendard-Bold";
        }

        span {
          font-size: 12px;
          line-height: 12px;
          letter-spacing: -0.5px;
          font-family: "Pretendard-Medium";
          color: $main-purple-0;
          margin-left: auto;
        }
      }

      textarea {
        min-width: 343px;
        width: 100%;
        min-height: 128px;
        border: 1px solid $grey-1;
        border-radius: 4px;
        margin-top: 16px;
        padding: 12px;
        font-family: "Pretendard-Regular";
        font-size: 14px;
        line-height: 22px;
        letter-spacing: -0.5px;
        outline-color: $main-purple-0;
      }
    }

    .presence {
      font-size: 16px;
      line-height: 26px;
      letter-spacing: -0.5px;
      font-family: "Pretendard-Bold";
    }
    .presence-warn {
      font-size: 12px;
      line-height: 12px;
      color: $red-500;
      // margin-left: 8px;
    }
  }

  .modal-text-wrapper {
    font-size: 14px;
    line-height: 22px;
    letter-spacing: -0.5px;
    font-family: "Pretendard-Regular";

    .sub-title {
      color: $grey-6;
    }
    .sub-bottom {
      color: $main-purple-dark-1;
      margin-top: 16px;
    }
  }

  .entered-wrapper {
    // border-top: 1px solid $grey-1;
    background-color: $white;
    position: fixed;
    bottom: 0;
    min-width: 343px;
    width: 100%;
    max-width: 750px;
    min-height: 96px;
    z-index: 10;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 16px;

    .request-entered {
      width: 100%;
      min-height: 48px;
      border: none;
      border-radius: 4px;
      font-size: 16px;
      line-height: 24px;
      font-family: "Pretendard-Medium";
      background-color: $grey-3;
      cursor: pointer;

      p {
        color: $white;
      }

      &.selected {
        background-color: $main-purple-dark-1;
      }
      &.selected.promo11st {
        background-color: #0f0fd9;
      }
    }
  }

  .content-check-wrapper {
    height: calc(100vh - 56px - 50px - 107.5px);
    overflow-y: scroll;
    overflow-x: hidden;
    margin-top: 13px;
    padding-bottom: 50px;
    &.mobile {
      padding-bottom: 150px;
    }

    .popup-wrapper {
      .req-wrapper {
        display: flex;
        padding-top: 16px;

        .popup-req {
          font-size: 14px;
          line-height: 14px;
          letter-spacing: -0.5px;
          color: $main-purple-0;
          min-width: 80px;
        }
        .popup-first {
          width: 100%;
          min-width: 272px;
          height: 0;
          border-bottom: 1px solid $main-purple-0;
          letter-spacing: 14px;
          margin: 6px 0 6px 8px;
        }
      }

      .popup-info {
        display: flex;
        letter-spacing: -0.5px;

        .req-info {
          min-width: 180px;
          width: 50%;
          display: flex;
          font-size: 14px;
          line-height: 22px;
          margin: 20px 0 32px;

          .title {
            color: $grey-5;
          }
          .body {
            color: $grey-9;
            margin-left: 8px;
          }
        }
      }

      .content-info {
        margin: 12px 0;

        .content-wrapper {
          display: flex;
          margin: 8px 0;

          .img {
            min-width: 120px;
            min-height: 120px;
            border-radius: 4px;
            background-color: $white;
            max-width: 210px;
            max-height: 210px;
            width: 30%;
            overflow: hidden;
            display: flex;
            align-items: center;
            border: 1px solid;
            aspect-ratio: 9/9;
            border: 1px solid $grey-1;
          }
          .text {
            width: 70%;
            min-width: 215px;
            min-height: 120px;
            margin-left: 4px;
            background-color: $grey-0;
            color: $grey-6;
            border: 1px solid $grey-1;
            border-radius: 4px;
            padding: 12px;
            font-size: 14px;
            line-height: 22px;
            font-family: "Pretendard-Regular";
          }
        }
        .reform-wrapper {
          // display: flex;
          display: inline-block;
          margin: 4px 0;
          overflow: hidden;

          .img {
            min-width: 110px;
            min-height: 110px;
            border-radius: 4px;
            background-color: $white;
            max-width: 14vw;
            max-height: 14vw;
            aspect-ratio: 9/9;
            border: 1px solid $grey-1;
            // width: 30%;
            margin: 0 2%;
          }
        }
      }

      .additional {
        margin-top: 20px;
        background-color: $grey-0;
        border: 1px solid $grey-1;
        border-radius: 4px;
        padding: 12px 16px;
        font-size: 14px;
        line-height: 22px;
        letter-spacing: -0.5px;
        color: $grey-6;
        font-family: "Pretendard-Regular";
        min-width: 343px;
        min-height: 128px;
        // margin-bottom: 24px;
        margin-bottom: 40%;
      }
    }
  }
}

.cancel-modal {
  .sub-title-wrapper {
    display: flex;
    justify-content: center;
    padding-top: 24px;

    .sub-btn {
      display: flex;
      align-items: center;
      margin: 0 8px;
      cursor: pointer;

      .btn {
        width: 24px;
        margin-right: 8px;
      }
    }
  }
}

.inflow-submit {
  .sub-title {
    font-size: 12px;
    line-height: 18px;
    color: $grey-7;
    // margin-bottom: 24px;
    margin: -20px 0 24px 24px;
    display: flex;
  }

  .inflow-input {
    min-width: 200px;
    height: 30px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 0 24px;

    .inflow-text {
      font-size: 14px;
      line-height: 14px;
      letter-spacing: -0.5px;
      font-family: "Pretendard-Regular";
      margin-left: 8px;
    }

    // input[type="radio"] {
    //   display:none;
    // }
    &:after[type="radio"]:checked + input {
      background-color: skyblue;
    }

    // input[type="radio"] + label {
    //   color:#f2f2f2;
    //   font-family:Arial, sans-serif;
    // }
  }
}
