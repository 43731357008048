.request-select {
  // padding: 20px 12px;
  width: 100%;
  height: 100%;
  padding-bottom: 72px;

  .step-percent {
    width: 66%;
    border-top: 2px solid $main-purple-0;
  }

  .step {
    font-family: "Pretendard-Medium";
    font-size: 14px;
    line-height: 20px;
    letter-spacing: -0.5px;
    // padding: 16px;
    padding: 16px 16px 0;
  }

  .content-unit {
    display: flex;
    flex-direction: column;
    padding: 16px 16px 48px;
    border-bottom: 1px solid $grey-1;

    .step-content {
      font-size: 20px;
      line-height: 32px;
      letter-spacing: -0.5px;
      font-family: "Pretendard-Bold";
      color: $grey-9;
    }

    .content-form {
      // position: relative;

      .input-wrapper {
        display: flex;
        align-items: center;
        position: relative;
        margin: 16px 0;

        .content-input {
          min-width: 340px;
          width: 100%;
          min-height: 48px;
          height: 4vh;
          border: none;
          border: 1px solid $grey-1;
          border-radius: 4px;
          // margin-bottom: 8px;
          padding-left: 44px;
          font-size: 14px;
          outline-color: $main-purple-0;
          &.promo11st {
            outline-color: #0f0fd9;
          }
          &.selected {
            border: 1px solid $main-purple-0;
          }
          &.selected {
            border: 1px solid $main-purple-0;
          }
          &.selected.promo11st {
            border: 1px solid #0f0fd9;
          }
        }
      }

      .list-btn {
        // border: 1px solid;
        box-shadow: 0 2px 2px 0 rgba($grey-8, 0.2);
        position: absolute;
        top: -18px;
        top: 204px;
        top: 64px;
        z-index: 10;
        background-color: white;
        /* width: 100%; */
        min-width: 343px;
        max-height: 270px;
        border-radius: 4px;
        overflow-y: scroll;

        .list-zero {
          width: 100%;
          height: 58px;
          padding: 0 16px;
          display: flex;
          align-items: center;
          border: none;
          font-size: 14px;
          line-height: 22px;
          color: $grey-9;
          letter-spacing: -0.5px;
          background-color: white;
        }

        .list-name {
          width: 100%;
          height: 58px;
          // border-bottom: 1px solid $grey-1;
          letter-spacing: -0.5px;
          padding: 0 16px;
          display: flex;
          flex-direction: column;
          justify-content: center;
          background-color: $white;
          border: none;

          .kor {
            font-size: 14px;
            line-height: 22px;
            color: $grey-9;
          }
          .eng {
            font-size: 10px;
            line-height: 16px;
            // letter-spacing: -0.5px;
            color: $grey-5;
          }
        }
      }

      // 브랜드를 알려주세요
      .brand-btn {
        min-width: 340px;
        // width: 40vw;

        .select-brand {
          padding: 4px 14px;
          margin: 6px 2px;
          background-color: $grey-0;
          border: 1px solid $grey-0;
          // border: none;
          border-radius: 16px;
          color: $grey-6;
          font-size: 14px;
          line-height: 24px;
          letter-spacing: -0.5px;
          font-family: "Pretendard-Regular";
          cursor: pointer;

          &.active {
            border: 1px solid $main-purple-0;
            background-color: $main-purple-light-1;
            color: $main-purple-dark-1;
            &:before {
              content: "";
              display: inline-block;
              background-image: url("../../../assets/img/_check_purple.svg");
              background-repeat: no-repeat;
              background-position: center center;
              width: 13px;
              height: 10px;
              margin-right: 5.5px;
            }
          }
          &.active.promo11st {
            border: 1px solid #0f0fd9;
            color: #0f0fd9;

            &:before {
              content: "";
              display: inline-block;
              background-image: url("../../../assets/img/_check_blue.svg");
              background-repeat: no-repeat;
              background-position: center center;
              width: 13px;
              height: 10px;
              margin-right: 5.5px;
            }
          }
        }
      }

      .item-btn {
        height: 48px;
        display: flex;
        justify-content: space-between;
        margin: 8px 0;

        .select-item {
          min-width: 168px;
          width: 100%;
          min-height: 48px;
          border-radius: 4px;
          // padding: 12px;
          padding: 8px 24px;
          border: 1px solid $grey-1;
          background-color: $white;
          font-size: 14px;
          line-height: 24px;
          font-family: "Pretendard-Regular";
          cursor: pointer;

          .item-wrapper {
            display: flex;
            align-items: center;

            p {
              margin-left: 20px;
              color: $grey-6;
            }
          }

          &.active {
            border: 1px solid $main-purple-0;
            background-color: $main-purple-light-1;

            p {
              color: $main-purple-dark-1;
            }
          }
          &:first-child {
            margin-right: 8px;
          }
        }
      }
    }
  }

  .box-line {
    width: 100%;
    height: 8px;
    background-color: $grey-0;
  }

  .entered-wrapper {
    // border-top: 1px solid $grey-1;
    background-color: $white;
    position: fixed;
    bottom: 0;
    min-width: 343px;
    width: 100%;
    max-width: 750px;
    min-height: 96px;
    z-index: 10;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 16px;

    .request-entered {
      width: 100%;
      min-height: 48px;
      border: none;
      border-radius: 4px;
      font-size: 16px;
      line-height: 24px;
      font-family: "Pretendard-Medium";
      // background-color: $main-purple-dark-1;
      background-color: $grey-3;

      p {
        color: $white;
      }

      &.selected {
        background-color: $main-purple-dark-1;
      }
      &.selected.promo11st {
        background-color: #0f0fd9;
      }
    }
  }

  .cancel-modal {
    .sub-title-wrapper {
      display: flex;
      justify-content: center;
      padding-top: 24px;

      .sub-btn {
        display: flex;
        align-items: center;
        margin: 0 8px;
        cursor: pointer;

        .btn {
          width: 24px;
          margin-right: 8px;
        }
      }
    }
  }

  .cleaning-guide {
    font-family: "Pretendard-Regular";
    padding: 19px 16px 16px;
    background-color: #f7f8f9;
    .title {
      display: flex;
      align-items: center;
      font-size: 14px;
      color: $main-purple-0;
      font-weight: 700;
      margin-bottom: 15px;
      letter-spacing: -0.5px;
      span {
        display: block;
        margin-left: 3px;
      }
    }
    .desc {
      font-size: 14px;
      letter-spacing: -0.5px;
      line-height: 24px;
      color: #212121;
    }
  }
}
