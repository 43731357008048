.notice {
  width: 100%;
  height: 100%;

  .notice-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    // align-items: center;
    min-width: 375px;
    min-height: 70px;
    letter-spacing: -0.32px;
    font-family: "Pretendard-Regular";
    border-bottom: 1px solid $grey-1;
    // margin: 0 16px;

    .content-wrapper {
      display: flex;
      justify-content: space-around;
      // align-items: center;
      align-items: flex-start;
      min-height: 70px;
      // padding: 16px 0;
      padding: 16px;

      .num {
        font-size: 13px;
        line-height: 26px;
        color: $grey-6;
      }
  
      .content {
        display: flex;
        flex-direction: column;
        min-width: 200px;
        width: 100%;
        margin-left: 24px;
  
        .sub {
          font-size: 13px;
          line-height: 17px;
          color: $grey-9;
        }
        .time {
          font-size: 11px;
          line-height: 18px;
          letter-spacing: -0.28px;
          color: $grey-5;
        }
  
      }
    }

    .img-wrapper {
      padding: 8px;
      background-color: $grey-0;
      
      .detail-img {
        width: 100%;
      }
    }
  }
}