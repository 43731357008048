.notification {
  width: 100%;
  height: 100%;
  background-color: $grey-1;
  padding: 8px 16px;

  .noti-content {
    min-width: 343px;
    min-height: 110px;
    border-radius: 6px;
    border: 1px solid $grey-1;
    background-color: $white;
    margin: 8px 0;

    .read {
      padding: 16px;

      .topic {
        display: flex;
        align-items: center;

        .title{
          font-size: 13px;
          letter-spacing: -0.32px;
          color: $grey-8;
          font-family: "Pretendard-Medium";
        }
        .badge {
          width: 8px;
          height: 8px;
          background-color: transparent;
          border-radius: 50%;
          margin-left: auto;
        }
      }
      .body {
        font-size: 15px;
        line-height: 22px;
        letter-spacing: -0.38px;
        color: $grey-8;
        font-family: "Pretendard-SemiBold";
        margin-top: 12px;
      }
      .date {
        font-size: 11px;
        line-height: 18px;
        letter-spacing: -0.28px;
        color: $grey-5;
        margin-top: 18px;
      }
    }

    .unread {
      // border-top: 1px solid blue;

      .title{
        font-size: 13px;
        letter-spacing: -0.32px;
        color: $grey-8;
        font-family: "Pretendard-Medium";
      }
    }
  }
}

.for-read-all {
  border: none;
  top: 16px;
  position: absolute;
  z-index: 10;
  background-color: transparent;
  font-size: 14px;
  color: $grey-5;
  line-height: 22px;
}

.have-none {
  width: 100%;
  height: 100vh;
  background-color: $white;
  display: flex;
  justify-content: center;
  align-items: center;
}
