.shilla-wrap {
  background-color: #212121;
  min-height: 100vh;
  color: #faf9ff;
  font-family: "Pretendard-Bold";
  background-image: url("../../assets/img/bg_shilla.png");
  background-size: 100% 1000px;
  background-repeat: no-repeat;
  background-position: center top;
  letter-spacing: -0.5px;
  .back-btn {
    position: fixed;
    margin-left: 16px;
    margin-top: 16px;
    cursor: pointer;
  }
  .shilla-bg {
    .shilla-title {
      text-align: center;
      padding-top: 118px;

      p {
        &:first-child {
          font-size: 24px;
          line-height: 24px;

          margin-bottom: 11px;
        }
        &:last-child {
          font-size: 46px;
          line-height: 46px;
        }
      }
    }
    .logo-colla {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding-top: 77px;
      padding-bottom: 101px;
    }
    .shilla-coupon {
      text-align: center;
      .title {
        font-size: 32px;
        line-height: 46px;
        padding-bottom: 20px;
      }
      .desc {
        font-size: 16px;
        line-height: 25.6px;
      }
      .coupon-area {
        padding-top: 52px;
        padding-bottom: 70px;
        margin: 0 45px;
        display: flex;
        flex-wrap: wrap;
        p {
          flex: 1 1 50%;

          img {
            width: 100%;
            display: block;
          }
        }
      }
      .steps {
        &.steps-2 {
          padding-top: 75px;
        }
        // display: flex;
        // justify-content: center;
        // align-items: center;
        // flex-direction: column;
        .title {
          border-radius: 5px;
          width: 66px;
          height: 32px;
          background-color: #424242;
          padding: 5px 12px;
          color: #dd5044;
          font-size: 13px;
          line-height: 22.4px;
          margin: 0 auto;
        }
        .desc {
          font-family: "Pretendard-Regular";
          font-size: 16px;
          line-height: 25.6px;
          padding: 20px 0;
        }
        .button-area {
          margin: 0 16px;

          a {
            display: block;
            max-width: 343px;
            margin: 0 auto;
            border: 0;
            background-color: #dd5044;
            padding: 16px 46px;
            color: #faf9ff;
            font-family: "Pretendard-Bold";
            font-size: 15px;
            line-height: 25.6px;
            border-radius: 5px;
            cursor: pointer;
          }
        }
        .input-area {
          margin: 0 16px;
          input {
            border: 0;
            background-color: #fff;
            border: 1px solid #c9cdd2;
            border-top-left-radius: 5px;
            border-bottom-left-radius: 5px;
            padding: 16px 0;
            text-indent: 16px;
            width: 250px;
            border-right: 0;
            &:focus {
              outline: none;
            }
          }
          button {
            padding: 16px 21px;
            border: 0;
            border: 1px solid #383c40;
            background-color: #383c40;
            color: #faf9ff;
            font-family: "Pretendard-Bold";
            border-top-right-radius: 5px;
            border-bottom-right-radius: 5px;
            border-left: 0;
            cursor: pointer;
          }
        }
        .guide {
          padding-top: 22px;
          font-family: "Pretendard-Regular";
          font-size: 12px;
          color: #e8ebed;
        }
      }
    }
    .only-luxurynall {
      padding-top: 158px;

      .badge {
        width: 163px;
        line-height: 42px;
        color: #6c40ff;
        background-color: #fff;
        border-radius: 30px;
        text-align: center;
        margin: 0 auto;
      }
      .title {
        font-size: 32px;
        line-height: 46px;
        text-align: center;
        padding-top: 33px;
        padding-bottom: 17px;
      }
      .guide-img {
        margin: 0 38px;
        img {
          width: 100%;
          display: block;
        }
      }
      .guide-text {
        font-family: "Pretendard-Regular";
        font-size: 16px;
        line-height: 25.6px;
        text-align: center;
        padding-bottom: 54px;
      }
      .online-reward {
        background-color: #424242;
        margin: 0 16px;
        border-radius: 5px;
        // max-width: 343px;
        // margin: 0 auto;
        .title {
          color: #dd5044;
          font-size: 16px;
          line-height: 25.6px;
        }
      }
      .reward {
        display: flex;
        justify-content: center;
        gap: 20px;
        text-align: center;
        font-family: "Pretendard-Regular";
        line-height: 25.6px;
        flex-wrap: wrap;

        .reward-img {
          height: 95px;
          &.offline {
            height: 72px;
          }
          img {
            display: block;
            margin: 0 auto;
          }
        }
        .reward-text {
          padding-top: 30px;
          color: #faf9ff;
          // padding-bottom: 46px;
          &.offline {
            padding-top: 18px;
          }
        }
        .lines {
          display: inline-block;
          width: 100%;
          height: 1px;
          margin: 0 16px;
          background-color: #c9cdd2;
          font-size: 0;
        }
      }
    }
    .for-shilla {
      padding-top: 79px;
      text-align: center;
      .steps {
        &.steps-2 {
          padding-top: 80px;
          padding-bottom: 91px;
        }
        // display: flex;
        // justify-content: center;
        // align-items: center;
        // flex-direction: column;
        .title {
          border-radius: 5px;
          width: 66px;
          height: 32px;
          background-color: #424242;
          padding: 5px 12px;
          color: #dd5044;
          font-size: 13px;
          line-height: 22.4px;
          margin: 0 auto;
        }
        .desc {
          font-family: "Pretendard-Regular";
          font-size: 16px;
          line-height: 25.6px;
          padding: 20px 0 25px;
        }
        .button-area {
          width: 343px;
          margin: 0 auto;

          button {
            border: 0;
            background-color: #fff;
            padding: 16px 0;
            width: 100%;
            color: #dd5044;
            font-family: "Pretendard-Bold";
            font-size: 15px;
            line-height: 25.6px;
            border-radius: 5px;
            cursor: pointer;
          }
          a {
            display: block;
            max-width: 343px;
            border: 0;
            background-color: #fff;
            padding: 16px 0;
            width: 100%;
            color: #dd5044;
            font-family: "Pretendard-Bold";
            font-size: 15px;
            line-height: 25.6px;
            border-radius: 5px;
            cursor: pointer;
          }
        }
        .input-area {
          margin: 0 16px;
          input {
            border: 0;
            background-color: #fff;
            border: 1px solid #c9cdd2;
            border-top-left-radius: 5px;
            border-bottom-left-radius: 5px;
            padding: 16px 0;
            text-indent: 16px;
            width: 250px;
            border-right: 0;
            &:focus {
              outline: none;
            }
          }
          button {
            padding: 16px 21px;
            border: 0;
            border: 1px solid #383c40;
            background-color: #383c40;
            color: #faf9ff;
            font-family: "Pretendard-Bold";
            border-top-right-radius: 5px;
            border-bottom-right-radius: 5px;
            border-left: 0;
            cursor: pointer;
          }
        }
        .guide {
          padding-top: 22px;
          font-family: "Pretendard-Regular";
          font-size: 12px;
          color: #e8ebed;
        }
      }
    }
    .evnet-point {
      margin: 0 16px;
      border-top: 1px solid #fff;
      .title {
        padding: 16px 0 10px;
      }
      div {
        font-size: 12px;
        font-family: "Pretendard-Regular";
        padding-bottom: 52px;
        p {
          line-height: 20px;
          &:before {
            content: "";
            display: inline-block;
            width: 4px;
            height: 4px;
            background-color: #fff;
            border-radius: 50%;
            vertical-align: middle;
            margin-right: 7px;
          }
        }
      }
    }
  }
  .modal-blur {
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.3);
    position: fixed;
    top: 0;
    left: 0;
  }
  .shilla-coupon-modal {
    max-width: 343px;
    position: fixed;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    background-color: #fff;
    color: #000;
    border-radius: 5px;
    width: 100%;
    text-align: center;
    .title {
      padding-top: 33px;
      padding-bottom: 30px;
      font-size: 20px;
    }
    .code {
      background-color: #f7f8f9;
      margin: 0 16px;
      border-radius: 5px;
      line-height: 63px;
      font-family: "Pretendard-Regular";
    }
    .paste {
      text-decoration: underline;
      font-family: "Pretendard-Regular";
      font-size: 14px;
      margin: 16px 0 36px;
      cursor: pointer;
    }
    .button-area {
      margin: 0 16px;
      padding-bottom: 31px;
      button {
        width: 100%;
        border: 0;
        background-color: #000;
        color: #fff;
        line-height: 58px;
        border-radius: 5px;
        cursor: pointer;
        font-family: "Pretendard-Bold";
      }
    }
  }
}
