.home {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 750px;
  min-width: 375px;
  justify-content: center;
  padding-bottom: 48px;

  // 헤더
  .home-header {
    width: 100%;
    height: 56px;
    padding: 0 16px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: sticky;
    top: 0;
    left: 0;
    z-index: 50000;
    background: $white;
    .home-header-logo {
      cursor: pointer;
    }
    .noti {
      position: relative;
      .badge {
        width: 8px;
        height: 8px;
        background-color: transparent;
        border-radius: 50%;
        position: absolute;
        right: 0;
      }
    }
  }

  // 메인 슬라이더
  .home-mainSlider {
    margin: 8px 16px 20px;
    border-radius: 12px;
    overflow: hidden;
    position: relative;
    aspect-ratio: 2/1;
    .slick-slider {
      width: 100%;
      height: 100%;
      overflow: hidden;
      border-radius: 12px;
      .slider-contents {
        position: relative;
        width: 100%;
        aspect-ratio: 2/1;
        border-radius: 12px;
        overflow: hidden;
        .slider-category {
          position: absolute;
          right: 32px;
          top: 0px;
          padding: 5px 6px;
          background-color: $white;
          border-radius: 0 0 8px 8px;
          font-family: "Pretendard-Medium";
          font-size: 12px;
          line-height: 100%;
          letter-spacing: -0.5px;
          color: $grey-9;
          &::before {
            content: "";
            display: block;
            width: 10px;
            height: 10px;
            background: url(../../assets/img/Subtract.svg) no-repeat center /
              cover;
            position: absolute;
            left: 0.25px;
            top: 0;
            transform: translateX(-100%);
          }
          &::after {
            content: "";
            display: block;
            width: 10px;
            height: 10px;
            background: url(../../assets/img/Subtract.svg) no-repeat center /
              cover;
            position: absolute;
            right: 0.25px;
            top: 0;
            transform: translateX(100%) rotateY(180deg);
          }
        }
        .slider-image {
          width: 100%;
          height: 100%;
        }
      }
    }
    .slider-arrow-btn {
      all: unset;
      display: none;
    }
    @media all and (min-width: 750px) {
      &:hover {
        .slider-arrow-btn {
          cursor: pointer;
          background: rgba(255, 255, 255, 0.8);
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
          border-radius: 50%;
          box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.2);
          display: flex;
          align-items: center;
          justify-content: center;
          width: 40px;
          height: 40px;
          aspect-ratio: 1/1;
          &.left {
            left: -8px;
            svg {
              fill: $grey-800;
            }
          }
          &.right {
            right: -8px;
            svg {
              stroke: $grey-800;
            }
          }
        }
      }
    }
    .index {
      position: absolute;
      width: fit-content;
      height: fit-content;
      right: 8px;
      bottom: 10px;
      font-family: "Pretendard-Medium";
      padding: 4px 6px;
      letter-spacing: -0.5px;
      font-size: 10px;
      line-height: 100%;
      color: $white;
      border-radius: 12px;
      background-color: rgba(33, 33, 33, 0.6);
    }
  }

  // 견적 비교하기 버튼
  .button-wrapper {
    padding: 0 16px;
    margin-bottom: 40px;
    .moveToRequest-btn {
      width: 100%;
      padding: 12px 16px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      background-color: $white;
      border-radius: 12px;
      border: 1px solid $purple-500;
      cursor: pointer;
      .item {
        display: flex;
        gap: 4px;
        align-items: center;
      }
      .btn-text {
        text-align: left;
        position: relative;
        .tag-270 {
          position: absolute;
          top: -17px;
          left: 0;
          transform: translateY(-50%);
          font-family: "Pretendard-Medium";
          font-size: 12px;
          line-height: 16px;
          letter-spacing: -0.3px;
          padding: 4px 8px;
          border-radius: 99px;
          color: $white;
          background: linear-gradient(101.59deg, #ab40ff 0%, #6c40ff 100%);
          user-select: none;
        }
        .text-1 {
          font-family: "Pretendard-Bold";
          font-size: 16px;
          line-height: 26px;
          letter-spacing: -0.5px;
          margin-bottom: 4px;
          color: $purple-700;
        }
        .text-2 {
          letter-spacing: -0.3px;
          font-family: "Pretendard-Regular";
          color: $grey-700;
        }
      }
      .btn-img-1 {
        width: 56px;
        height: 56px;
        overflow: hidden;
      }
      .btn-img-2 {
        width: 20px;
        height: 20px;
        background: url(../../assets/img/icon/24px/arrow_right_regular.svg)
          no-repeat center / contain;
      }
    }
  }

  // 견적 의뢰하기 카테고리
  .request-restoration {
    width: 100%;
    padding: 0 16px;
    display: flex;

    margin-bottom: 32px;
    .title {
      font-family: "Pretendard-Bold";
      font-size: 18px;
      letter-spacing: -0.5px;
      display: flex;
      justify-content: space-between;
      align-items: flex-end;
      margin-bottom: 16px;
      .name {
        display: flex;
        align-items: center;
        column-gap: 4px;
        .length {
          font-family: "Pretendard-Bold";
          color: $main-purple-dark-1;
          font-size: 16px;
          letter-spacing: -0.5px;
        }
      }
    }
    .button-wrapper-cat {
      margin-bottom: 40px;
      width: 100%;

      .button-wrapper-title {
        display: block;
      }

      .cat-btn {
        width: 25%;
        justify-content: space-between;
        align-items: center;
        background-color: $white;
        border: 0px;
        cursor: pointer;
        .item {
          gap: 4px;
          align-items: center;
          overflow: hidden;
          width: 100%;
          max-width: 120px;
        }
        .item-title {
          display: block;
          font-size: 1rem;
          line-height: 1.1rem;
          letter-spacing: -0.5px;
          font-family: "Pretendard-Regular";
          color: black;
        }
      }
    }
  }

  // 진행중인 수선
  .restoration {
    width: 100%;
    padding: 0 16px;
    margin-bottom: 32px;
    .title {
      font-family: "Pretendard-Bold";
      font-size: 18px;
      letter-spacing: -0.5px;
      display: flex;
      justify-content: space-between;
      align-items: flex-end;
      margin-bottom: 16px;
      .name {
        display: flex;
        align-items: center;
        column-gap: 4px;
        .length {
          font-family: "Pretendard-Bold";
          color: $main-purple-dark-1;
          font-size: 16px;
          letter-spacing: -0.5px;
        }
      }
      .link-btn {
        display: flex;
        align-items: center;
        font-family: "Pretendard-Regular";
        font-size: 12px;
        a {
          color: $grey-6;
        }
      }
    }
    .contents {
      .slider-controller-area {
        width: 148px;
        font-family: "Pretendard-Regular";
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin: 0 auto;
        gap: 12px;
        button {
          all: unset;
          cursor: pointer;
          display: block;
          border: 1px solid $grey-300;
          padding: 6px 12px;
          border-radius: 4px;
          .left {
            width: 16px;
            height: 16px;
            background: url(../../assets/img/icon_left_arrow_center_align_black_16.svg)
              no-repeat center / contain;
          }
          .right {
            width: 16px;
            height: 16px;
            background: url(../../assets/img/icon_right_arrow_center_align_black_16.svg)
              no-repeat center / contain;
          }
        }
        .controller-index {
          font-family: "Pretendard-Medium";
          font-size: 14px;
          line-height: 100%;
          letter-spacing: -0.5px;
          display: flex;
          gap: 8px;
          .g-900 {
            color: $grey-900;
          }
          .g-300 {
            color: $grey-300;
          }
          .g-500 {
            color: $grey-500;
          }
        }
      }
    }
  }

  // 베스트 리뷰
  .review {
    width: 100%;
    padding: 0 16px;
    margin-bottom: 48px;
    .title {
      font-family: "Pretendard-Bold";
      font-size: 18px;
      letter-spacing: -0.5px;
      display: flex;
      justify-content: space-between;
      align-items: flex-end;
      margin-bottom: 16px;
      .link-btn {
        display: flex;
        align-items: center;
        font-family: "Pretendard-Regular";
        font-size: 12px;
        cursor: pointer;
        span {
          color: $grey-6;
        }
      }
    }
    .review-slider {
      .slick-slider {
        height: 368px;
        border: 1px solid $grey-300;
        border-radius: 12px;
        padding: 20px 16px;
        .line-0 {
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin-bottom: 12px;
          .line-1 {
            display: flex;
            gap: 8px;
            font-size: 14px;
            line-height: 100%;
            letter-spacing: -0.5px;
            font-family: "Pretendard-Medium";
            color: $grey-900;
            .bar {
              color: $grey-300;
            }
            .line-2 {
              display: flex;
              align-items: center;
              gap: 4px;
            }
            .star-img {
              width: 16px;
              height: 16px;
              background: url(../../assets/img/icon/16px/star_full.svg)
                no-repeat center / contain;
            }
            .name {
              font-family: "Pretendard-Regular";
              color: $grey-600;
            }
          }
          .report-btn {
            font-family: "Pretendard-Regular";
            font-size: 14px;
            letter-spacing: -0.5px;
            color: $grey-4;
          }
        }
        .before-after-slider-area {
          margin-bottom: 12px;

          @media all and (max-width: 437px) {
            .review-desktop {
              display: none;
            }
            .review-mobile {
              display: block;
              border-radius: 4px;
              overflow: hidden;
              .handle-img {
                width: 48px;
                height: 36px;
                background: url(../../assets/img/slider.svg) no-repeat center /
                  contain;
              }
              .comparison-img {
                height: 200px;
                background-position: center;
                background-size: cover;
                background-repeat: no-repeat;
                position: relative;
                img {
                  width: 100%;
                  height: 100%;
                  object-fit: cover;
                  object-position: center center;
                }
                .comparison-imgcaption {
                  font-family: "Pretendard-Medium";
                  font-size: 12px;
                  letter-spacing: -0.3px;
                  color: $white;
                  background: rgba(33, 33, 33, 0.7);
                  padding: 6px 8px;
                  width: fit-content;
                  position: absolute;
                  bottom: 0;
                  &.before {
                    left: 0;
                    border-radius: 0 4px 0 0;
                  }
                  &.after {
                    right: 0;
                    border-radius: 4px 0 0 0;
                  }
                }
              }
            }
          }
          @media all and (min-width: 438px) {
            .review-desktop {
              display: flex;
              column-gap: 8px;
              .desktop-img {
                width: 50%;
                height: 200px;
                border-radius: 4px;
                overflow: hidden;
                position: relative;
                background-position: center;
                background-size: cover;
                background-repeat: no-repeat;
                img {
                  width: 100%;
                  height: 100%;
                  object-fit: cover;
                  object-position: center center;
                }
                .caption {
                  font-size: 12px;
                  letter-spacing: -0.3px;
                  color: $white;
                  font-family: "Pretendard-Medium";
                  padding: 6px 8px;
                  background: rgba(33, 33, 33, 0.7);
                  width: fit-content;
                  position: absolute;
                  bottom: 0;
                  &.before {
                    left: 0;
                    border-radius: 0 4px 0 0;
                  }
                  &.after {
                    right: 0;
                    border-radius: 4px 0 0 0;
                  }
                }
              }
            }
            .review-mobile {
              display: none;
            }
          }
        }
        .tags-area {
          display: flex;
          gap: 8px;
          margin-bottom: 8px;
          word-wrap: break-word;
          overflow-x: hidden;
          text-overflow: ellipsis;
          .tag {
            flex: 0 0 auto;
            padding: 3px 8px;
            width: fit-content;
            border-radius: 4px;
            font-family: "Pretendard-Regular";
            white-space: nowrap;
            font-size: 12px;
            color: $grey-700;
            line-height: 22px;
            letter-spacing: -0.5px;
            background-color: $grey-200;
            cursor: default;
          }
        }
        .message-area {
          display: flex;
          justify-content: space-between;
          align-items: center;
          .message {
            font-family: "Pretendard-Regular";
            font-size: 14px;
            line-height: 24px;
            letter-spacing: -0.5px;
            text-overflow: ellipsis;
            overflow: hidden;
            display: -webkit-box;
            -webkit-line-clamp: 1;
            -webkit-box-orient: vertical;
          }
          .view-more-btn {
            flex: 0 0 auto;
            line-height: 22px;
            font-family: "Pretendard-Regular";
            font-size: 14px;
            letter-spacing: -0.5px;
            color: $grey-4;
            text-decoration: underline;
            padding-left: 4px;
          }
        }
        .likes-btn {
          &.active {
            border: 1px solid $purple-500;
            color: $purple-700;
            background: $purple-50;
            .grey-800 {
              color: $purple-700;
            }
          }
          svg {
            stroke: currentColor;
          }
          all: unset;
          margin-top: 16px;
          cursor: pointer;
          border: 1px solid $grey-300;
          padding: 10px 12px;
          border-radius: 99px;
          display: flex;
          align-items: center;
          gap: 4px;
          font-size: 12px;
          line-height: 100%;
          letter-spacing: -0.3px;
          font-family: "Pretendard-Medium";
          color: $grey-600;
          .grey-800 {
            color: $grey-800;
          }
        }
      }
      .slider-controller-area {
        width: 148px;
        font-family: "Pretendard-Regular";
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin: 12px auto 0;
        gap: 12px;
        button {
          all: unset;
          cursor: pointer;
          display: block;
          border: 1px solid $grey-300;
          padding: 6px 12px;
          border-radius: 4px;
          .left {
            width: 16px;
            height: 16px;
            background: url(../../assets/img/icon_left_arrow_center_align_black_16.svg)
              no-repeat center / contain;
          }
          .right {
            width: 16px;
            height: 16px;
            background: url(../../assets/img/icon_right_arrow_center_align_black_16.svg)
              no-repeat center / contain;
          }
        }
        .controller-index {
          font-family: "Pretendard-Medium";
          font-size: 14px;
          line-height: 100%;
          letter-spacing: -0.5px;
          display: flex;
          gap: 8px;
          .g-900 {
            color: $grey-900;
          }
          .g-300 {
            color: $grey-300;
          }
          .g-500 {
            color: $grey-500;
          }
        }
      }
    }
  }
  .our-services {
    width: 100%;
    padding: 0 16px;
    margin-bottom: 24px;
    .title {
      font-family: "Pretendard-Bold";
      font-size: 18px;
      letter-spacing: -0.5px;
      display: flex;
      justify-content: space-between;
      align-items: flex-end;
      margin-bottom: 16px;
      .link-btn {
        display: flex;
        align-items: center;
        font-family: "Pretendard-Regular";
        font-size: 12px;
        cursor: pointer;
        span {
          color: $grey-6;
        }
      }
    }
    .contents-area {
      .scroll-container {
        height: 200px;
        display: flex;
        align-items: center;
        gap: 12px;
        transition: 0.5s !important;
        .content-item {
          width: 200px;
          aspect-ratio: 1/1;
          flex: 0 0 auto;
          border-radius: 8px;
          cursor: pointer;
        }
      }
    }
  }

  // 내 명품, 수선이 될까요?
  .service-guide {
    width: 100%;
    height: 84px;
    margin-bottom: 24px;
    background: #f8f5e9;
    text-align: center;
    .service-guide-img {
      width: 375px;
      height: 100%;
      background: url(../../assets/img/subBanner.svg) no-repeat center / contain;
    }
  }
  // 자주 묻는 질문
  .home-faq {
    width: 100%;
    padding: 0 16px;
    margin-bottom: 24px;
    .title {
      font-family: "Pretendard-Bold";
      font-size: 18px;
      letter-spacing: -0.5px;
      display: flex;
      justify-content: space-between;
      align-items: flex-end;
      margin-bottom: 12px;
      .link-btn {
        display: flex;
        align-items: center;
        font-family: "Pretendard-Regular";
        font-size: 12px;
        cursor: pointer;
        span {
          color: $grey-6;
        }
      }
    }
    .faq-contents {
      .faq-item {
        font-family: "Pretendard-Regular";
        font-size: 14px;
        line-height: 22.4px;
        letter-spacing: -2%;
        overflow: hidden;
        .faq-ques {
          padding: 16px;
          border-top: 1px solid $grey-300;
          display: flex;
          justify-content: space-between;
          align-items: center;
          background: white;
          cursor: pointer;
          .col {
            display: flex;
            align-items: center;
            gap: 8px;
            .ques-img {
              width: 16px;
              height: 16px;
              background: url("../../assets/img/icon/16px/question.svg")
                no-repeat center / contain;
            }
          }
          .arrow-img {
            width: 24px;
            height: 24px;
            background: url("../../assets/img/icon/24px/chevron_down_thin.svg")
              no-repeat center / contain;
            &.active {
              background: url("../../assets/img/icon/24px/chevron_up_thin.svg")
                no-repeat center / contain;
            }
          }
        }
        .faq-answ {
          max-height: 0;
          background-color: $grey-100;
          overflow: hidden;
          &.active {
            max-height: 1000px;
            transition: max-height 1s;
            -o-transition: max-height 1s;
            -moz-transition: max-height 1s;
            -webkit-transition: max-height 1s;
          }
          .faq-answ-contents {
            padding: 16px;
          }
        }
      }
    }
  }

  // 푸터
  .home-footer {
    background-color: $grey-100;
    padding: 20px 16px;
    .footer-title {
      cursor: pointer;
      color: $grey-700;
      display: flex;
      align-items: center;
      font-family: "Pretendard-Medium";
      letter-spacing: -0.5px;
      gap: 2px;
      font-size: 14px;
      .arrow-btn {
        width: 16px;
        height: 16px;
        background: url(../../assets/img/icon/24px/chevron_down_regular.svg)
          no-repeat center / contain;
        &.active {
          background: url(../../assets/img/icon/24px/chevron_up_regular.svg)
            no-repeat center / contain;
        }
      }
    }
    .footer-contents {
      margin-top: 20px;
      font-size: 12px;
      line-height: 100%;
      color: $grey-700;
      letter-spacing: -0.3px;
      font-family: "Pretendard-Regular";
      flex-direction: column;
      gap: 8px;
      display: none;
      &.active {
        display: flex;
      }
      .line {
        display: flex;
        align-items: center;
        span {
          &:nth-child(1) {
            width: 100px;
          }
          a {
            color: $grey-700;
          }
        }
      }
      .company-name {
        font-family: "Pretendard-Bold";
      }
    }
    .link {
      margin-top: 20px;
      display: flex;
      font-family: "Pretendard-Regular";
      color: $grey-700;
      font-size: 12px;
      line-height: 100%;
      letter-spacing: -0.3px;
      gap: 4px;
      &.close {
        margin-top: 12px;
      }
      .bar {
        color: $grey-400;
      }
      a.bold {
        font-family: "Pretendard-Bold";
      }
    }
    .sns {
      display: flex;
      align-items: center;
      margin-top: 16px;
      gap: 8px;
      .icon {
        height: fit-content;
        img {
          width: 28px;
          height: 28px;
        }
      }
    }
  }
}
