.splash2-wrapper {
  touch-action: none;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  z-index: 1000000000;

  background-color: #fff;
  // .slick-dots{
  //   top:0 !important;
  // }

  .splash-skip {
    &.small {
      top: 2%;
      right: 4%;
    }
    &.medium {
      top: 4%;
      right: 4%;
    }
    &.large {
      top: 6%;
      right: 6%;
    }
    z-index: 1;
    font-size: 14px;
    line-height: 24px;
    letter-spacing: -0.21px;
    color: $grey-4;
    font-family: "Pretendard-Regular";
    position: absolute;
    top: 2%;
    cursor: pointer;
  }
  .splash2-dots {
    z-index: 1;
    display: flex;
    justify-content: center;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    li {
      width: 19px;
      height: 19px;
      display: flex;
      justify-content: center;
      align-items: center;
      button {
        cursor: pointer;
        width: 8px;
        height: 8px;
        border-radius: 4px;
        font-size: 0;
        text-indent: -9999px;
        border: 0;
        background-color: $grey-2;
      }
    }
  }
  .splash2-content {
    &.bg-uniq {
      background-image: url(../../assets/img/splash_bg.png);
      background-size: 100% 100%;
      background-repeat: no-repeat;
    }
    height: 100vh;
    overflow: hidden;
    display: flex !important;
    flex-direction: column;
    justify-content: center;
    .splash-title {
      padding-top: 55px;
      p {
        width: 100%;
        text-align: center;
        color: $grey-900;
        line-height: 36px;
        letter-spacing: -2%;
        font-size: 24px;
        font-family: "Pretendard-Bold";
      }
    }
    .splash-sub-title {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      p {
        font-size: 14px;
        font-family: "Pretendard-Regular";
        letter-spacing: -2%;
        line-height: 22.4px;
        color: $purple-600;
      }
    }
    .splash-desc {
      p {
        font-size: 20px;
        line-height: 32px;
        color: $grey-900;
        text-align: center;
        font-family: "Pretandard-Regular";
        letter-spacing: -2%;
      }
    }
    .splash-animation {
      width: 750px;
      margin: 126px auto;
      overflow: hidden;
      position: relative;
      // &::before {
      //   content: "";
      //   width: 90px;
      //   height: 180px;
      //   background: linear-gradient(
      //     to right,
      //     rgba(255, 255, 255, 1) 0%,
      //     rgba(255, 255, 255, 0) 100%
      //   );
      //   position: absolute;
      //   left: 0;
      //   top: 0;
      //   z-index: 10;
      // }
      // &::after {
      //   content: "";
      //   width: 90px;
      //   height: 180px;
      //   background: linear-gradient(
      //     to left,
      //     rgba(255, 255, 255, 1) 0%,
      //     rgba(255, 255, 255, 0) 100%
      //   );
      //   position: absolute;
      //   right: 0;
      //   top: 0;
      //   z-index: 10;
      // }
      .splash-imgs {
        position: relative;
        display: flex;
        gap: 24px;
        width: calc(204 * 5);
        animation: onboarding_ani 8s linear infinite;
        .splash-slider-img {
          width: 180px;
          flex: 0 0 auto;
          img {
            width: 100%;
          }
        }
      }
      @keyframes onboarding_ani {
        0% {
          transform: translateX(0);
        }
        100% {
          transform: translateX(-204px * 5);
        }
      }
    }
  }
  .splash2-button {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    position: relative;
    button {
      cursor: pointer;
      width: 343px;
      height: 48px;
      line-height: 100%;
      letter-spacing: -0.5px;
      border-radius: 9px;
      background-color: $grey-900;
      border: 0px;
      color: $white;
      font-size: 16px;
      font-family: "Pretendard-Regular";
      display: flex;
      align-items: center;
      justify-content: center;
      .arrow {
        width: 16px;
        height: 16px;
        background: url(../../assets/img/icon/16px/icon_chevron.svg);
      }
    }
    input {
      display: none;
    }
    .splash-visible {
      position: absolute;
      top: 52px;
      input {
        display: none;
      }
      label {
        display: flex;
        justify-content: center;
        align-items: center;
        font-family: "Pretendard-Regular";
        font-size: 14px;
        line-height: 24px;
        letter-spacing: -0.21px;
        color: $grey-4;
        cursor: pointer;
      }
    }
  }
}
