.tab-bar {
  position: sticky;
  left: 0;
  top: 56px;
  width: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
  border-bottom: 1px solid $grey-1;
  // background: $grey-0;
  button {
    &.active {
      border-bottom: 3px solid $main-purple-0;
      color: $main-purple-dark-1;
      font-family: "Pretendard-SemiBold";
      transition: 0.3s linear;
    }
    background-color: transparent;
    width: fit-content;
    height: 100%;
    padding: 6.5px 6px 0;
    border: none;
    border-bottom: 3px solid transparent;
    color: $grey-5;
    height: 48.5px;
    font-family: "Pretendard-Medium";
    font-size: 16px;
    letter-spacing: -0.4px;
    cursor: pointer;
  }
}
