.post-list {
  .regist-post {
    margin-top: 16px;
    display: flex;
    overflow-x: scroll;
    scrollbar-width: none;
    -ms-overflow-style: none;
    &::-webkit-scrollbar {
      display: none; /* Chrome, Safari, Opera*/
    }
    // justify-content: space-between;
    li {
      flex-grow: 1;
      &.add-new-post {
        div {
          display: block;
          width: 315px;
          height: 153px;
          border: 1px dashed $grey-3;
          border-radius: 4px;

          span {
            display: block;
          }

          .plus-icon {
            width: 51px;
            height: 51px;
            margin: 36px auto 15px;

            img {
              width: 100%;
            }
          }

          .text {
            font-size: 13px;
            color: $grey-8;
            letter-spacing: -0.32;
            text-align: center;
          }
        }
      }

      display: inline-block;
      margin-right: 8px;
      vertical-align: top;
      flex-shrink: 0;

      input {
        display: none;
      }

      input:checked + label {
        border: 1px solid $main-purple-0;

        .chkbox-bg {
          background-image: url("../../assets/img/icCheck21On.svg");
        }
      }

      label {
        display: inline-block;
        position: relative;
        width: 315px;
        height: 153px;
        padding-left: 16px;
        border: 1px dashed $grey-3;
        border-radius: 4px;
        cursor: pointer;

        span {
          &.post-title-text {
            font-size: 15px;
            margin: 16px 0 24px;
          }

          &.post-name-phone {
            font-family: "Pretendard-Medium";
            font-size: 15px;
            color: $grey-8;
            letter-spacing: -0.38px;
            margin-bottom: 10px;
          }

          &.chkbox-bg {
            position: absolute;
            right: 14px;
            top: 14px;
            width: 24px;
          }

          &.delete {
            position: absolute;
            right: 17px;
            width: 22px;
            height: 16px;
            font-size: 13px;
            letter-spacing: -0.32px;
            color: $grey-3;
          }

          display: inline-block;
          width: 100%;
          line-height: 18px;
          font-size: 12px;
          letter-spacing: -0.3px;
        }
      }
    }
  }

  .post-list-slider {
    margin-top: 16px;
    .add-new-post {
      display: block;
      width: 315px;
      height: 153px;
      border: 1px dashed $grey-3;
      border-radius: 4px;

      span {
        display: block;
      }

      .plus-icon {
        width: 51px;
        height: 51px;
        margin: 36px auto 15px;

        img {
          width: 100%;
        }
      }

      .text {
        font-size: 13px;
        color: $grey-8;
        letter-spacing: -0.32;
        text-align: center;
      }
    }

    .post-slider {
      .slick-slide {
        position: relative;
        border-radius: 4px;
        // padding-left: 16px;
        cursor: pointer;
        height: 153px;
        width: 315px !important;
        margin-right: 8px;
        .map-div {
          input {
            display: none;
          }

          input:checked + label {
            border: 1px solid $main-purple-0;

            .chkbox-bg {
              background-image: url("../../assets/img/icCheck21On.svg");
            }
          }
          label {
            display: inline-block;
            position: relative;
            width: 315px;
            height: 153px;
            padding-left: 16px;
            border: 1px dashed $grey-3;
            border-radius: 4px;
            cursor: pointer;

            span {
              &.post-title-text {
                font-size: 15px;
                margin: 16px 0 24px;
              }

              &.post-name-phone {
                font-family: "Pretendard-Medium";
                font-size: 15px;
                color: $grey-8;
                letter-spacing: -0.38px;
                margin-bottom: 10px;
              }

              &.chkbox-bg {
                position: absolute;
                right: 14px;
                top: 14px;
                width: 24px;
              }

              &.delete {
                position: absolute;
                right: 17px;
                width: 22px;
                height: 16px;
                font-size: 13px;
                letter-spacing: -0.32px;
                color: $grey-3;
              }

              display: inline-block;
              width: 100%;
              line-height: 18px;
              font-size: 12px;
              letter-spacing: -0.3px;
            }
          }
        }

        // .add-new-post {
        //     display: block;
        //     width: 315px;
        //     height: 153px;
        //     border-radius: 4px;
        //     .plus-icon {
        //       display:block;
        //       width: 51px;
        //       height: 51px;
        //       margin: 36px auto 15px;

        //       img {
        //         width: 100%;
        //       }
        //     }

        //     .text {
        //       font-size: 13px;
        //       color: $grey-8;
        //       letter-spacing: -0.32;
        //       text-align: center;
        //     }

        // }
        // span {
        //   &.post-title-text {
        //     font-size: 15px;
        //     margin: 16px 0 24px;
        //   }

        //   &.post-name-phone {
        //     font-family: "Pretendard-Medium";
        //     font-size: 15px;
        //     color: $grey-8;
        //     letter-spacing: -0.38px;
        //     margin-bottom: 10px;
        //   }

        //   &.chkbox-bg {
        //     position: absolute;
        //     right: 14px;
        //     top: 14px;
        //     width: 24px;
        //   }

        //   &.delete {
        //     position: absolute;
        //     right: 17px;
        //     width: 22px;
        //     height: 16px;
        //     font-size: 13px;
        //     letter-spacing: -0.32px;
        //     color: $grey-3;
        //   }

        //   display: inline-block;
        //   width: 100%;
        //   line-height: 18px;
        //   font-size: 12px;
        //   letter-spacing: -0.3px;
        // }
      }
    }

    // overflow-x: scroll;
    // scrollbar-width: none;
    // -ms-overflow-style: none;
    // &::-webkit-scrollbar {
    //   display: none; /* Chrome, Safari, Opera*/
    // }
    // justify-content: space-between;
  }

  .post-msg {
    margin: 14px 0 20px;
    &:after {
      content: "";
      display: block;
      clear: both;
    }
    li {
      &:first-child {
        transition: height 0.3s linear;
      }
      &.direct-msg {
        margin-top: 8px;
        border: 1px solid $grey-1;
      }
      position: relative;
      background-color: $grey-0;
      color: $grey-5;
      line-height: 44px;
      text-indent: 15px;
      overflow: hidden;
      cursor: pointer;
      .post-text {
        border: 1px solid $grey-1;
      }
      .icon {
        position: absolute;
        top: 10px;
        right: 11px;
        width: 24px;
        height: 24px;

        img {
          display: block;
          width: 100%;
        }
      }
      input {
        display: block;
        width: 100%;
        border: 0;
        line-height: 44px;
        font-size: 15px;
        text-indent: 15px;
        background-color: $white;
      }
      ul {
        li {
          .post-text {
            border-top: 0;
          }
        }
      }
    }
  }
}
