.slideup-modalpopup {
  touch-action: none;
  &.active {
    display: block;
  }
  display: none;
  width: 100%;
  min-width: 375px;
  max-width: 750px;
  height: 100vh;
  position: fixed;
  z-index: 25;
  left: 50%;
  transform: translateX(-50%);
  bottom: 0;
  background: rgba(33, 33, 33, 0.8);

  .popup-box {
    overflow-y: scroll;
    max-height: calc(var(--vh, 1vh) * 100 - 56px);
    animation: slideUpAni 0.15s linear;
    background: white;
    position: absolute;
    bottom: 0;
    width: 100%;
    border-radius: 10px 10px 0 0;
    padding: 26px 0 0;
    .popup-header-area {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0 16px;
      .title {
        font-family: "Pretendard-SemiBold";
        font-size: 20px;
        letter-spacing: -0.5px;
        color: $grey-9;
      }
      .close-popup-btn {
        width: 24px;
        height: 24px;
        background: url(../../assets/img/icClose24Black.svg) no-repeat center /
          contain;
        cursor: pointer;
      }
    }
    .popup-contents-area {
      &.no-btn {
        max-height: calc(var(--vh, 1vh) * 100 - 56px - 50px);
        overflow-y: hidden;
      }
      max-height: calc(var(--vh, 1vh) * 100 - 56px - 50px - 107.5px);
      overflow-y: hidden;
    }
    .popup-button-area {
      display: flex;
      column-gap: 8px;
      padding: 15px 16px 42px;
      border-top: 1px solid $grey-1;
      .popup-btn {
        padding: 14px;
        border-radius: 4px;
        border: none;
        font-family: "Pretendard-Medium";
        font-size: 16px;
        letter-spacing: -0.16px;
        cursor: pointer;
        &.init-btn {
          width: 30%;
          background: $white;
          border: 1px solid $grey-1;
          color: $grey-7;
        }
        &.apply-btn {
          width: 70%;
          color: $white;
          background: $main-purple-dark-1;
        }
        &.apply-btn.promo11st {
          background-color: #0f0fd9;
        }
      }
    }
  }
  @keyframes slideUpAni {
    from {
      bottom: -50%;
    }
    to {
      bottom: 0;
    }
  }
}
