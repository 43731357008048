.contact-with {
  width: 100%;
  height: 100%;
  padding: 0 16px 24px;

  .content {
    // padding: 0 16px;
    margin: 28px 0 32px;

    .title {
      font-size: 20px;
      line-height: 34px;
      letter-spacing: -0.5px;
      font-family: "Pretendard-Medium";
    }

    .body {
      margin-top: 32px;
      display: flex;
      justify-content: space-evenly;

      .body-wrapper {
        text-align: center;

        .explain {
          font-size: 12px;
          line-height: 14px;
          letter-spacing: -0.5px;
          font-family: "Pretendard-Regular";
          color: $grey-9;
          margin-top: 12px;
        }
        .main {
          font-size: 20px;
          letter-spacing: -0.5px;
          font-family: "Pretendard-Bold";
          color: $main-purple-dark-1;
          margin-top: 10px;
        }
      }
    }
  }

  .inquiry {
    display: flex;
    flex-direction: column;
    margin-bottom: 16px;

    .for-agent {
      min-width: 343px;
      height: 202px;
      border-radius: 6px;
      border: 1px solid $grey-1;
      padding: 24px 20px 20px;
      margin-bottom: 16px;

      .title {
        font-size: 16px;
        letter-spacing: -0.5px;
        font-family: "Pretendard-Bold";
        color: $grey-9;
        text-align: start;
      }

      .body {
        font-size: 14px;
        line-height: 22px;
        font-family: "Pretendard-Regular";
        color: $grey-7;
        text-align: start;
        margin-top: 16px;
      }

      .inq-btn {
        min-width: 300px;
        width: 100%;
        height: 44px;
        border: none;
        border-radius: 4px;
        color: $white;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: $main-purple-dark-1;
        margin-top: 16px;
        font-size: 14px;
        font-family: "Pretendard-Medium";
      }
    }
  }
}