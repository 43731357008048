.full-menu {
  width: 100%;
  height: 100%;
  padding-bottom: 72px;
  
  .first {
    width: 100%;
    // height: 250px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 8px 24px;
    // box-shadow: 0 2px 2px 0 rgba($grey-8, 0.2);
    border-bottom: 1px solid $grey-1;

    .menu {
      width: 100%;
      height: 50px;
      line-height: 50px;
      cursor: pointer;
    }
  }
}