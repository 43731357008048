.faq {
  .select-tab {
    display: flex;
    flex-direction: column;
    min-width: 343px;
    align-items: center;
    padding: 16px;

    .btn-wrapper {
      display: flex;
      width: 100%;

      .tab-btn {
        width: 100%;
        min-width: 80px;
        min-height: 38px;
        background-color: $grey-0;
        color: $grey-8;
        border: none;
        border-radius: 4px;
        margin: 2px;
        font-family: "Pretendard-Medium";
        font-size: 13px;
        line-height: 26px;
        letter-spacing: -0.32px;

        &.active {
          background-color: $main-purple-0;
          color: $white;
        }
      }
    }
  }

  .faq-content {
    display: flex;
    flex-direction: column;
    // padding: 0 16px;

    .title {
      display: flex;
      align-items: flex-start;
      justify-content: space-around;
      min-height: 50px;
      border-bottom: 1px solid $grey-2;
      font-size: 13px;
      letter-spacing: -0.32px;
      line-height: 20px;
      font-family: "Pretendard-Regular";
      color: $grey-6;
      // padding: 16px;
      padding: 16px 8px;
      margin: 0 16px;

      p {
        min-width: 50px;
      }

      .subject {
        margin-left: 16px;
        color: $grey-9;
        min-width: 234px;
        width: 100%;
      }
    }

    .body {
      min-height: 30px;
      background-color: $grey-0;
      font-size: 13px;
      line-height: 20px;
      letter-spacing: -0.32px;
      color: $grey-7;
      font-family: "Pretendard-Regular";
      padding: 16px;
    }
  }
}
