.PromotionDetail {
  width: 100%;
  .img-wrap {
    width: 100%;
    img {
      width: 100%;
      display: block;
    }
  }
}
