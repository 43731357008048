.my-review {
  .default-text {
    min-height: inherit;
    display: flex;
    justify-content: center;
    padding-top: 89px;
    font-family: "Pretendard-Regular";
    font-size: 17px;
    letter-spacing: -0.42px;
    color: $grey-5;
  }
  .review-items-wrap {
    border-top: 10px solid $grey-0;
    .my-review-box {
      padding: 20px 24px 16px;
      border-bottom: 1px solid $grey-1;
      .row {
        display: flex;
        align-items: center;
        column-gap: 12px;
        margin-bottom: 14px;
        .img {
          width: 59px;
          height: 59px;
          border-radius: 6px;
          background-position: center;
          background-repeat: no-repeat;
          background-size: cover;
        }
        .col {
          .title {
            display: flex;
            align-items: center;
            font-family: "Pretendard-SemiBold";
            font-size: 18px;
            letter-spacing: -0.45px;
            color: $grey-8;
            margin-bottom: 7.8px;
            .middot {
              color: $grey-5;
            }
            .arrow {
              width: 24px;
              height: 24px;
              background: url(../../../assets/img/icArrow24Medium.svg) no-repeat
                center / contain;
            }
          }
          .reg-time {
            font-family: "Pretendard-Regular";
            font-size: 11px;
            letter-spacing: -0.28px;
            color: $grey-5;
          }
        }
      }
      .review-btn {
        width: 100%;
        padding: 15px;
        border-radius: 6px;
        border: none;
        font-family: "Pretendard-SemiBold";
        font-size: 15px;
        letter-spacing: -0.15px;
        &.write-review-btn {
          background: $main-purple-dark-1;
          color: $white;
        }
        &.view-review-btn {
          background: $grey-3;
          color: $white;
        }
      }
    }
  }
  .unwritten-review {
    min-height: calc(100vh - 56px - 49.5px);
    // header-height: 56px, tabbar-height: 49.5px
  }
}

// ////////////////
.myreview-write {
  min-height: calc(100vh - 56px);
  .depth-1st {
    min-height: inherit;
    .point-area,
    .satisfaction-area {
      display: flex;
      flex-direction: column;
      // align-items: center;
      text-align: center;
      padding: 50px 34px 45px;
      border-bottom: 1px solid $grey-1;
      .title {
        font-family: "Pretendard-SemiBold";
        font-size: 20px;
        color: $grey-9;
        margin-bottom: 8px;
      }
      .info {
        font-family: "Pretendard-Regular";
        font-size: 14px;
        color: $grey-5;
        margin-bottom: 24px;
      }
      .point {
        column-gap: 2px;
        display: flex;
        justify-content: center;
        .star {
          width: 36px;
          height: 36px;
          background: url(../../../assets/img/icStrar36Off.svg) no-repeat center /
            contain;
          cursor: pointer;

          &.active {
            background: url(../../../assets/img/icStrar36On.svg) no-repeat
              center / contain;
            transition: 0.2s linear;
          }
        }
      }
      .satisfaction {
        .sat-btn {
          &.active {
            color: $main-purple-0;
            border: 1px solid $main-purple-light-4;
            background: rgb(249, 244, 255);
          }
          &:last-child {
            margin-bottom: 0;
          }
          transition: 0.2s linear;
          width: 100%;
          display: flex;
          align-items: center;
          padding: 8px 14px;
          border-radius: 4px;
          border: 1px solid $grey-1;
          background: $white;
          margin-bottom: 8px;
          font-family: "Pretendard-Regular";
          font-size: 13px;
          color: $grey-8;
          letter-spacing: -0.39px;
          cursor: pointer;
          span {
            width: 36px;
            height: 36px;
            margin-right: 8px;
            &.img1 {
              background: url(../../../assets/img/icGood36.svg) no-repeat center /
                contain;
            }
            &.img2 {
              background: url(../../../assets/img/icMoney36.svg) no-repeat
                center / contain;
            }
            &.img3 {
              background: url(../../../assets/img/icCalendar36.svg) no-repeat
                center / contain;
            }
          }
        }
      }
    }
    .satisfaction-area {
      border-bottom: none;
      padding-bottom: 206px;
    }
    .btn-area {
      min-width: 375px;
      max-width: 750px;
      width: 100%;
      position: fixed;
      bottom: 0;
      left: 50%;
      transform: translateX(-50%);
      padding: 0 16px 45px;
      .next-btn {
        width: 100%;
        background: $grey-3;
        color: $white;
        font-family: "Pretendard-SemiBold";
        font-size: 16px;
        letter-spacing: -0.16px;
        padding: 19px;
        border: none;
        border-radius: 6px;
        pointer-events: none;
        &.active {
          background: $main-purple-dark-1;
          pointer-events: auto;
        }
      }
    }
  }
  .depth-2nd {
    min-height: inherit;
    .point-area {
      display: flex;
      flex-direction: column;
      // align-items: center;
      text-align: center;
      padding: 26px 18px 22px;
      .title {
        font-family: "Pretendard-SemiBold";
        font-size: 16px;
        letter-spacing: -0.4px;
        color: $grey-9;
        margin-bottom: 18px;
      }
      .info {
        font-family: "Pretendard-Regular";
        font-size: 14px;
        color: $grey-5;
        margin-bottom: 24px;
      }
      .point {
        column-gap: 5px;
        display: flex;
        justify-content: center;
        margin-bottom: 7px;
        .star {
          width: 28px;
          height: 28px;
          background: url(../../../assets/img/icStrar28Off.svg) no-repeat center /
            contain;
          cursor: pointer;
          &.active {
            background: url(../../../assets/img/icStrar28On.svg) no-repeat
              center / contain;
            transition: 0.2s linear;
          }
        }
      }
      .point-num {
        font-family: "Pretendard-Medium";
        font-size: 18px;
        letter-spacing: -0.45px;
        color: $grey-8;
      }
    }
    .text-area {
      padding: 0 16px;
      .textarea-form {
        resize: none;
        width: 100%;
        height: 130px;
        border: none;
        border-top: 1px solid $grey-1;
        border-bottom: 1px solid $grey-1;
        padding: 14px 0;
        outline: none;
        font-family: "Pretendard-Regular";
        font-size: 13px;
        letter-spacing: -0.39px;
        color: $grey-8;
        line-height: 20px;
        &::placeholder {
          color: $grey-5;
        }
      }
    }
    .image-area {
      padding: 18px 16px 28px;
      .before,
      .after {
        .row {
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin-bottom: 3px;
          .col1 {
            display: flex;
            align-items: center;
            column-gap: 4px;
            .title {
              font-family: "Pretendard-SemiBold";
              font-size: 14px;
              letter-spacing: -0.35px;
              color: $grey-8;
            }
            .info {
              font-family: "Pretendard-Regular";
              font-size: 11px;
              letter-spacing: -0.44px;
              color: $grey-5;
            }
          }
          .reload-btn {
            width: 24px;
            height: 24px;
            background: url(../../../assets/img/icReset24GrayM.svg) no-repeat
              center / contain;
          }
        }
        .img-slide-wrap {
          padding: 10px 0 0px;
          display: flex;
          column-gap: 8px;
          overflow-x: scroll;
          .add-file-input {
            display: none;
          }
          .add-file-btn {
            flex: 0 0 auto;
            background: $white;
            width: 72px;
            height: 72px;
            border: 1px solid $grey-1;
            border-radius: 4px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            .camera-icon {
              width: 24px;
              height: 24px;
              background: url(../../../assets/img/icPhoto24.svg) no-repeat
                center / contain;
              margin-bottom: 2px;
            }
            .caption {
              font-family: "Pretendard-Regular";
              font-size: 12px;
              letter-spacing: -0.3px;
              color: $grey-9;
            }
          }
          .repair-img-box-wrap {
            flex: 0 0 auto;
            display: flex;
            column-gap: 8px;
            .repair-img-box {
              flex: 0 0 auto;
              width: 72px;
              height: 72px;
              border-radius: 4px;
              position: relative;
              background-position: center;
              background-size: cover;
              background-repeat: no-repeat;
              .delete-btn {
                width: 24px;
                height: 24px;
                background: url(../../../assets/img/icDelete24.svg) no-repeat
                  center / contain;
                position: absolute;
                right: 0;
                top: 0;
                transform: translate(40%, -40%);
              }
            }
          }
        }
      }
      .before {
        margin-bottom: 16px;
      }
    }
    .agree-area {
      border-top: 1px solid $grey-1;
      background: $grey-0;
      padding: 18px 16px calc(18px + 92px);
      color: $grey-8;
      .title {
        font-family: "Pretendard-SemiBold";
        font-size: 16px;
        letter-spacing: -0.48px;
        margin-bottom: 12px;
      }
      .para {
        font-family: "Pretendard-Regular";
        font-size: 12px;
        letter-spacing: -0.36px;
        line-height: 21px;
        margin-bottom: 4px;
      }
      .check {
        font-family: "Pretendard-Medium";
        font-size: 13px;
        letter-spacing: -0.39px;
        color: $grey-8;
        display: flex;
        align-items: center;
        column-gap: 6px;
        margin-top: 12px;
        &.active {
          .check-img {
            background: url(../../../assets/img/icCheckbox18On.svg) no-repeat
              center / contain;
          }
        }
        .check-img {
          width: 18px;
          height: 18px;
          background: url(../../../assets/img/checkBox.svg) no-repeat center /
            contain;
        }
      }
    }
  }
}

.none {
  display: none;
}
