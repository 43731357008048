.manage-password {
  width: 100%;
  height: calc(100vh - 56px);
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  .password-content {
    .input {
      width: 100%;
      min-height: 60px;
      display: flex;
      align-items: center;
      border-bottom: 1px solid $grey-1;
      padding: 0 16px;
  
      input {
        width: 70%;
        border: none;
      }
  
      button {
      margin-left: auto;
      border: none;
      background-color: transparent;
      font-size: 15px;
      line-height: 20px;
      letter-spacing: -0.45px;
      font-family: "Pretendard-Regular";
      color: $blue-500;
      }
    }
    
    .input-explain {
      padding: 0 16px;
      margin-top: 10px;
      font-size: 16px;
      line-height: 20px;
      letter-spacing: -0.45px;
      color: $grey-8;
    }
  }

  .input-enter {
    min-width: 344px;
    height: 58px;
    color: $white;
    background-color: $grey-3;
    margin: 0 16px 40px 16px;
    border-radius: 8px;
    display: flex;
    justify-content: center;
    align-items: center;

    button {
      font-size: 16px;
      line-height: 25px;
      letter-spacing: -0.16px;
      font-family: "Pretendard-SemiBold";
      color: $white;
      border: none;
      background-color: transparent;
    }

    &.active {
      background-color: $main-purple-dark-1;
      pointer-events: initial;
    }
  }
}