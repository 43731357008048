.store-info {
  width: 100%;
  height: 100%;
  font-family: "Pretendard-Regular";

  .info {
    min-width: 343px;
    min-height: 36px;
    padding: 16px;
    font-size: 13px;
    line-height: 21px;
    letter-spacing: -0.32px;
    color: $grey-8;
    background-color: $grey-0;
  }
  
  .content {

    .content-body {
      padding: 20px;

      .title {
        font-size: 18px;
        letter-spacing: -0.72px;
        color: $grey-8;
        font-family: "Pretendard-Bold";
        margin-bottom: 8px;
      }
      .body {
        display: flex;
        font-size: 13px;
        line-height: 16px;
        letter-spacing: -0.32px;
        color: $grey-9;
        font-family: "Pretendard-Regular";
        padding: 6px 0;
        
        .name {
          min-width: 48px;
          margin-right: 18px;
          color: $grey-5;
        }
        .store-value {
          width: 210px;
          word-wrap: break-word;
          word-break: keep-all;

          .tel {
            border-bottom: 1px solid;
          }
        }
      }
    }
    .location-btn {
      font-size: 13px;
      line-height: 22px;
      letter-spacing: -0.5;
      color: $main-purple-dark-1;
      font-family: "Pretendard-Regular";
      border: none;
      background-color: transparent;
      padding: 8px 0 8px 60px;
    }
  }

}

.location-modal {
  background-color: $white;
  width: 100%;
  max-width: 750px;
  height: 100%;
  z-index: 100;
  // position: absolute;
  position: fixed;
  top: 0;

  .modal-header {
    height: 56px;
    border-bottom: 1px solid $grey-1;
    // position: relative;
    font-size: 18px;
    font-family: "Pretendard-Bold";
    display: flex;
    justify-content: center;
    align-items: center;
    
    button {
      position: absolute;
      right: 0;
      padding: 16px;
      border: none;
      background-color: transparent;
    }
  }
}