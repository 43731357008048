.sign-up{
  // display:flex;
  // flex-direction: column;
  // justify-content: center;
  // min-height: 100vh;
  .main-title{
    width:331px;
    margin:10px auto 20px;
    font-family:"Pretendard-Bold";
    font-size:27px;
    letter-spacing: -0.81px;
    line-height:39px;
    color: $grey-9;
  }
  .input-area{
    width:331px;
    margin:0 auto;
    p{
      &.label-area{
        position:relative;
        // display:none;
      }
      margin-bottom:8px;
    }
    .user-pass-area{
      position: relative;
    }
    input{
      &:focus{
        border-color: $main-purple-light-4;
        caret-color: $main-purple-light-4;
        outline:none;
      }
      &#user-input-phone{
        width:235px;
        margin-right:6px;
        color:$grey-5;
      }
      &[type="button"]{
        &.active{
          background-color:$main-purple-dark-1;
        }
        &:focus{
          border-color:$grey-3;
        }
        width:90px;
        font-family:"Pretendard-Medium";
        background-color:$grey-3;
        border:1px solid $grey-3;
        font-size:15px;
        letter-spacing: -0.45px;
        color: $white;
        text-indent:0;
      }
      width:100%;
      line-height:44px;
      border:1px solid $grey-1;
      border-radius: 4px;
      text-indent:15px;

    }
    label{
      &[for="certification"]{
        position:absolute;
        right:14px;
        top:14px;
        color:$main-purple-0;
        font-family:"Pretendard-Regular";
        letter-spacing: -0.45px;
        font-size:15px;
        line-height:20px;
      }
    }
    .view-hidden-icon{
      position: absolute;
      right:12px;
      top:12px;
      display:inline-block;
      width:24px;
      height:24px;
      img{
        width:100%;
      }
    }
    .guide{
      margin-top:11px;
      display:inline-block;
      color:$main-purple-0;
      font-family:"Pretendard-Regular";
      letter-spacing: -0.45px;
      font-size:15px;
      line-height:20px;
      span{
        display:inline-block;
        &.check-text{
          height:24px;
        }
        &.check-icon{
          width:24px;
          vertical-align: middle;
          margin-right:20px;
          img{
            width:100%;
          }
        }
      }
    
    }
    .gender{
      display:flex;
      flex-direction: row;
      button{
        &.active{
          border-color:$main-purple-light-4;
          color: $main-purple-dark-1;
        }
        &[value="여"]{
          margin:0 4px;
        }
        width:46px;
        border:1px solid $grey-1;
        background-color: $white;
        flex-shrink: 0;
        font-size:15px;
        font-family: "Pretendard-Regular";
        letter-spacing: -0.45px;
        color:$grey-8;
        cursor:pointer;
        border-radius: 4px;
        margin-left: 4px;
        outline-color: $main-purple-0;
      }
    }
  }
  .checkbox-area{
    width:331px;
    margin:0 auto;
    input{
      &:checked +label .check-bg{
        background-image: url("../../assets/img/icCheck21On.svg");
      }
      display:none;
    }
    label{
      width:24px;
      height:24px;
      margin-right:7px;
      .check-bg{
        display:block;
        width:20px;
        height:20px;
        background-size: 100% 100%;
        background-image: url("../../assets/img/icCheck21Off.svg");
        cursor: pointer;
      }
    }
    .agree-all, .agree-area{
      border-radius: 6px;
      .agree-title{
        display:inline-block;
      }
    }
    .agree-all{
      display:flex;
      background-color: rgba(247,248,249,1);
      line-height:55px;
      margin-bottom:11px;
      padding:0 10px 0 17px;
      align-items: center;
      .agree-title{
        font-family:"Pretendard-Medium";
        font-size:18px;
        letter-spacing: -0.45px;
        color: $grey-9;
      }
    }
    .agree-area{
      border:1px solid $grey-1;
      padding:20px 10px 20px 17px;
      >div{
        &:last-child{
          margin-bottom: 0;
        }
        display:flex;
        margin-bottom:16px;
        .agree-title{
          
          flex-grow:1;
          line-height:23px;
          color: $grey-6;
          letter-spacing: -0.35px;
          font-size:14px;
          font-family:"Pretendard-Regular";
        }
      }

    }
    
  }
  .agree-modal{
    display:none;
    position: absolute;
    top:0;
    max-width: 750px;
  }
}