.Payment {
  background-color: $grey-0;
  padding-bottom: 80px;
  position: relative;
  // 배송 정보
  .sec1 {
    background-color: $white;
    padding: 16px;
    border-bottom: 1px solid $grey-1;
    .title-line {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 10px;
      .title {
        font-family: "Pretendard-Bold";
        color: $new-black;
        font-size: 16px;
      }
      .list {
        all: unset;
        cursor: pointer;
        color: $main-purple-dark-1;
        font-size: 12px;
        font-family: "Pretendard-Regular";
        line-height: 100%;
        border: 1px solid $main-purple-light-4;
        padding: 8px 12px;
        border-radius: 99px;
        background-color: $white;
      }
    }
    .address-input {
      .tab-menu {
        display: flex;
        text-align: center;
        font-family: "Pretendard-Regular";
        font-size: 14px;
        letter-spacing: -0.5px;
        color: $grey-7;
        line-height: 100%;
        background-color: $grey-0;
        border-radius: 4px 4px 0 0;
        overflow: hidden;
        border: 1px solid $grey-1;
        border-bottom: none;
        .tab {
          all: unset;
          cursor: pointer;
          padding: 17px 0;
          width: 100%;
          border-bottom: 1px solid $grey-1;
          &.active {
            background-color: $white;
            border-radius: 4px 4px 0 0;
            color: $grey-9;
            border-bottom: 1px solid $white;
            &.left {
              border-right: 1px solid $grey-1;
            }
            &.right {
              border-left: 1px solid $grey-1;
            }
          }
        }
      }
      // 신규 배송지 (0)
      .tab-content0 {
        margin-bottom: 12px;
        border: 1px solid $grey-1;
        border-top: 0px;
        border-radius: 0 0 4px 4px;
        padding: 20px 20px;
        font-family: "Pretendard-Regular";
        font-size: 14px;
        line-height: 100%;
        letter-spacing: -0.5px;
        display: flex;
        flex-direction: column;
        color: $grey-9;
        gap: 10px;
        .item {
          .line {
            display: flex;
            gap: 6px;
            align-items: center;
            font-family: "Pretendard-Regular";
            color: $grey-9;
            font-size: 14px;
            line-height: 100%;
            margin-bottom: 14px;
            .icon {
              font-size: 10px;
              font-family: "Pretendard-Medium";
              color: $main-purple-dark-1;
              padding: 4px 6px;
              border: 1px solid $main-purple-0;
              border-radius: 4px;
              line-height: 100%;
            }
            .bar {
              color: $grey-3;
            }
          }
          .addr {
            font-family: "Pretendard-Regular";
            color: $grey-7;
            line-height: 22.4px;
          }
        }
        .empty {
          font-family: "Pretendard-Regular";
          font-size: 14px;
          line-height: 22.4px;
          letter-spacing: -0.5%;
          color: $grey-5;
          text-align: center;
          padding: 15px 0;
        }
      }
      // 신규 배송지 (1)
      .tab-content1 {
        margin-bottom: 12px;
        border: 1px solid $grey-1;
        border-top: 0px;
        border-radius: 0 0 4px 4px;
        padding: 20px 16px;
        font-family: "Pretendard-Regular";
        font-size: 14px;
        line-height: 100%;
        letter-spacing: -0.5px;
        display: flex;
        flex-direction: column;
        color: $grey-9;
        gap: 10px;
        .line {
          display: flex;
          align-items: center;
          .label {
            width: 64px;
            flex: 0 0 auto;
          }
          input {
            border: 1px solid $grey-1;
            width: 100%;
            padding: 15px 16px;
            line-height: 14px;
            border-radius: 4px;
            font-size: 14px;
            height: 44px;
            &:focus {
              border: 1px solid $main-purple-light-4;
              outline: none;
            }
          }
          .input-wrap {
            width: 100%;
            @supports (-webkit-touch-callout: none) {
              input {
                &:disabled {
                  border: 1px solid $grey-2;
                  background-color: $grey-1;
                }
              }
            }
          }
          .zip-code {
            display: flex;
            width: 100%;
            gap: 8px;
            .input-wrap {
              width: calc(100% / 3 * 2);
              input {
                width: 100%;
                height: 44px;
                background-color: $grey-0;
              }
            }
            button {
              all: unset;
              cursor: pointer;
              width: calc(100% / 3);
              background-color: $white;
              text-align: center;
              color: $main-purple-dark-1;
              border-radius: 4px;
              border: 1px solid $main-purple-dark-1;
            }
          }
          .addr1 {
            background-color: $grey-0;
          }
        }
        .chk-area {
          padding-top: 9px;
          padding-bottom: 3px;
          display: flex;
          align-items: center;

          label {
            cursor: pointer;
            width: 100%;
            display: flex;
            align-items: center;
            gap: 7px;

            .chk-img {
              width: 24px;
              height: 24px;
              aspect-ratio: 1/1;
              background: url(../../../assets/img/check_small_default.svg)
                no-repeat center / contain;
            }

            input {
              display: none;
              &:checked {
                & + .chk-img {
                  background: url(../../../assets/img/check_small_selected.svg)
                    no-repeat center / contain;
                }
              }
            }
          }
        }
      }
      .select-box {
        font-size: 14px;
        button {
          display: flex;
          position: relative;
          justify-content: space-between;
          align-items: center;
          width: 100%;
          border: 1px solid $grey-1;
          border-radius: 4px;
          height: 44px;
          padding: 15px 16px;
          font-size: 14px;
          line-height: 100%;
          letter-spacing: -0.5px;
          background-color: $white;
          color: $grey-9;
          cursor: pointer;
          .arrow-img {
            width: 24px;
            height: 24px;
            &.down {
              background: url(../../../assets/img/icon_arrow_bottom_black_24.svg)
                no-repeat center / contain;
            }
            &.up {
              background: url(../../../assets/img/icon_arrow_top_black_24.svg)
                no-repeat center / contain;
            }
          }
          .opt-list {
            text-align: left;
            background-color: $white;
            width: 100%;
            position: absolute;
            top: 44px;
            z-index: 10;
            left: 0;
            height: 0px;
            overflow: hidden;
            transition: 0.3s;
            display: flex;
            flex-direction: column;
            padding: 0 16px;
            box-shadow: 0px 2px 8px 0px rgba(83, 83, 84, 0.15);
            border-radius: 8px;
            input {
              display: none;
              &:checked {
                & + label {
                  color: $main-purple-dark-1;
                }
              }
            }
            label {
              cursor: pointer;
              padding: 15px 0px;
              height: 44px;
              color: $grey-9;
              &:not(:last-child) {
                border-bottom: 1px solid $grey-1;
              }
            }
          }
        }

        &.active {
          .opt-list {
            height: fit-content;
            overflow: scroll;
          }
        }
      }
      .self-write {
        width: 100%;
        padding: 15px 16px;
        font-family: "Pretendard-Regular";
        letter-spacing: -0.5px;
        color: $grey-9;
        font-size: 14px;
        height: 44px;
        margin-top: 8px;
        border-radius: 4px;
        border: 1px solid $grey-1;
        &:focus {
          border: 1px solid $main-purple-light-4;
          outline: none;
        }
      }
    }
  }
  // 수거 박스 선택
  .sec2 {
    margin-top: 11px;
    background-color: $white;
    padding: 16px;
    border-bottom: 1px solid $grey-1;
    .title-line {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 16px;
      line-height: 100%;
      .title {
        font-family: "Pretendard-Bold";
        color: $new-black;
        font-size: 16px;
      }
      .sub {
        font-family: "Pretendard-Regular";
        font-size: 12px;
        letter-spacing: -0.5px;
        color: $grey-7;
      }
    }
    .box-area {
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 8px;

      input {
        display: none;
        &:checked {
          & + label {
            border: 1px solid $main-purple-light-4;
            background-color: $main-purple-light-1;
            .text,
            .name {
              color: $main-purple-dark-1;
            }
            .chk-img {
              background: url(../../../assets/img/check_small_selected.svg)
                no-repeat center / contain;
            }
          }
        }
      }

      label {
        cursor: pointer;
        position: relative;
        height: 84px;
        border: 1px solid $grey-1;
        border-radius: 6px;
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;

        .name {
          font-family: "Pretendard-Bold";
          font-size: 16px;
          letter-spacing: -0.5px;
          line-height: 16px;
          color: $grey-7;
          margin-bottom: 4px;
        }

        .text {
          font-family: "Pretendard-Regular";
          font-size: 14px;
          line-height: 23.8px;
          color: $grey-7;
          span {
            font-family: "Pretendard-Bold";
          }
        }

        .chk-img {
          position: absolute;
          left: 6px;
          top: 6px;
          width: 24px;
          height: 24px;
          aspect-ratio: 1/1;
          background: url(../../../assets/img/check_small_default.svg) no-repeat
            center / contain;
        }

        input {
          display: none;
          &:checked {
            & + .chk-img {
              background: url(../../../assets/img/check_small_selected.svg)
                no-repeat center / contain;
            }
          }
        }
      }
    }
    .sec2-info {
      padding: 17px;
      margin-top: 12px;
      background-color: $grey-0;
      h3 {
        display: flex;
        align-items: center;
        gap: 4px;
        .icon {
          width: 16px;
          height: 16px;
          background: url(../../../assets/img/icon_info_line_purple_16.svg)
            no-repeat center / contain;
        }
        font-family: "Pretendard-Bold";
        font-size: 14px;
        color: $main-purple-dark-1;
        line-height: 100&;
        letter-spacing: -0.5px;
        margin-bottom: 13px;
      }
      div {
        display: flex;
        flex-direction: column;
        gap: 8px;
        p {
          font-family: "Pretendard-Regular";
          font-size: 14px;
          line-height: 19.6px;
          letter-spacing: -0.5px;
          color: $grey-9;
        }
      }
    }
  }
  // 쿠폰
  .sec3 {
    &.hidden {
      display: none;
    }
    margin-top: 11px;
    background-color: $white;
    padding: 16px;
    border-bottom: 1px solid $grey-1;
    .title-line {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 16px;
      line-height: 100%;
      .title {
        font-family: "Pretendard-Bold";
        color: $new-black;
        font-size: 16px;
      }
    }
    .coupon-sub-title {
      font-family: "Pretendard-Bold";
      font-size: 12px;
      line-height: 100%;
      color: $grey-9;
      padding: 20px 0 10px;
    }
    .select-box {
      font-size: 14px;
      button {
        display: flex;
        position: relative;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        border: 1px solid $grey-1;
        border-radius: 4px;
        height: 44px;
        padding: 15px 16px;
        font-size: 14px;
        line-height: 100%;
        letter-spacing: -0.5px;
        background-color: $white;
        color: $grey-9;
        cursor: pointer;
        .arrow-img {
          width: 24px;
          height: 24px;
          &.down {
            background: url(../../../assets/img/icon_arrow_bottom_black_24.svg)
              no-repeat center / contain;
          }
          &.up {
            background: url(../../../assets/img/icon_arrow_top_black_24.svg)
              no-repeat center / contain;
          }
        }
        .opt-list {
          text-align: left;
          background-color: $white;
          width: 100%;
          position: absolute;
          top: 44px;
          z-index: 10;
          left: 0;
          height: 0px;
          overflow: hidden;
          transition: 0.3s;
          display: flex;
          flex-direction: column;
          padding: 0 16px;
          box-shadow: 0px 2px 8px 0px rgba(83, 83, 84, 0.15);
          border-radius: 8px;
          input {
            display: none;
            &:checked {
              & + label {
                color: $main-purple-dark-1;
              }
            }
          }
          label {
            cursor: pointer;
            padding: 15px 0px;
            height: 44px;
            color: $grey-9;
            &:not(:last-child) {
              border-bottom: 1px solid $grey-1;
            }
          }
        }
      }
      &.active {
        .opt-list {
          height: fit-content;
          overflow: scroll;
        }
      }
    }
    .register {
      margin-top: 16px;
      .register-tab {
        all: unset;
        cursor: pointer;
        display: flex;
        align-items: center;
        gap: 6px;
        font-family: "Pretendard-Medium";
        color: $main-purple-dark-1;
        font-size: 14px;
        line-height: 100%;
        letter-spacing: -0.5%;
        margin-bottom: 16px;
        .coupon-img {
          width: 24px;
          height: 24px;
          background: url(../../../assets/img/icon_coupon_purple_24.svg)
            no-repeat center / contain;
        }
        .text {
          display: flex;
          gap: 2px;
          align-items: center;
          .arrow-img {
            width: 16px;
            height: 16px;
            background: url(../../../assets/img/icon_arrow_bottom_purple_16.svg)
              no-repeat center / contain;
          }
        }
      }
      .input-area {
        display: flex;
        gap: 8px;
        input {
          width: calc(100% / 3 * 2);
          border: 1px solid $grey-1;
          padding: 15px 16px;
          line-height: 14px;
          border-radius: 4px;
          font-size: 14px;
          height: 44px;
          &:focus {
            border: 1px solid $main-purple-light-4;
            outline: none;
          }
        }
        button {
          background-color: $white;
          width: calc(100% / 3);
          border-radius: 4px;
          border: 1px solid $main-purple-light-4;
          color: $main-purple-dark-1;
          font-family: "Pretendard-Medium";
          letter-spacing: -0.5px;
          font-size: 14px;
          line-height: 100%;
          height: 44px;
          cursor: pointer;
        }
      }
    }
  }
  // 앤올캐시
  .sec4 {
    margin-top: 11px;
    background-color: $white;
    padding: 16px;
    border-bottom: 1px solid $grey-1;
    .title-line {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 16px;
      line-height: 100%;
      .title {
        font-family: "Pretendard-Bold";
        color: $new-black;
        font-size: 16px;
      }
    }
    .nall-cash {
      display: flex;
      flex-direction: column;
      gap: 16px;
      color: $grey-9;
      .line {
        display: flex;
        gap: 8px;
        font-size: 14px;
        letter-spacing: -0.5%;
        input {
          height: 44px;
          width: calc(100% / 3 * 2);
          padding: 15px 16px;
          border: 1px solid $grey-1;
          line-height: 100%;
          font-family: "Pretendard-Regular";
          border-radius: 4px;
          font-size: 14px;
          &:focus {
            border: 1px solid $main-purple-light-4;
            outline: none;
          }
        }
        button {
          height: 44px;
          width: calc(100% / 3);
          border: 1px solid $main-purple-light-4;
          border-radius: 4px;
          background-color: $white;
          font-family: "Pretendard-Medium";
          color: $main-purple-dark-1;
          cursor: pointer;
          font-size: 14px;
        }
        b {
          font-family: "Pretendard-Bold";
          &.purple {
            color: $main-purple-dark-1;
          }
        }
      }
    }
    .sec4-info {
      border-radius: 8px;
      padding: 17px;
      margin-top: 20px;
      background-color: $grey-0;
      h3 {
        display: flex;
        align-items: center;
        gap: 4px;
        .icon {
          width: 16px;
          height: 16px;
          background: url(../../../assets/img/icon_info_line_purple_16.svg)
            no-repeat center / contain;
        }
        font-family: "Pretendard-Bold";
        font-size: 14px;
        color: $main-purple-dark-1;
        line-height: 100&;
        letter-spacing: -0.5px;
        margin-bottom: 13px;
      }
      div {
        display: flex;
        flex-direction: column;
        gap: 8px;
        p {
          font-family: "Pretendard-Regular";
          font-size: 14px;
          line-height: 19.6px;
          letter-spacing: -0.5px;
          color: $grey-9;
        }
      }
    }
  }
  // 결제 금액
  .sec5 {
    margin-top: 11px;
    background-color: $white;
    padding: 16px;
    border-bottom: 1px solid $grey-1;
    .title-line {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 16px;
      line-height: 100%;
      .title {
        font-family: "Pretendard-Bold";
        color: $new-black;
        font-size: 16px;
      }
    }
    .cost {
      .cost-list {
        padding-top: 4px;
        padding-bottom: 24px;
        border-bottom: 1px solid $grey-1;
        display: flex;
        flex-direction: column;
        gap: 12px;
        font-family: "Pretendard-Regular";
        font-size: 14px;
        line-height: 100%;
        color: $grey-7;
        position: relative;
        .line {
          display: flex;
          justify-content: space-between;
          align-items: center;
          span:nth-child(2) {
            color: $grey-9;
            display: flex;
            gap: 2px;

            &.zero {
              color: $grey-5;
            }
            &.delivery-fee {
              display: flex;
              gap: 4px;
              align-items: center;
              del {
                font-size: 12px;
                line-height: 100%;
                color: $grey-5;
              }
              span {
                color: $main-purple-dark-1;
                b {
                  font-family: "Pretendard-Bold";
                }
              }
            }
          }
        }
        .tool-tip {
          padding: 10px 12px;
          background: $grey-8;
          color: $white;
          width: fit-content;
          border-radius: 99px;
          font-size: 12px;
          letter-spacing: -0.5px;
          line-height: 100%;
          position: absolute;
          right: 0;
          bottom: -16px;
          .triangle {
            width: 7px;
            aspect-ratio: 1 / 1;
            background: $grey-8;
            position: absolute;
            right: 15.5px;
            top: 0;
            transform: translateY(-50%) rotate(45deg);
          }
        }
      }

      .cost-result {
        padding-top: 28px;
        padding-bottom: 4px;
        text-align: right;
        .result {
          display: flex;
          gap: 2px;
          justify-content: flex-end;
          align-items: center;
          font-family: "Pretendard-Medium";
          margin-bottom: 8px;
          b {
            font-family: "Pretendard-Bold";
            font-size: 20px;
            letter-spacing: -0.5%;
          }
        }
        .points-area {
          display: flex;
          flex-direction: column;
          gap: 4px;
          .points {
            color: $grey-9;
            display: flex;
            gap: 4px;
            justify-content: flex-end;
            align-items: center;
            font-size: 12px;
            line-height: 100%;

            .icon {
              font-size: 10px;
              line-height: 100%;
              padding: 4px 8px;
              background-color: $main-purple-dark-1;
              color: $white;
              border-radius: 99px;
            }
          }
        }
      }
    }
  }
  // 결제 수단
  .sec6 {
    margin-top: 11px;
    background-color: $white;
    padding: 16px;
    .title-line {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 16px;
      line-height: 100%;
      .title {
        font-family: "Pretendard-Bold";
        color: $new-black;
        font-size: 16px;
      }
    }
    .payment-methods {
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 8px;
      font-family: "Pretendard-Regular";
      font-size: 14px;
      letter-spacing: -0.5px;
      input {
        display: none;
        &:checked {
          & + label {
            border: 1px solid $main-purple-light-4;
            background-color: $main-purple-light-1;
            color: $main-purple-dark-1;
          }
        }
      }
      label {
        cursor: pointer;
        width: 100%;
        border: 1px solid $grey-1;
        background-color: $white;
        border-radius: 4px;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 44px;
        line-height: 100&;
      }
    }
    .payment-agree {
      margin-top: 16px;
      padding-bottom: 10px;
      label {
        font-family: "Pretendard-Regular";
        font-size: 14px;
        letter-spacing: -0.5px;
        color: $grey-9;
        cursor: pointer;
        display: flex;
        align-items: center;
        gap: 6px;
        .chk-img {
          width: 24px;
          height: 24px;
          aspect-ratio: 1/1;
          background: url(../../../assets/img/check_small_default.svg) no-repeat
            center / contain;
        }
        input {
          display: none;
          &:checked {
            & + .chk-img {
              background: url(../../../assets/img/check_small_selected.svg)
                no-repeat center / contain;
            }
          }
        }
      }
    }
  }
  // 버튼
  .payment-button {
    z-index: 50;
    position: fixed;
    bottom: 0;
    // left: 50%;
    // transform: translateX(-50%);
    width: 100%;
    max-width: 750px;
    min-width: 375px;
    padding: 16px 0 48px;
    border: none;
    background-color: $grey-4;
    color: $white;
    font-family: "Pretendard-Medium";
    font-size: 16px;
    letter-spacing: -0.5%;
    line-height: 100%;
    &.active {
      background-color: $main-purple-dark-1;
      cursor: pointer;
    }
  }
  // 배송지 관리
  .manage-addr-list {
    position: fixed;
    left: 50%;
    transform: translateX(-50%);
    top: 0;
    width: 100%;
    min-width: 375px;
    max-width: 750px;
    height: calc(var(--vh, 1vh) * 100);
    background-color: $grey-0;
    z-index: 100;
    overflow: scroll;
    .item-area {
      padding: 16px;
      display: flex;
      flex-direction: column;
      gap: 16px;
      .item {
        background-color: $white;
        border: 0.5px solid $grey-1;
        padding: 20px;
        border-radius: 8px;
        box-shadow: 0px 2px 10px 0px rgba(83, 83, 84, 0.08);
        .name-line {
          display: flex;
          align-items: center;
          gap: 6px;
          font-family: "Pretendard-Regular";
          font-size: 14px;
          margin-bottom: 12px;
          color: $grey-9;
          .default-tag {
            color: $main-purple-dark-1;
            font-family: "Pretendard-Medium";
            font-size: 10px;
            line-height: 10px;
            border: 1px solid $main-purple-0;
            padding: 4px 6px;
            border-radius: 4px;
          }
          .bar {
            color: $grey-3;
          }
        }
        .address {
          line-height: 22.4px;
          font-size: 14px;
          font-family: "Pretendard-Regular";
          color: $grey-7;
          margin-bottom: 20px;
        }
        .btn-area {
          display: flex;
          justify-content: space-between;
          align-items: center;
          color: $grey-9;
          button {
            color: $grey-9;
            cursor: pointer;
            &.select-btn {
              background: $main-purple-dark-1;
              border: none;
              color: $white;
            }
            background: $white;
            border: 1px solid $grey-1;
            border-radius: 4px;
            padding: 8px 10px;
            line-height: 100%;
            font-size: 14px;
          }
          .left-btn-area {
            display: flex;
            gap: 8px;
          }
        }
      }
      .add-btn {
        margin-top: 12px;
        button {
          width: 100%;
          background: $main-purple-dark-1;
          color: $white;
          border-radius: 4px;
          height: 48px;
          border: none;
          font-size: 16px;
          line-height: 100%;
          letter-spacing: -0.5px;
          font-family: "Pretendard-Medium";
          cursor: pointer;
        }
      }
    }
    // .empty-area {
    .empty {
      font-family: "Pretendard-Regular";
      font-size: 16px;
      letter-spacing: -0.5px;
      color: $grey-7;
      line-height: 100%;
      text-align: center;
      display: flex;
      flex-direction: column;
      gap: 20px;
      margin-top: 266px;
      button {
        color: $white;
        background: $main-purple-dark-1;
        height: 44px;
        width: 148px;
        font-size: 16px;
        letter-spacing: -0.5px;
        border: none;
        border-radius: 4px;
        cursor: pointer;
        font-family: "Pretendard-Medium";
      }
    }
    // }
  }
  // 신규 배송지 등록
  .add-new-addr {
    background-color: $white;
    position: fixed;
    left: 50%;
    top: 0;
    transform: translateX(-50%);
    width: 100%;
    max-width: 750px;
    min-width: 375px;
    height: calc(var(--vh, 1vh) * 100);
    z-index: 200;
    .contents {
      margin-bottom: 12px;
      border-top: 0px;
      padding: 24px 16px;
      font-family: "Pretendard-Regular";
      font-size: 14px;
      line-height: 100%;
      letter-spacing: -0.5px;
      display: flex;
      flex-direction: column;
      color: $grey-9;
      gap: 10px;
      .line {
        display: flex;
        align-items: center;
        .label {
          width: 64px;
          flex: 0 0 auto;
        }
        input {
          border: 1px solid $grey-1;
          width: 100%;
          padding: 15px 16px;
          line-height: 14px;
          border-radius: 4px;
          font-size: 14px;
          height: 44px;
          &:focus {
            border: 1px solid $main-purple-light-4;
            outline: none;
          }
        }
        .input-wrap {
          width: 100%;
          @supports (-webkit-touch-callout: none) {
            input {
              &:disabled {
                border: 1px solid $grey-2;
                background-color: $grey-1;
              }
            }
          }
        }
        .zip-code {
          display: flex;
          width: 100%;
          gap: 8px;
          .input-wrap {
            width: calc(100% / 3 * 2);
            input {
              width: 100%;
              height: 44px;
              background-color: $grey-0;
            }
          }
          button {
            all: unset;
            cursor: pointer;
            width: calc(100% / 3);
            background-color: $white;
            text-align: center;
            color: $main-purple-dark-1;
            border-radius: 4px;
            border: 1px solid $main-purple-dark-1;
          }
        }
        .addr1 {
          background-color: $grey-0;
        }
      }
      .chk-area {
        padding-top: 9px;
        padding-bottom: 3px;
        display: flex;
        align-items: center;
        label {
          cursor: pointer;
          width: 100%;
          display: flex;
          align-items: center;
          gap: 7px;
          .chk-img {
            width: 24px;
            height: 24px;
            aspect-ratio: 1/1;
            background: url(../../../assets/img/check_small_default.svg)
              no-repeat center / contain;
          }
          input {
            display: none;
            &:checked {
              & + .chk-img {
                background: url(../../../assets/img/check_small_selected.svg)
                  no-repeat center / contain;
              }
            }
          }
        }
      }
      .add-btn {
        position: absolute;
        bottom: 16px;
        height: 48px;
        line-height: 100%;
        border-radius: 4px;
        color: $white;
        font-size: 16px;
        letter-spacing: -0.5px;
        font-family: "Pretendard-Medium";
        border: none;
        background-color: $grey-3;
        width: calc(100% - 32px);
        &.active {
          cursor: pointer;
          background-color: $main-purple-dark-1;
        }
      }
    }
  }
  // 주소검색 팝업
  .postcode {
    width: 100%;
    height: calc(var(--vh, 1vh) * 100);
    max-width: 750px;
    min-width: 375px;
    position: fixed;
    z-index: 300;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    background-color: rgba(0, 0, 0, 0.5);
    @supports (-webkit-touch-callout: none) {
      .postcode-popup {
        height: -webkit-fill-available;
      }
    }
    .postcode-popup {
      @keyframes slideUp {
        from {
          transform: translateY(100%);
        }
        to {
          transform: translateY(0);
        }
      }
      animation: slideUp 0.2s;
      padding: 26px 16px 0px;
      border-radius: 10px 10px 0 0;
      position: absolute;
      bottom: 0;
      width: 100%;
      height: fit-content;
      max-height: calc(100vh - 60px);
      background-color: $white;
      // left: 0;
      .popup-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 13px;
        .title {
          font-family: "Pretendard-SemiBold";
          font-size: 20px;
          line-height: 100%;
          letter-spacing: -0.5px;
        }
        .close-btn {
          all: unset;
          cursor: pointer;
          width: 24px;
          height: 24px;
          background: url(../../../assets/img/icClose24Black.svg) no-repeat
            center / contain;
        }
      }
    }
  }
  // 확인 팝업
  .Popup {
    width: 100%;
    height: calc(var(--vh, 1vh) * 100);
    position: fixed;
    left: 50%;
    top: 0;
    background-color: rgba(0, 0, 0, 0.6);
    z-index: 100;
    transform: translateX(-50%);
    display: flex;
    justify-content: center;
    align-items: center;
    max-width: 750px;
    min-width: 375px;
    z-index: 300;
    .contents {
      background-color: $white;
      border-radius: 8px;
      overflow: hidden;
      width: 328px;
      .text-area {
        padding: 40px 24px 36px;
        text-align: center;
        .title {
          font-family: "Pretendard-Bold";
          font-size: 20px;
          letter-spacing: -0.5px;
          margin-bottom: 20px;
          line-height: 100%;
        }
        .desc {
          font-family: "Pretendard-Regular";
          font-size: 16px;
          letter-spacing: -0.5px;
          line-height: 25.6px;
        }
      }
      .btn {
        width: 100%;
        border: none;
        padding: 20px;
        line-height: 100%;
        color: $white;
        font-family: "Pretendard-Medium";
        background: $main-purple-dark-1;
        cursor: pointer;
      }
    }
  }

  input {
    caret-color: $main-purple-dark-1;
  }
}
// 결제 완료
.Payment-Result {
  position: fixed;
  left: 50%;
  top: 0;
  transform: translateX(-50%);
  z-index: 500;
  width: 100%;
  max-width: 750px;
  min-width: 375px;
  height: calc(var(--vh, 1vh) * 100);
  background-color: $white;
  .contents {
    width: 100%;
    height: calc(var(--vh, 1vh) * 100 - 56px);
    position: relative;
    padding: 0 16px;
    .method {
      border: 1px solid $main-purple-light-4;
      width: fit-content;
      padding: 8px 12px;
      line-height: 100%;
      border-radius: 15px;
      font-size: 12px;
      font-family: "Pretendard-Medium";
      letter-spacing: -0.5px;
      color: $main-purple-dark-1;
      margin: 28px auto 20px;
    }
    .main-text {
      font-family: "Pretendard-Medium";
      letter-spacing: -0.5px;
      color: $new-black;
      font-size: 19px;
      line-height: 30px;
      text-align: center;
      margin-bottom: 24px;
    }
    .lna-addr {
      background-color: $grey-0;
      padding: 18px;
      text-align: center;
      margin-bottom: 14px;
      .purple {
        font-family: "Pretendard-Medium";
        font-size: 19px;
        line-height: 100%;
        letter-spacing: -0.5px;
        margin-bottom: 11px;
        color: $main-purple-dark-1;
      }
      .small {
        font-family: "Pretendard-Regular";
        font-size: 15px;
        letter-spacing: -0.5px;
        color: $grey-8;
      }
    }
    .info {
      border: 1px solid $grey-1;
      border-radius: 6px;
      padding: 22px 0;
      margin-bottom: 14px;
      .detail {
        padding: 0 16px 18px;
        border-bottom: 1px $grey-1 solid;
        .info-title {
          font-family: "Pretendard-SemiBold";
          font-size: 16px;
          letter-spacing: -0.5px;
          line-height: 100%;
          color: $grey-9;
          margin-bottom: 18px;
        }
        .line-area {
          display: flex;
          flex-direction: column;
          gap: 11px;
          .line {
            display: flex;
            justify-content: space-between;
            font-family: "Pretendard-Regular";
            color: $grey-8;
            letter-spacing: -0.5px;
            font-size: 14px;
            .purple {
              color: $main-purple-dark-1;
            }
          }
        }
      }
      .result {
        padding: 16px 16px 0;
        .line {
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin-bottom: 4px;
          .info-title {
            font-family: "Pretendard-SemiBold";
            font-size: 16px;
            letter-spacing: -0.5px;
            line-height: 100%;
            color: $grey-9;
          }
          .price {
            font-family: "Pretendard-SemiBold";
            font-size: 21px;
            line-height: 100%;
            letter-spacing: -0.5px;
            color: $grey-8;
          }
        }
        .sub {
          display: flex;
          justify-content: flex-end;
          align-items: center;
          gap: 4px;
          .icon {
            padding: 5px 8px;
            background-color: $main-purple-dark-1;
            font-family: "Pretendard-Medium";
            font-size: 10px;
            color: $white;
            border-radius: 16px;
            letter-spacing: -0.5px;
          }
          .text {
            font-family: "Pretendard-Medium";
            font-size: 12px;
            letter-spacing: -0.5px;
            color: $grey-7;
          }
        }
      }
    }
    .sub-text {
      font-family: "Pretendard-Regular";
      font-size: 14px;
      color: $grey-7;
      text-align: center;
      line-height: 100%;
      letter-spacing: -0.5px;
    }
    .btn-area {
      padding: 0 16px 60px;
      width: 100%;
      position: absolute;
      left: 0;
      bottom: 0px;
      .result-popup-btn {
        height: 48px;
        width: 100%;
        background-color: $main-purple-dark-1;
        color: $white;
        font-family: "Pretendard-Medium";
        border-radius: 6px;
        border: none;
        outline: none;
        font-size: 16px;
        cursor: pointer;
      }
    }
  }
}
