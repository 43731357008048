.map-content {
  background-color: white;
  min-width: 343px;
  width: 100%;
  height: 80px;
  position: absolute;
  z-index: 10;
  bottom: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-bottom: 32px;
  padding: 16px;
  border-radius: 8px;
  font-family: "Pretendard-Normal";

  .store-name {
    font-size: 16px;
    line-height: 24px;
    font-family: "Pretendard-Bold";
  }
  .location-wrapper {
    display: flex;
    align-items: center;
    margin-top: 6px;

    .text {
      font-size: 13px;
      line-height: 20px;
      color: $grey-9;
    }
    
    .copy-btn {
      font-size: 13px;
      line-height: 24px;
      letter-spacing: -0.5px;
      font-family: "Pretendard-SemiBold";
      background-color: transparent;
      margin-left: auto;
      color: $main-purple-dark-1;
      border: none;
    }
  }
}