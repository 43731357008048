.with-draw {
  width: 100%;
  height: calc(100vh - 56px);
  color: $grey-9;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  
  .withdraw-content {
    padding: 0 16px;
    height: 70vh;

    .title {
      font-size: 20px;
      line-height: 30px;
      font-family: "Pretendard-Bold";
      margin-top: 24px;
    }
    .body {
      font-size: 14px;
      line-height: 20px;
      letter-spacing: -0.35px;
      font-family: "Pretendard-Regular";
      margin-top: 18px;

      button {
        border: none;
        background: none;
      }
  
      &:first-child {
        margin-top: 24px;
      }
  
      &:last-child {
        color: $grey-7;
        margin-top: 40px;
        display: flex;
      }
    }
  }
  
  .withdraw-enter {
    min-width: 344px;
    width: -webkit-fill-available;
    height: 58px;
    color: $white;
    background-color: $grey-3;
    margin: 0 16px 40px 16px;
    border-radius: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
    // position: fixed;
    // bottom: 0;

    button {
      font-size: 16px;
      line-height: 25px;
      letter-spacing: -0.16px;
      font-family: "Pretendard-SemiBold";
      color: $white;
      border: none;
      background-color: transparent;
    }

    &.active {
      background-color: $main-purple-dark-1;
      pointer-events: initial;
    }
  }
  .withdraw-modal{
    position: fixed;
    background-color: #fff;
    width:100%;
    max-width: 750px;
    min-width: 375px;
    z-index: 100;
    top:0;
    left:50%;
    transform:translateX(-50%);
    display:flex;
    flex-direction: column;
    height:100%;
    align-content: space-around;
    .withdraw-reason-title{
      margin:24px 16px 0;
      .main-title{
        font-family: "Pretendard-Bold";
        font-size:20px;
        line-height: 20px;
        color:#333;
      }
      .sub-title{
        font-family: "Pretendard-Regular";
        font-size:14px;
        line-height: 30px;
        letter-spacing: -0.35px;
        color:$grey-7;
      }
    }
    .reason-list{
      margin:24px 16px 0;
      display:flex;
      flex-direction: column;
      li{
        margin-bottom:8px;
        input{
          display:none;
        }
        input:checked + label .chk-bg{
          background-image:url("../../../assets/img/icon_check_purple_24.svg")
        }
      
        
        label{
          display:flex;
          align-items: center;
          align-content: center;
          border:1px solid $grey-1;
          padding:16px;
          padding-left:4px;
          cursor:pointer;
          span{
            display:inline-block;
          }
          .chk-bg{
      
            width:24px;
            height:24px;
            background-size:100% 100%;
            background-image:url("../../../assets/img/icons/icon_check_grey_24.svg")
          }
          .reason-text{
            height:24px;
            line-height: 24px;
          }
        }
      }
    }
    button{
      position: absolute;
      bottom:0;
      right:0;
      width:100%;
      line-height: 48px;
   
      border:0;
      background-color: $main-purple-dark-1;
      color:#fff;
      font-size:16px;
      font-family: "Pretendard-Medium";
      letter-spacing: -0.4px;
      cursor:pointer;
      a{
        display:inline-block;
        width:100%;
        height:100%;
        color:#fff;
        padding-bottom:40px;
      }
    }
  }
}