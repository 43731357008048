.timeline {
  width: 100%;
  min-height: calc(100vh - 56px - 72px);
  .timeline-wrap {
    width: 100%;
    min-height: calc(100vh - 56px - 49.5px);
    background: $grey-0;
    .timeline-empty {
      width: 100%;
      min-height: inherit;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      .main-text {
        font-family: "Pretendard-Medium";
        font-size: 16px;
        letter-spacing: -0.4px;
        color: $grey-6;
        margin-bottom: 12px;
      }
      .sub-text {
        font-family: "Pretendard-Medium";
        font-size: 12px;
        letter-spacing: -0.3px;
        color: $grey-6;
        line-height: 20px;
      }
      .request-btn {
        margin-top: 24px;
        border: none;
        border-radius: 2px;
        width: 150px;
        height: 40px;
        background: $main-purple-dark-1;
        color: $white;
        font-size: 14px;
        font-family: "Pretendard-Medium";
        letter-spacing: -0.35px;
        cursor: pointer;
      }
      .past-text {
        font-family: "Pretendard-Medium";
        font-size: 16px;
        letter-spacing: -0.4px;
        color: $grey-6;
      }
    }
  }
}

// timelineBox
.timelinebox-wrap {
  padding: 18px 16px 18px;
}
.timeline-box {
  &.timeline-box-home {
    box-shadow: none;
    border: 1px solid $grey-1;
  }
  height: 121px;
  width: 100%;
  background: $white;
  border-radius: 10px;
  border: 0.5px solid $grey-1;
  box-shadow: 0 3px 8px 0 rgba(109, 109, 110, 0.1);
  display: flex;
  justify-content: space-between;
  padding: 18px;
  margin-bottom: 12px;

  .info {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    .name {
      font-family: "Pretendard-SemiBold";
      font-size: 18px;
      letter-spacing: -0.45px;
      display: flex;
      align-items: center;
      .middot {
        color: $grey-5;
      }
      .arrow {
        width: 24px;
        height: 24px;
        background: url(../../assets/img/icArrow24Medium.svg) no-repeat center /
          contain;
      }
    }
    .register-time {
      font-family: "Pretendard-Regular";
      font-size: 11px;
      color: $grey-5;
      letter-spacing: -0.28px;
      margin-top: -12px;
    }
    .row {
      display: flex;
      column-gap: 5px;
      .state {
        font-family: "Pretendard-Regular";
        font-size: 11px;
        letter-spacing: -0.28px;
        padding: 6px 9px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 13px;
        color: $grey-5;
        border: 1px solid $grey-1;
        &.current-state {
          color: $main-purple-dark-1;
          border: 1px solid $main-purple-dark-1;
        }
      }
      .review-btn {
        padding: 6px 9px;
        border-radius: 13px;
        border: none;
        outline: none;
        font-family: "Pretendard-Medium";
        font-size: 11px;
        letter-spacing: -0.44px;
        cursor: pointer;
        &.write-review-btn {
          background: $main-purple-dark-1;
          color: $white;
        }
        &.view-review-btn {
          background: $grey-1;
          color: $grey-6;
        }
      }
    }
  }
  .img {
    width: 85px;
    height: 85px;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    border-radius: 6px;
  }
}
// timeline-detail
.timeline-detail {
  min-height: calc(100vh - 56px);
  background: $grey-0;
  padding: 7px;
  // 주문번호
  .detail-info {
    width: 100%;
    background: $white;
    border-radius: 6px;
    border: 0.5px solid #eaeaea;
    box-shadow: 0 2px 4px rgba(109, 109, 110, 0.1);
    padding: 10px 13px;
    display: flex;
    column-gap: 12px;
    margin-bottom: 19px;
    .img {
      width: 38px;
      height: 38px;
      border-radius: 50%;
      background-size: cover;
      background-position: center;
      background-repeat: no-repeat;
      background-position: center;
    }
    .desc {
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      .title {
        font-family: "Pretendard-Regular";
        font-size: 11px;
        letter-spacing: -0.28px;
        color: $grey-4;
      }
      .num {
        font-family: "Pretendard-Medium";
        font-size: 13px;
        letter-spacing: -0.32px;
        color: $grey-8;
      }
    }
  }
  .timeline-step-area {
    padding-left: 15px;
    padding-right: 9px;
    .timeline-step-item {
      &:first-child {
        .line {
          &::before {
            background: url(../../assets/img/progress24Ing.svg) no-repeat center /
              contain;
          }
          border-left: 1px dotted $main-purple-light-4;
        }
        .content {
          .detail-step {
            .title {
              color: $main-purple-0;
            }
          }
        }
      }
      &:last-child {
        &.active {
          .line {
            border-left: 1px solid $grey-1;
            // border-left: 1px dotted $main-color-400;
          }
        }
        .line {
          border-left: 1px solid transparent;
        }
      }
      &.active {
        .content {
          .detail-step {
            .arrow-btn {
              width: 24px;
              height: 24px;
              background: url(../../assets/img/dropdown24Open.svg) no-repeat
                center / contain;
            }
          }
          .detail-box {
            &.detail-box-1 {
              margin-bottom: 10px;
            }
            display: block;
            &.detail-box-color {
              background: $main-purple-light-1;
              border: 0.5px solid rgb(232, 231, 239);
              box-shadow: 0 2px 6px 0 rgba(109, 109, 110, 0.1);
            }
          }
        }
      }
      display: flex;
      .line {
        flex: 0 0 auto;
        &::before {
          content: "";
          width: 24px;
          height: 24px;
          position: absolute;
          left: 0;
          top: 0;
          background: url(../../assets/img/progress24Off.svg) no-repeat center /
            contain;
          transform: translate(-50%, -50%);
        }
        margin-top: 12px;
        border-left: 1px solid $grey-1;
        width: 15px;
        position: relative;
      }
      .content {
        width: calc(100% - 16px);
        .detail-step {
          width: 100%;
          display: flex;
          justify-content: space-between;
          align-items: flex-start;
          text-align: left;
          margin-bottom: 12px;
          background: none;
          border: none;
          outline: none;
          .title {
            font-family: "Pretendard-SemiBold";
            font-size: 18px;
            letter-spacing: -0.45px;
            color: $grey-8;
            margin-bottom: 5px;
          }
          .date {
            font-family: "Pretendard-Regular";
            font-size: 11px;
            letter-spacing: -0.28px;
            color: $grey-5;
          }
          .arrow-btn {
            width: 24px;
            height: 24px;
            background: url(../../assets/img/dropdown24Close.svg) no-repeat
              center / contain;
          }
        }
        .detail-box {
          width: 100%;
          display: none;
          margin-bottom: 32px;
          padding: 20px 16px;
          border: 0.5px solid $grey-1;
          border-radius: 10px;
          background: $white;
          box-shadow: 0 2px 6px 0 rgba(109, 109, 110, 0.1);
          .step-1 {
            .title {
              font-family: "Pretendard-Bold";
              font-size: 16px;
              letter-spacing: -0.4px;
              color: $grey-8;
              margin-bottom: 12px;
            }
            .img-area {
              .main-slider {
                position: relative;
                .slider-container {
                  .slider-item {
                    border-radius: 4px;
                    overflow: hidden;
                    .slider-item-img {
                      width: 100%;
                      aspect-ratio: 1.6/1;
                      background-repeat: no-repeat;
                      background-size: cover;
                      background-position: center;
                    }
                  }
                }
                .img-index {
                  position: absolute;
                  right: 8px;
                  bottom: 10px;
                  color: $white;
                  font-family: "Pretendard-Medium";
                  font-size: 10px;
                  letter-spacing: 1.5px;
                  padding: 4px 7px;
                  border-radius: 9.5px;
                  background-color: rgba(48, 50, 52, 0.7);
                }
              }
              .sub-imgs-area {
                .sub-imgs {
                  width: 100%;
                  display: flex;
                  column-gap: 5px;
                  margin-top: 10px;
                  margin-bottom: 16px;
                  .sub-img-item {
                    position: relative;
                    width: calc(100% / 6);
                    aspect-ratio: 1 / 1;
                    border-radius: 2px;
                    background-size: cover;
                    background-repeat: no-repeat;
                    background-position: center;
                    overflow: hidden;
                    &::before {
                      content: "";
                      width: 100%;
                      height: 100%;
                      position: absolute;
                      left: 0;
                      top: 0;
                      background: rgba(33, 33, 33, 0.6);
                    }
                    &.active {
                      border: 1.5px solid $main-purple-0;
                      &::before {
                        background: transparent;
                      }
                    }
                    .img-note {
                      &.active {
                        background: url(../../assets/img/iconMessageWhite16.svg)
                          no-repeat center / contain;
                      }
                      width: 16px;
                      height: 16px;
                      position: absolute;
                      bottom: 0;
                      right: 0;
                    }
                  }
                }
                .sub-img-note-area {
                  &.active {
                    .note {
                      display: block;
                    }
                  }
                  font-size: 13px;
                  font-family: "Pretendard-Regular";
                  letter-spacing: -0.32px;
                  margin-bottom: 16px;
                  line-height: 20px;
                  .note {
                    color: $grey-8;
                    text-overflow: ellipsis;
                    overflow: hidden;
                    display: -webkit-box;
                    -webkit-line-clamp: 2;
                    -webkit-box-orient: vertical;
                  }
                  .view-more-btn {
                    all: unset;
                    color: $grey-4;
                    text-decoration: underline;
                    cursor: pointer;
                  }
                }
              }
            }
            .text-area {
              border-top: 1px solid $grey-1;
              padding-top: 16px;
              .sub-title {
                font-family: "Pretendard-SemiBold";
                font-size: 14px;
                letter-spacing: -0.35px;
                color: $grey-9;
                margin-bottom: 10px;
              }
              .text {
                background: $grey-0;
                padding: 14px;
                border-radius: 6px;
                font-size: 13px;
                font-family: "Pretendard-Regular";
                letter-spacing: -0.32px;
                color: $grey-8;
              }
            }
          }
          .step-1-sub-1 {
            .title {
              font-family: "Pretendard-SemiBold";
              font-size: 14px;
              letter-spacing: -0.35px;
              color: $grey-9;
              margin-bottom: 10px;
            }
            .note {
              font-family: "Pretendard-Regular";
              font-size: 13px;
              letter-spacing: -0.32px;
              color: $grey-8;
            }
          }
          .step-2 {
            // 새로운 견적 도착
            padding: 16px 20px;
            display: flex;
            flex-direction: column;
            align-items: center;
            .main-color-600 {
              color: $main-purple-0;
            }
            .estimate-count {
              font-family: "Pretendard-Medium";
              font-size: 18px;
              letter-spacing: -0.72px;
              color: $grey-8;
              margin-bottom: 13px;
            }
            .data-time {
              display: flex;
              column-gap: 4px;
              align-items: center;
              font-size: 11px;
              color: $grey-7;
              font-family: "Pretendard-Regular";
              margin-bottom: 31px;
              .reload-btn {
                display: inline-block;
                width: 12.5px;
                height: 12.5px;
                background: url(../../assets/img/24998.svg) no-repeat center /
                  contain;
              }
            }
            .min-price {
              // width: 152px;
              width: fit-content;
              display: flex;
              align-items: center;
              column-gap: 20px;
              margin-bottom: 30px;
              .img {
                width: 50px;
                height: 50px;
                background: url(../../assets/img/24214.svg) no-repeat center /
                  contain;
                flex: 0 0 auto;
              }
              .desc {
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                .title {
                  font-family: "Pretendard-Medium";
                  font-size: 13px;
                  color: $grey-8;
                  letter-spacing: -0.32px;
                  margin-bottom: 5px;
                }
                .info {
                  color: $main-purple-0;
                  font-family: "Pretendard-SemiBold";
                  font-size: 20px;
                  letter-spacing: -0.5px;
                  white-space: nowrap;
                }
              }
            }
            .min-workday {
              // width: 152px;
              width: fit-content;
              display: flex;
              align-items: center;
              column-gap: 20px;
              .img {
                flex: 0 0 auto;
                width: 50px;
                height: 50px;
                background: url(../../assets/img/24215.svg) no-repeat center /
                  contain;
              }
              .desc {
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                .title {
                  font-family: "Pretendard-Medium";
                  font-size: 13px;
                  color: $grey-8;
                  letter-spacing: -0.32px;
                  margin-bottom: 5px;
                }
                .info {
                  color: $main-purple-0;
                  font-family: "Pretendard-SemiBold";
                  font-size: 20px;
                  letter-spacing: -0.5px;
                  white-space: nowrap;
                }
              }
            }
            .step-2-btn {
              margin-top: 37px;
              width: 100%;
              background: $main-purple-dark-1;
              color: $white;
              border: none;
              outline: none;
              padding: 16px;
              border-radius: 6px;
              font-family: "Pretendard-SemiBold";
              font-size: 14px;
              letter-spacing: -0.35px;
              display: flex;
              place-content: center;
            }
          }
          .step-3 {
            // 견적 선택 완료
            .selected {
              display: flex;
              padding-bottom: 13px;
              border-bottom: 1px solid $grey-1;
              margin-bottom: 17px;
              .left {
                .img {
                  background: url(../../assets/img/24214.svg) no-repeat center /
                    contain;
                }
              }
              .right {
                .img {
                  background: url(../../assets/img/24215.svg) no-repeat center /
                    contain;
                }
              }
              .left,
              .right {
                display: flex;
                align-items: center;
                column-gap: 4px;
                width: 50%;
                .img {
                  width: 38px;
                  height: 38px;
                }
                .info {
                  display: flex;
                  flex-direction: column;
                  row-gap: 2px;
                  .title {
                    color: $grey-8;
                    font-size: 11px;
                    font-family: "Pretendard-Regular";
                    letter-spacing: -0.28px;
                  }
                  .price {
                    font-family: "Pretendard-SemiBold";
                    font-size: 14px;
                    letter-spacing: -0.35px;
                    color: $main-purple-0;
                  }
                }
              }
            }
            .meister-note {
              .title {
                color: &black;
                font-size: 14px;
                font-family: "Pretendard-SemiBold";
                letter-spacing: -0.35px;
                margin-bottom: 10px;
              }
              .content {
                background: $grey-0;
                border-radius: 6px;
                padding: 14px;
                font-size: 13px;
                font-family: "Pretendard-Regular";
                letter-spacing: -0.32px;
                color: $grey-8;
              }
            }
          }
          .step-4,
          .step-5 {
            // 결제 대기 (가상계좌)
            .payment {
              all: unset;
            }
            .bank,
            .payment,
            .mileage {
              &.gap {
                padding-bottom: 17.5px;
              }
              .title {
                &.payment-title {
                  margin-top: 17.5px;
                }
                font-family: "Pretendard-SemiBold";
                font-size: 14px;
                letter-spacing: -0.35px;
                color: $grey-9;
                margin-bottom: 12px;
              }
              .mileage-info {
                background: $main-purple-light-1;
                padding: 11px 10px;
                border-radius: 4px;
                font-family: "Pretendard-Medium";
                font-size: 13px;
                letter-spacing: -0.32px;
                color: $main-purple-dark-1;
                .row {
                  display: flex;
                  justify-content: space-between;
                }
              }
              .info {
                font-family: "Pretendard-Regular";
                font-size: 14px;
                letter-spacing: -0.42px;
                color: $grey-8;
                display: flex;
                flex-direction: column;
                row-gap: 8px;
                .main-color-600 {
                  color: $main-purple-0;
                }
                .semibold {
                  font-family: "Pretendard-SemiBold";
                }
                .row {
                  &.amount {
                    margin-top: 13px;
                    .amount-pay {
                      font-size: 15px;
                      letter-spacing: -0.38px;
                      color: $grey-9;
                      font-family: "Pretendard-Medium";
                      display: flex;
                      align-items: center;
                      column-gap: 1px;
                    }
                    .amount-pay span {
                      font-size: 21px;
                      font-family: "Pretendard-SemiBold";
                      letter-spacing: -0.52px;
                      color: $grey-8;
                    }
                  }
                  &.message {
                    margin-top: -3px;
                    font-size: 12px;
                    letter-spacing: -0.3px;
                    color: $grey-7;
                    font-family: "Pretendard-Regular";
                    .col:nth-child(2) {
                      display: flex;
                      align-items: center;
                      column-gap: 4px;
                      .message-icon {
                        display: inline-block;
                        font-family: "Pretendard-Medium";
                        font-size: 10px;
                        letter-spacing: -0.25px;
                        color: $white;
                        padding: 3px 7px;
                        background: $main-purple-dark-1;
                        border-radius: 9px;
                      }
                    }
                  }
                  display: flex;
                  justify-content: space-between;
                  align-items: center;
                  &.total {
                    margin-top: 7px;
                  }
                }
                .caution {
                  margin-top: 16px;
                  margin-bottom: 13.5px;
                  background: $grey-0;
                  border-radius: 6px;
                  padding: 14px;
                  font-size: 13px;
                  line-height: 20px;
                  color: $grey-8;
                  font-family: "Pretendard-Regular";
                }
              }
            }
            .bank {
              border-bottom: 1px solid $grey-1;
            }
            .mileage {
              border-top: 1px solid $grey-1;
              margin-top: 17.5px;
              padding-top: 17.5px;
            }
          }
          .stet-3-sub-1,
          .stet-5-sub-1 {
            // 견적기한 만료(3-1) (EXES)
            // 입금기한 만료(5-1) (EXBK)
            .info {
              font-family: "Pretendard-Regular";
              font-size: 13px;
              letter-spacing: -0.32px;
              color: $grey-8;
              line-height: 21px;
            }
          }
          .step-6 {
            // 수선품 수거 예정(수거용 박스) SRWT -> SDCM
            .step-6-1 {
              .title {
                font-family: "Pretendard-SemiBold";
                font-size: 14px;
                letter-spacing: -0.35px;
                color: $grey-9;
                margin-bottom: 12px;
                line-height: normal;
              }
              .guide {
                font-family: "Pretendard-Regular";
                font-size: 14px;
                letter-spacing: -0.35px;
                line-height: 23px;
                color: $grey-8;
                padding-bottom: 17.5px;
                border-bottom: 1px solid $grey-1;
                margin-bottom: 17.5px;
              }
              .shipping {
                padding-bottom: 17.5px;
                .info {
                  font-family: "Pretendard-Regular";
                  font-size: 14px;
                  letter-spacing: -0.35px;
                  line-height: 24px;
                  .row {
                    display: flex;
                    column-gap: 15px;
                    .col1 {
                      width: 60px;
                    }
                  }
                }
              }
              .method {
                border-top: 1px solid $grey-1;
                padding-top: 17.5px;
                .info {
                  display: flex;
                  flex-direction: column;
                  row-gap: 34px;
                  .row {
                    display: flex;
                    column-gap: 19px;
                    .img {
                      &.img1 {
                        background: url(../../assets/img/25265.svg) no-repeat
                          center / contain;
                      }
                      &.img2 {
                        background: url(../../assets/img/25266.svg) no-repeat
                          center / contain;
                      }
                      width: 70px;
                      height: 70px;
                    }
                    .col {
                      font-size: 13px;
                      font-family: "Pretendard-Regular";
                      line-height: 21px;
                      letter-spacing: -0.39px;
                      .index {
                        font-family: "Pretendard-SemiBold";
                        font-size: 16px;
                        letter-spacing: -0.48px;
                        color: $main-purple-0;
                        margin-bottom: 8px;
                      }
                    }
                  }
                }
              }
              .check {
                .number {
                  display: flex;
                  background: $grey-0;
                  padding: 11px 14px;
                  border-radius: 6px;
                  column-gap: 12px;
                  font-size: 13px;
                  font-family: "Pretendard-Regular";
                  letter-spacing: -0.32px;
                  color: $grey-8;
                  .blue {
                    color: $blue-500;
                    text-decoration: underline;
                  }
                }
              }
            }
            .step-6-2 {
              .title {
                font-family: "Pretendard-SemiBold";
                font-size: 16px;
                letter-spacing: -0.4px;
                color: $grey-9;
                text-align: center;
                margin-bottom: 18px;
              }
              .address {
                background: $main-purple-light-1;
                border-radius: 4px;
                color: $main-purple-dark-1;
                font-family: "Pretendard-Medium";
                font-size: 14px;
                padding: 16px;
                display: flex;
                flex-direction: column;
                row-gap: 8px;
                text-align: center;
              }
              .shipping-info-form {
                .selectbox {
                  position: relative;
                  width: 100%;
                  margin-bottom: 8px;
                  &.active .optionList {
                    max-height: 298px;
                    overflow-y: scroll;
                    opacity: 1;
                  }
                  .selectbox-btn {
                    &.selected {
                      background: $white;
                      color: $grey-8;
                      .arrow {
                        background: url(../../assets/img/icdropdown24Active.svg)
                          no-repeat center / contain;
                      }
                    }
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    width: 100%;
                    height: 43px;
                    border-radius: 4px;
                    border: 1px solid $grey-1;
                    padding-left: 15px;
                    padding-right: 10px;
                    color: $grey-5;
                    background: $grey-0;
                    font-family: "Pretendard-Regular";
                    font-size: 14px;
                    letter-spacing: -0.42px;
                    cursor: pointer;
                    display: flex;
                    .arrow {
                      width: 24px;
                      height: 24px;
                      background: url(../../assets/img/icdropdown24Inactive.svg)
                        no-repeat center / contain;
                      pointer-events: none;
                    }
                  }
                  .optionList {
                    box-shadow: 0 3px 8px 0 rgba(85, 85, 85, 0.08);
                    padding: 3px 0px;
                    // position: relative;
                    position: absolute;
                    top: 41px;
                    left: 0;
                    z-index: 1;
                    width: 100%;
                    background: $white;
                    color: $grey-5;
                    border-radius: 4px;
                    overflow: hidden;
                    max-height: 0;
                    transition: 0.2s linear;
                    display: flex;
                    flex-direction: column;
                    opacity: 0;
                    .optionItem {
                      padding: 8px 20px;
                      transition: 0.1s;
                      display: block;
                      font-family: "Pretendard-Regular";
                      font-size: 14px;
                      letter-spacing: -0.35px;
                      &:not(.optionItem:last-child) {
                        border-bottom: 0.5px solid $grey-1;
                      }
                      .item-label {
                        cursor: pointer;
                        width: 100%;
                        display: flex;
                        align-items: center;
                        &::after {
                          content: "";
                          display: inline-block;
                          width: 24px;
                          height: 24px;
                          background: none;
                        }
                      }
                      .item-input {
                        display: none;
                      }
                      &.active {
                        .item-label {
                          color: $main-purple-0;
                          &::after {
                            content: "";
                            display: inline-block;
                            width: 24px;
                            height: 24px;
                            background: url(../../assets/img/icon_check_purple_24.svg)
                              no-repeat center / contain;
                          }
                        }
                      }
                    }
                  }
                }
                .row {
                  display: flex;
                  column-gap: 5px;
                  .col {
                    width: 100%;
                    position: relative;
                    .input {
                      /* Chrome, Safari, Edge, Opera */
                      &::-webkit-outer-spin-button,
                      &::-webkit-inner-spin-button {
                        -webkit-appearance: none;
                        margin: 0;
                      }
                      /* Firefox */
                      &[type="number"] {
                        -moz-appearance: textfield;
                      }
                      &::placeholder {
                        color: $grey-5;
                      }
                      &:focus {
                        border: 1px solid $main-purple-light-4;
                        &::placeholder {
                          color: transparent;
                        }
                      }
                      &.inactive {
                        &:focus {
                          background: $white;
                        }
                        background: $grey-0;
                      }
                      width: 100%;
                      height: 43px;
                      border: 1px solid $grey-1;
                      border-radius: 4px;
                      font-family: "Pretendard-Regular";
                      font-size: 14px;
                      letter-spacing: -0.42px;
                      padding: 0 16px;
                      outline: none;
                      color: $grey-8;
                    }
                    .delete-btn {
                      width: 24px;
                      height: 24px;
                      background: url(../../assets/img/icInputDelete24Gray.svg)
                        no-repeat center / contain;
                      position: absolute;
                      right: 8px;
                      top: 10px;
                    }
                  }
                  .save-btn {
                    display: block;
                    width: calc(100% / 3);
                    height: 43px;
                    border: 1px solid $grey-1;
                    border-radius: 4px;
                    background: $white;
                    font-family: "Pretendard-Regular";
                    font-size: 14px;
                    color: $grey-7;
                    letter-spacing: -0.42px;
                    cursor: pointer;
                    &.inactive {
                      display: none;
                    }
                  }
                }
              }
            }
            .step-6-3 {
              .title {
                font-family: "Pretendard-SemiBold";
                font-size: 14px;
                letter-spacing: -0.35px;
                color: $grey-9;
                margin-bottom: 12px;
              }
              .guide {
                font-family: "Pretendard-Regular";
                font-size: 14px;
                letter-spacing: -0.35px;
                color: $grey-8;
                padding-bottom: 17.5px;
                border-bottom: 1px solid $grey-1;
                margin-bottom: 17.5px;
              }
              .dday {
                display: flex;
                align-items: center;
                padding-bottom: 17.5px;
                border-bottom: 1px solid $grey-1;
                margin-bottom: 17.5px;
                .graph {
                  width: 42px;
                  height: 42px;
                  margin-right: 16px;
                  color: $main-purple-0;
                  letter-spacing: -0.32px;
                  font-family: "Pretendard-SemiBold";
                  font-size: 13px;
                  position: relative;
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  .svg {
                    position: absolute;
                    left: 0;
                    top: 0;
                    width: 42px;
                    height: 42px;
                    overflow: visible;
                    circle {
                      fill: none;
                      stroke: $grey-1;
                      stroke-width: 4px;
                      &.percent {
                        stroke: $main-purple-light-4;
                        stroke-dasharray: 132;
                        transform-origin: center;
                        transform: rotateZ(-90deg);
                        stroke-linecap: round;
                        animation: graph-ani 0.3s linear;
                      }
                    }
                  }
                }
                .info {
                  font-family: "Pretendard-SemiBold";
                  .title {
                    font-size: 14px;
                    letter-spacing: -0.35px;
                    color: $grey-9;
                    margin-bottom: 6px;
                  }
                  .deadline {
                    font-size: 16px;
                    letter-spacing: -0.4px;
                    color: $main-purple-0;
                  }
                }
              }
              .customer-info {
                .info {
                  font-family: "Pretendard-Regular";
                  font-size: 14px;
                  letter-spacing: -0.35px;
                  line-height: 24px;
                  .row {
                    display: flex;
                    column-gap: 15px;
                    .col1 {
                      width: 60px;
                    }
                  }
                }
              }
            }
          }
          .step-7,
          .step-8 {
            // 수선 진행 중 (RSRS)
            .dday {
              display: flex;
              align-items: center;
              padding-bottom: 17.5px;
              border-bottom: 1px solid $grey-1;
              margin-bottom: 17.5px;
              .graph {
                width: 42px;
                height: 42px;
                margin-right: 16px;
                color: $main-purple-0;
                letter-spacing: -0.32px;
                font-family: "Pretendard-SemiBold";
                font-size: 13px;
                position: relative;
                display: flex;
                align-items: center;
                justify-content: center;
                .svg {
                  position: absolute;
                  left: 0;
                  top: 0;
                  width: 42px;
                  height: 42px;
                  overflow: visible;
                  circle {
                    fill: none;
                    stroke: $grey-1;
                    stroke-width: 4px;
                    &.percent {
                      stroke: $main-purple-light-4;
                      stroke-dasharray: 132;
                      transform-origin: center;
                      transform: rotateZ(-90deg);
                      stroke-linecap: round;
                      animation: graph-ani 0.3s linear;
                    }
                  }
                }
              }
              .info {
                font-family: "Pretendard-SemiBold";
                .title {
                  font-size: 14px;
                  letter-spacing: -0.35px;
                  color: $grey-9;
                  margin-bottom: 6px;
                }
                .deadline {
                  font-size: 16px;
                  letter-spacing: -0.4px;
                  color: $main-purple-0;
                }
              }
            }
            .note {
              font-family: "Pretendard-SemiBold";
              font-size: 14px;
              letter-spacing: -0.35px;
              color: $grey-9;
              .main-slider {
                margin-top: 10px;
                margin-bottom: 16px;
                position: relative;
                .tab-info {
                  width: 100%;
                  height: calc(100% - 2px);
                  position: absolute;
                  left: 0;
                  top: 0;
                  z-index: 1;
                  border-radius: 4px;
                  display: flex;
                  flex-direction: column;
                  justify-content: center;
                  align-items: center;
                  row-gap: 16.3px;
                  text-align: center;
                  background: rgba(0, 0, 0, 0.7);
                  .img {
                    background: url(../../assets/img/swipe.svg) no-repeat center /
                      contain;
                    width: 41px;
                    height: 44.8px;
                  }
                  .text {
                    color: $white;
                    line-height: 20px;
                    font-size: 13px;
                    font-family: "Pretendard-SemiBold";
                    letter-spacing: -0.32px;
                  }
                }
                .slider-container {
                  border-radius: 4px;
                  overflow: hidden;
                  .slider-item {
                    border-radius: 4px;
                    overflow: hidden;
                    .slider-item-img {
                      width: 100%;
                      aspect-ratio: 1.6/1;
                      background-repeat: no-repeat;
                      background-size: cover;
                      background-position: center;
                    }
                  }
                }
                .img-index {
                  position: absolute;
                  right: 8px;
                  bottom: 10px;
                  color: $white;
                  font-family: "Pretendard-Medium";
                  font-size: 10px;
                  letter-spacing: 1.5px;
                  padding: 4px 7px;
                  border-radius: 9.5px;
                  background-color: rgba(48, 50, 52, 0.7);
                }
              }
              .meister-note {
                font-family: "Pretendard-Regular";
                font-size: 13px;
                letter-spacing: -0.32px;
                padding: 14px;
                background: $grey-0;
                border-radius: 6px;
                margin-top: 10px;
                color: $grey-8;
                line-height: 20px;
              }
            }
            .notice {
              font-family: "Pretendard-SemiBold";
              font-size: 14px;
              letter-spacing: -0.35px;
              color: $grey-9;
              padding-top: 17.5px;
              border-top: 1px solid $grey-1;
              margin-top: 17.5px;
              p {
                &:first-child {
                  margin-top: 10px;
                }
                font-family: "Pretendard-Regular";
                font-size: 11px;
                letter-spacing: -0.28px;
                color: $grey-8;
                line-height: 18px;
                padding-left: 8px;
                text-indent: -8px;
              }
              button {
                &.request-delivery-btn {
                  background: $main-purple-dark-1;
                  color: $white;
                  font-family: "Pretendard-SemiBold";
                  font-size: 15px;
                  letter-spacing: -0.38px;
                  margin-top: 20px;
                }
                &.retry-btn {
                  background: transparent;
                  font-family: "Pretendard-Medium";
                  font-size: 13px;
                  letter-spacing: -0.39px;
                  color: $grey-5;
                }
                width: 100%;
                border: none;
                outline: none;
                border-radius: 4px;
                padding: 14px;
                cursor: pointer;
              }
            }
          }
          .step-9 {
            .info {
              font-family: "Pretendard-Regular";
              font-size: 13px;
              letter-spacing: -0.32px;
              line-height: 21px;
              color: $main-purple-dark-1;
            }
            .title {
              font-family: "Pretendard-SemiBold";
              font-size: 14px;
              color: $grey-9;
              letter-spacing: -0.35px;
              margin-bottom: 10px;
            }
            .content {
              font-family: "Pretendard-Regular";
              font-size: 13px;
              letter-spacing: -0.32px;
              color: $grey-8;
              line-height: 20px;
            }
          }
          .step-10 {
            // 수선품 수거 예정(수거용 박스) SRWT -> SDCM
            .step-10-1 {
              .title {
                font-family: "Pretendard-SemiBold";
                font-size: 14px;
                letter-spacing: -0.35px;
                color: $grey-9;
                margin-bottom: 12px;
                line-height: normal;
              }
              .guide {
                font-family: "Pretendard-Regular";
                font-size: 14px;
                letter-spacing: -0.35px;
                line-height: 23px;
                color: $grey-8;
                padding-bottom: 17.5px;
                border-bottom: 1px solid $grey-1;
                margin-bottom: 17.5px;
              }
              .shipping {
                .info {
                  font-family: "Pretendard-Regular";
                  font-size: 14px;
                  letter-spacing: -0.35px;
                  line-height: 24px;
                  color: $grey-8;
                  .row {
                    display: flex;
                    column-gap: 15px;
                    .col1 {
                      width: 60px;
                    }
                  }
                }
              }
              .check {
                .number {
                  display: flex;
                  background: $grey-0;
                  padding: 11px 14px;
                  border-radius: 6px;
                  column-gap: 12px;
                  font-size: 13px;
                  font-family: "Pretendard-Regular";
                  letter-spacing: -0.32px;
                  color: $grey-8;
                  .blue {
                    color: $blue-500;
                    text-decoration: underline;
                  }
                }
              }
            }
            .step-10-2 {
              .title {
                font-family: "Pretendard-SemiBold";
                font-size: 14px;
                letter-spacing: -0.35px;
                color: $grey-9;
                margin-bottom: 12px;
              }
              .guide {
                font-family: "Pretendard-Regular";
                font-size: 14px;
                letter-spacing: -0.35px;
                color: $grey-8;
                padding-bottom: 17.5px;
                border-bottom: 1px solid $grey-1;
                margin-bottom: 17.5px;
              }
              .dday {
                display: flex;
                align-items: center;
                padding-bottom: 17.5px;
                border-bottom: 1px solid $grey-1;
                margin-bottom: 17.5px;
                .graph {
                  width: 42px;
                  height: 42px;
                  margin-right: 16px;
                  color: $main-purple-0;
                  letter-spacing: -0.32px;
                  font-family: "Pretendard-SemiBold";
                  font-size: 13px;
                  position: relative;
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  .svg {
                    position: absolute;
                    left: 0;
                    top: 0;
                    width: 42px;
                    height: 42px;
                    overflow: visible;
                    circle {
                      fill: none;
                      stroke: $grey-1;
                      stroke-width: 4px;
                      &.percent {
                        stroke: $main-purple-light-4;
                        stroke-dasharray: 132;
                        transform-origin: center;
                        transform: rotateZ(-90deg);
                        stroke-linecap: round;
                        animation: graph-ani 0.3s linear;
                      }
                    }
                  }
                }
                .info {
                  font-family: "Pretendard-SemiBold";
                  .title {
                    font-size: 14px;
                    letter-spacing: -0.35px;
                    color: $grey-9;
                    margin-bottom: 6px;
                  }
                  .deadline {
                    font-size: 16px;
                    letter-spacing: -0.4px;
                    color: $main-purple-0;
                  }
                }
              }
              .customer-info {
                padding-bottom: 17.5px;
                .info {
                  font-family: "Pretendard-Regular";
                  font-size: 14px;
                  letter-spacing: -0.35px;
                  line-height: 24px;
                  .row {
                    display: flex;
                    column-gap: 15px;
                    .col1 {
                      width: 60px;
                    }
                  }
                }
              }
            }
          }
          .step-11 {
            display: flex;
            flex-direction: column;
            align-items: center;
            row-gap: 16px;
            .img {
              width: 74.6px;
              height: 56.4px;
              background: url(../../assets/img/25256.svg) no-repeat center/
                contain;
            }
            .text {
              font-family: "Pretendard-Regular";
              font-size: 13px;
              letter-spacing: -0.32px;
              color: $grey-8;
              text-align: center;
              line-height: 21px;
              margin-bottom: 2px;
              span {
                font-family: "Pretendard-Medium";
                color: $main-purple-0;
              }
            }
            .write-review-btn {
              &.active {
                background: $main-purple-dark-1;
                font-family: "Pretendard-SemiBold";
                color: $white;
                cursor: pointer;
              }
              background: $grey-1;
              font-family: "Pretendard-Medium";
              color: $grey-6;
              width: 100%;
              font-size: 15px;
              padding: 14px;
              letter-spacing: -0.38px;
              border: none;
              outline: none;
              border-radius: 4px;
            }
          }
        }
      }
    }
  }
}

@keyframes graph-ani {
  from {
    stroke-dashoffset: 132;
  }
}

.timeline-step8-slideup-modalpopup-contents {
  background: $grey-0;
  height: calc(var(--vh, 1vh) * 100 - 56px - 50px - 92px);
  overflow-y: scroll;
  margin-top: 18px;
  border-top: 1px solid $grey-1;
  display: flex;
  flex-direction: column;
  row-gap: 8px;
  .title {
    padding: 0 16px;
    font-family: "Pretendard-SemiBold";
    font-size: 16px;
    letter-spacing: -0.4px;
    color: $grey-9;
    margin-bottom: 16px;
  }
  .shipping-area {
    padding: 18px 0;
    background: $white;
    border-bottom: 1px solid $grey-1;
    .contents {
      padding-left: 16px;
    }
  }
  .method-area {
    background: $white;
    padding: 18px 0;
    flex: 1 0 auto;
    .contents {
      padding: 0 16px;
      .btn-area {
        display: flex;
        column-gap: 5px;
        margin-bottom: 12px;
        .method-btn {
          &.active {
            border: none;
            background: $main-purple-dark-1;
            color: $white;
            font-family: "Pretendard-Medium";
          }
          width: 100%;
          padding: 14px;
          background: $white;
          border: 1px solid $grey-1;
          border-radius: 4px;
          font-family: "Pretendard-Regular";
          font-size: 15px;
          letter-spacing: -0.45px;
          color: $grey-8;
        }
      }
      .info-area {
        margin-bottom: 31px;
        .info-1 {
          position: relative;
          margin-bottom: 100px;
          .selectbox {
            position: relative;
            width: 100%;
            // margin-bottom: 8px;
            &.active .optionList {
              max-height: 500px;
              overflow-y: scroll;
              opacity: 1;
            }
            .selectbox-btn {
              &.selected {
                background: $white;
                color: $grey-8;
                .arrow {
                  background: url(../../assets/img/icdropdown24Active.svg)
                    no-repeat center / contain;
                }
              }
              display: flex;
              justify-content: space-between;
              align-items: center;
              width: 100%;
              height: 43px;
              border-radius: 4px;
              border: 1px solid $grey-1;
              padding-left: 15px;
              padding-right: 10px;
              color: $grey-5;
              background: $grey-0;
              font-family: "Pretendard-Regular";
              font-size: 14px;
              letter-spacing: -0.42px;
              cursor: pointer;
              display: flex;
              .arrow {
                width: 24px;
                height: 24px;
                background: url(../../assets/img/icdropdown24Inactive.svg)
                  no-repeat center / contain;
                pointer-events: none;
              }
            }
            .optionList {
              box-shadow: 0 3px 8px 0 rgba(85, 85, 85, 0.08);
              padding: 3px 0px;
              position: relative;
              top: 0;
              left: 0;
              z-index: 1;
              width: 100%;
              background: $white;
              color: $grey-5;
              border-radius: 4px;
              overflow: hidden;
              max-height: 0;
              transition: 0.2s linear;
              display: flex;
              flex-direction: column;
              opacity: 0;
              .optionItem {
                cursor: pointer;
                padding: 8px 20px;
                transition: 0.1s;
                display: block;
                font-family: "Pretendard-Regular";
                font-size: 14px;
                letter-spacing: -0.35px;
                &:not(.optionItem:last-child) {
                  border-bottom: 0.5px solid $grey-1;
                }
                .item-label {
                  width: 100%;
                  display: flex;
                  align-items: center;
                  &::after {
                    content: "";
                    display: inline-block;
                    width: 24px;
                    height: 24px;
                    background: none;
                  }
                }
                .item-input {
                  display: none;
                }
                &.active {
                  .item-label {
                    color: $main-purple-0;
                    &::after {
                      content: "";
                      display: inline-block;
                      width: 24px;
                      height: 24px;
                      background: url(../../assets/img/icon_check_purple_24.svg)
                        no-repeat center / contain;
                    }
                  }
                }
              }
            }
          }
          .input-self-text {
            position: absolute;
            left: 0;
            top: 51px;
            width: 100%;
            height: 43px;
            border-radius: 4px;
            border: 1px solid $grey-1;
            padding: 0 15px;
            &:focus {
              outline: 1px solid $main-purple-0;

              // border:
            }
          }
        }
        .info-2 {
          .text-box-1 {
            font-family: "Pretendard-Regular";
            font-size: 14px;
            letter-spacing: -0.42px;
            line-height: 24px;
            color: $grey-8;
            border: 1px solid $grey-1;
            border-radius: 4px;
            padding: 16px;
            margin-bottom: 16px;
            .purple {
              font-family: "Pretendard-Medium";
              color: $main-purple-0;
            }
          }
          .text-box-2 {
            li {
              font-family: "Pretendard-Regular";
              font-size: 12px;
              letter-spacing: -0.3px;
              line-height: 20px;
              color: $grey-8;
              margin-left: 16px;
              list-style-type: disc;
              &.grey {
                color: $grey-6;
                list-style-type: none;
              }
            }
          }
        }

        //
      }
    }
  }
}

.timeline-step8-slideup-modalpopup-contents-2 {
  margin-top: 16px;
  padding: 0 16px 16px;
  height: calc(var(--vh, 1vh) * 100 - 56px - 50px - 92px);
  overflow-y: scroll;
  .textarea-area {
    margin-top: 15px;
    margin-bottom: 27px;
    .textarea {
      &::placeholder {
        color: $grey-5;
      }
      resize: none;
      width: 100%;
      height: 148px;
      outline: none;
      padding: 16px;
      font-family: "Pretendard-Regular";
      font-size: 15px;
      letter-spacing: -0.38px;
      border: 1px solid $grey-1;
      border-radius: 6px;
      color: $grey-8;
      caret-color: $main-purple-dark-1;
    }
  }
  .info-area {
    .title {
      font-family: "Pretendard-SemiBold";
      font-size: 16px;
      letter-spacing: -0.4px;
      color: $grey-9;
      margin-bottom: 14px;
    }
    .contents {
      display: flex;
      flex-direction: column;
      row-gap: 12px;
      p {
        font-family: "Pretendard-Regular";
        font-size: 13px;
        letter-spacing: -0.39px;
        color: $grey-7;
        line-height: 21px;
      }
    }
    .text-box-1 {
      margin-bottom: 30px;
    }
    .text-box-2 {
      margin-bottom: 60px;
    }
  }
}
