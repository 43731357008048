.request-info {
  width: 100%;
  height: 100%;
  // padding: 24px 16px;

  .request-explain {
    font-family: "Pretendard-Regular";
    font-size: 14px;
    line-height: 24px;
    letter-spacing: -0.5px;
    color: $grey-9;
    padding: 0 2px;

    p {
      display: flex;
      align-items: center;
      margin: 4px 0;
    }
    .ques {
      font-family: "Pretendard-Medium";
      line-height: 20px;
    }
    .title {
      font-family: "Pretendard-Bold";
      font-size: 24px;
      line-height: 36px;
      color: $main-purple-dark-1;
      margin: 4px 0 16px;
    }
  }

  .explain-img {
    display: flex;
    justify-content: center;
    margin: 40px 0;

    img {
      min-width: 240px;
      width: 12vw;
    }
  }

  .explain-bottom {
    display: flex;
    flex-direction: column;
    align-items: center;
    color: $grey-9;
    margin-bottom: 24px;

    p {
      font-family: "Pretendard-Regular";
      font-size: 10px;
      line-height: 16px;
      letter-spacing: -0.5px;
    }
    .top {
      font-family: "Pretendard-Medium";
      font-size: 16px;
      line-height: 24px;
      color: $main-purple-dark-1;
    }
  }

  // .request-entered {
  //   min-width: 343px;
  //   width: 100%;
  //   min-height: 48px;
  //   border: none;
  //   border-radius: 4px;
  //   font-size: 16px;
  //   line-height: 24px;
  //   font-family: "Pretendard-Medium";
  //   background-color: $main-purple-dark-1;
  //   color: $white;
  //   margin-bottom: 56px;
  // }
  .entered-wrapper {
    // border-top: 1px solid $grey-1;
    background-color: $white;
    position: fixed;
    bottom: 0;
    min-width: 343px;
    width: 100%;
    max-width: 750px;
    min-height: 96px;
    z-index: 10;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 16px;

    .request-entered {
      width: 100%;
      min-height: 48px;
      border: none;
      border-radius: 4px;
      font-size: 16px;
      line-height: 24px;
      font-family: "Pretendard-Medium";
      background-color: $main-purple-dark-1;
      color: $white;
      cursor: pointer;
    }
  }
}
