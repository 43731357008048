.Contents {
  max-width: 750px;
  min-width: 350px;
  width: 100%;
  .Contents-tab-menu {
    position: sticky;
    left: 0;
    top: 56px;
    z-index: 50;
    width: 100%;
    padding: 0 16px;
    border-bottom: 1px solid $grey-300;
    display: flex;
    justify-content: space-between;
    background: $white;
    span {
      cursor: pointer;
      width: calc(100%);
      padding: 12px 0;
      display: flex;
      justify-content: center;
      align-items: center;
      color: $grey-600;
      font-size: 16px;
      line-height: 16px;
      letter-spacing: -0.5px;
      border-bottom: 2px solid transparent;
      font-family: "Pretendard-Regular";
      &.active {
        font-family: "Pretendard-Medium";
        color: $purple-700;
        border-bottom: 2px solid $purple-700;
        transition: border 0.3s;
      }
    }
  }
  .Contents-tab {
    padding-bottom: 48px;
    .item-list {
      padding: 0 24px;
      .tab3-btn {
        all: unset;
        cursor: pointer;
        box-sizing: border-box;
        width: 100%;
        padding: 10px;
        font-size: 14px;
        line-height: 24px;
        color: $grey-800;
        font-family: "Pretendard-Regular";
        border: 1px solid $grey-1;
        border-radius: 4px;
        margin-top: 16px;
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 2px;
        .btn-img {
          width: 16px;
          height: 16px;
          background: url(../../../assets/img/icon/24px/chevron_down_thin.svg)
            no-repeat center / contain;
        }
      }
    }
  }
  .banner {
    width: 100%;
    aspect-ratio: 4/1;
  }
}

// components
.ContentsItem {
  cursor: pointer;
  padding: 22px 0;
  border-bottom: 1px solid $grey-1;
  &:last-child {
    border-bottom: 1px solid transparent;
  }
  .line {
    display: flex;
    justify-content: space-between;
    gap: 20px;
    .left {
      display: flex;
      flex-direction: column;
      gap: 4px;
      .title {
        font-family: "Pretendard-Bold";
        font-size: 16px;
        line-height: 24px;
      }
      .text {
        font-family: "Pretendard-Regular";
        letter-spacing: -2%;
        font-size: 13px;
        line-height: 20px;
        overflow: hidden;
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
      }
    }
    .right {
      .img {
        width: 92px;
        aspect-ratio: 1/1;
        border-radius: 12px;
        position: relative;
        .arrow-btn {
          width: 24px;
          height: 24px;
          background: $grey-9;
          border-radius: 50%;
          position: absolute;
          right: -4px;
          bottom: -4px;
          display: flex;
          justify-content: center;
          align-items: center;
          .arrow-icon {
            width: 16px;
            height: 16px;
            background: url("../../../assets/img/icons/icon_arrow_right_16.svg")
              no-repeat center / contain;
          }
        }
      }
    }
  }
}
.ContentsNews {
  cursor: pointer;
  padding: 20px 0;
  border-bottom: 1px solid $grey-1;

  .title {
    margin-bottom: 8px;
    font-family: "Pretendard-Bold";
    font-size: 14px;
    line-height: 24px;
    letter-spacing: -0.5px;
    word-break: keep-all;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
  }
  .desc {
    display: flex;
    gap: 4px;
    font-family: "Pretendard-Regular";
    font-size: 12px;
    color: $grey-700;
  }
}

// ContentsDetail
.ContentsDetail {
  .btn-wrap {
    width: 100%;
    padding: 8px 16px;
    .request-btn {
      all: unset;
      cursor: pointer;
      width: 100%;
      height: 48px;
      background-color: $purple-700;
      border-radius: 4px;
      color: $white;
      display: flex;
      align-items: center;
      justify-content: center;
      font-family: "Pretendard-Medium";
      font-size: 16px;
      line-height: 100%;
      letter-spacing: -0.5px;
    }
  }
}
