.Alert2ButtonModalPopup {
  &.active {
    display: flex;
  }
  display: none;
  width: 100vw;
  height: 100vh;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 30;
  background: rgba(33, 33, 33, 0.7);
  justify-content: center;
  align-items: center;
  .popup {
    padding: 40px 22px 24px;
    width: 329px;
    height: 216px;
    border-radius: 12px;
    background: $white;
    display: flex;
    align-items: center;
    flex-direction: column;
    .title {
      font-family: "Pretendard-SemiBold";
      font-size: 20px;
      letter-spacing: -0.5px;
      color: $grey-8;
      text-align: center;
      margin-bottom: 18px;
    }
    .desc {
      font-family: "Pretendard-Regular";
      font-size: 15px;
      letter-spacing: -0.38px;
      color: $grey-8;
      max-width: 256px;
      text-align: center;
      line-height: 24px;
      margin-bottom: 20px;
    }
    .button-area {
      width: 100%;
      display: flex;
      column-gap: 5px;
      .btn {
        width: 100%;
        height: 44px;
        font-size: 15px;
        color: $grey-8;
        letter-spacing: -0.38px;
        border-radius: 4px;
        &.left-btn {
          font-family: "Pretendard-Regular";
          border: 1px solid $grey-1;
          background: $white;
        }
        &.right-btn {
          font-family: "Pretendard-SemiBold";
          border: none;
          background: $main-purple-dark-1;
          color: $white;
        }
      }
    }
  }
}
