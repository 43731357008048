.contact-info {
  width: 100%;

  .service {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 28px 0 24px;
    // min-height: 154px;
    font-family: "Pretendard-Regular";

    .customer-service {
      font-size: 16px;
      line-height: 25px;
      letter-spacing: -0.4px;
      font-family: "Pretendard-SemiBold";
    }

    .customer-info {
      font-size: 13px;
      // line-height: 20px;
      letter-spacing: -0.32px;
      color: $grey-6;
      margin: 12px;
      display: flex;

      p {
        padding: 0 8px;
      }
    }

    .co-info {
      display: flex;
      padding: 4px 0;

      p {
        min-width: 156px;
        min-height: 40px;
        border: 1px solid $grey-1;
        border-radius: 2px;
        font-size: 13px;
        line-height: 20px;
        letter-spacing: -0.32px;
        font-family: "Pretendard-Medium";
        display: flex;
        align-items: center;
        justify-content: center;

        &:last-child {
          margin-left: 8px;
        }
      }
    }
  }
}