.modal-full-black {
  background-color: rgba(0, 0, 0, 0.5);
  width: 100%;
  height: 100%;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 70;
  overflow: hidden;

  .modal-bold {
    width: 328px;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    background-color: $white;
    border-radius: 12px;
    padding-top: 36px;
    color: $grey-8;
    overflow: hidden;

    p {
      line-height: 24px;
    }

    .modal-title {
      font-family: "Pretendard-SemiBold";
      font-size: 20px;
      line-height: 32px;
      letter-spacing: -0.5px;
    }

    .modal-desc {
      font-family: "Pretendard-Regular";
      font-size: 15px;
      letter-spacing: -0.38px;
      margin: 16px 0 32px;
    }

    .modal-button-area {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      text-align: center;
      height: 56px;
      font-family: "Pretendard-Medium";

      .left-btn {
        color: $grey-6;
        background-color: $grey-1;
        display: inline-block;
        flex-basis: 140px;
        border-radius: 4px;
        border-top-left-radius: 0;
        border-top-right-radius: 0;
        border-bottom-left-radius: 10px;
        border-bottom-right-radius: 0;
        font-size: 16px;
        letter-spacing: -0.5px;
        font-family: "Pretendard-Medium";
        border: 0;
        flex-grow: 1;
        cursor: pointer;
      }

      .right-btn {
        border-color: $main-purple-dark-1;
        background-color: $main-purple-dark-1;
        color: $white;
        display: inline-block;
        flex-basis: 140px;
        border-radius: 4px;
        border-top-left-radius: 0;
        border-top-right-radius: 0;
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 10px;
        font-size: 16px;
        letter-spacing: -0.5px;
        font-family: "Pretendard-Medium";
        border: 0;
        flex-grow: 1;
        cursor: pointer;
      }
    }
  }
}
