.page-button {
  &.active {
    background-color: $main-purple-dark-1;
    pointer-events: initial;
    cursor: pointer;
  }
  min-width: 375px;
  max-width: 750px;
  width: 100%;
  position: fixed;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  background-color: $grey-3;
  color: $white;
  font-family: "Pretendard-SemiBold";
  font-size: 16px;
  line-height: 21px;
  letter-spacing: -0.4px;
  pointer-events: none;
  width: 100%;
  padding: 20px 0 51px;
  border: 0;
  z-index: 10;
}
