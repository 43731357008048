.compare-estimate {
  background-color: $grey-0;
  overflow: hidden;
  padding-bottom: 89px;
  min-height: 100vh;
  font-family: "Pretendard-Regular";
  // .compare-estimate-notice {
  //   display: flex;
  //   justify-content: space-between;
  //   // align-items: nter;
  //   font-family: "Pretendard-Regular";
  //   background-color: $white;
  //   border-radius: 8px;
  //   box-shadow: 0px 0px 8px rgba(109, 109, 110, 0.1);
  //   margin: 12px 16px 0;
  //   padding: 15px 11px 14px 15px;
  //   p {
  //     color: $grey-6;
  //     font-size: 13px;
  //     line-height: 21px;
  //     letter-spacing: -0.32px;
  //     span {
  //       font-family: "Pretendard-SemiBold";
  //     }
  //   }
  // }
  .compare-estimate-all {
    padding: 16px;

    .total-simple-case {
      display: flex;
      justify-content: space-between;
      .total-estimate {
        margin-bottom: 12px;
        font-family: "Pretendard-Medium";
        font-size: 14px;
        letter-spacing: -0.5px;
        line-height: 100%;
        .bold {
          font-family: "Pretendard-Bold";
        }
      }
      //
    }
    .line {
      display: flex;
      align-items: center;
      margin-bottom: 16px;
      gap: 8px;
      .view {
        label {
          border: 1px solid $grey-1;
          padding: 6px 12px 6px 8px;
          background-color: $white;
          border-radius: 99px;
          cursor: pointer;
          display: flex;
          align-items: center;
          gap: 2px;
          input {
            display: none;
          }
          .checkbox-bg {
            width: 24px;
            height: 24px;
            background: url("../../assets/img/icon_change_grey_24.svg")
              no-repeat center / contain;
          }
          .simple-case-text {
            font-family: "Pretendard-Regular";
            font-size: 14px;
            line-height: 100%;
            letter-spacing: -0.5px;
            color: $grey-9;
          }
        }
      }
      .selectbox {
        position: relative;
        .select-btn {
          display: flex;
          align-items: center;
          padding: 6px 4px 6px 16px;
          background: $white;
          border-radius: 99px;
          border: 1px solid $grey-1;
          cursor: pointer;
          color: $grey-9;
          font-size: 14px;
          .arrow-down-icon {
            width: 24px;
            height: 24px;
            background: url("../../assets/img/drop_down_24.svg") no-repeat
              center / contain;
          }
        }
        .list {
          display: none;
          padding: 4px 16px;
          border-radius: 8px;
          box-shadow: 0 2px 8px 0 rgba(83, 83, 84, 0.15);
          position: absolute;
          top: 34px;
          left: 0;
          background-color: $white;
          width: 120px;
          z-index: 1;
          li {
            &:not(:last-child) {
              border-bottom: 1px solid $grey-1;
            }
            input {
              display: none;
            }
            input:checked + label {
              color: $main-purple-dark-1;
            }
            label {
              display: block;
              padding: 15px 0;
              cursor: pointer;
              font-size: 14px;
              font-family: "Pretendard-Regular";
              letter-spacing: -0.5px;

              color: $grey-6;
            }
          }
        }
        &.active {
          .list {
            display: block;
          }
        }
      }
    }
    .simple-case {
      .simple-case-list {
        display: flex;
        flex-direction: column;
        gap: 8px;
        li {
          &.active {
            border: 1px solid $main-purple-light-4;
            p {
              .chk-icon {
                background: url(../../assets/img/check_small_selected.svg)
                  no-repeat center / contain;
              }
            }
          }
          display: grid;
          grid-template-columns: 1.5fr 1fr 1fr;
          background-color: $white;
          padding: 12px;
          border-radius: 6px;
          border: 1px solid $grey-1;

          p {
            letter-spacing: -0.5px;
            font-size: 14px;
            font-family: "Pretendard-Medium";
            line-height: 14px;
            display: flex;
            align-items: center;
            gap: 9px;
            .chk-icon {
              width: 24px;
              height: 24px;

              background: url(../../assets/img/check_small_default.svg)
                no-repeat center / contain;
            }
          }
        }
      }
    }
    .estimate-list {
      > ul {
        width: 100%;
        > li {
          .chk-icon {
            width: 32px;
            height: 32px;
            background: url("../../assets/img/check_circle_32_default.svg")
              no-repeat center / contain;
          }
          &.active {
            border: 1px solid $main-purple-light-4;
            .chk-icon {
              width: 32px;
              height: 32px;
              background: url("../../assets/img/check_circle_32_checked.svg")
                no-repeat center / contain;
            }
          }
          margin-bottom: 16px;
        }
      }
      li {
        position: relative;
        flex-grow: 1;
        background-color: $white;
        border-radius: 8px;
        padding-bottom: 16px;
        border: 1px solid $white;
        padding: 20px;
        box-shadow: 0px 2px 10px 0px rgba(83, 83, 84, 0.08);
        .estimate-list-head {
          border-bottom: 1px solid $grey-1;
          padding-bottom: 16px;
          .estimate-list-head-title {
            font-family: "Pretendard-Bold";
            font-size: 20px;
            letter-spacing: -0.5px;
            line-height: 100%;
            margin-bottom: 9px;
            color: $grey-9;
            display: flex;
            justify-content: space-between;
            align-items: center;
            // .meister-name {
            //   cursor: pointer;
            // }
          }
          .estimate-list-head-grade {
            display: flex;
            align-items: center;
            gap: 2px;
            p {
              font-size: 14px;
              color: $grey-6;
              line-height: 100%;
              letter-spacing: -0.5px;
              // span{
              //   font-size:14px;
              //   display:inline-block;

              // }
              // .purple-text{
              //   font-family:"Pretendard-Medium";
              //   color:$main-purple-dark-1;
              // }
            }
            .grade-point {
              color: $grey-9;
            }
            .star-icon {
              margin-top: 0;
              width: 16px;
              img {
                display: block;
                width: 100%;
              }
            }
          }
        }
        // .fixation {
        //   position: absolute;
        //   right: 20px;
        //   top: 18px;
        //   input {
        //     display: none;
        //   }
        //   input:checked + label {
        //     .fixation-bg {
        //       background-image: url("../../assets/img/icons/_icon_pin_white_24.svg");
        //       background-color: $main-purple-0;
        //     }
        //     .bg-text {
        //       color: $main-purple-0;
        //     }
        //   }
        //   label {
        //     cursor: pointer;
        //     .fixation-bg {
        //       display: block;
        //       width: 32px;
        //       height: 32px;
        //       margin: 0 auto;
        //       background-image: url("../../assets/img/icons/_icon_pin_grey_24.svg");
        //       background-size: 100% 100%;
        //       border-radius: 16px;
        //       background-color: $grey-0;
        //     }
        //     .bg-text {
        //       margin-top: 6px;
        //       display: block;
        //       text-align: center;
        //       width: 36px;
        //       font-size: 10px;
        //       letter-spacing: -0.5px;
        //       font-family: "Pretendard-Regular";
        //     }
        //   }
        // }
        .estimate-list-price-period {
          margin-top: 16px;
          .estimate-list-price-period-title {
            margin-bottom: 12px;
            display: grid;
            grid-template-columns: 0.6fr 0.4fr;
            font-size: 12px;
            line-height: 12px;
            letter-spacing: -0.5px;
            color: $grey-6;
          }
          .estimate-list-price-period-text {
            display: flex;
            align-items: center;
            font-size: 22px;
            line-height: 26px;
            letter-spacing: -0.5px;
            font-family: "Pretendard-SemiBold";
            color: $grey-9;
            display: grid;
            grid-template-columns: 0.6fr 0.4fr;
            div {
              display: flex;
              align-items: center;
              gap: 4px;
              p {
                display: inline-block;
              }
              .purple-text {
                display: inline-block;
                margin-left: 4px;
                padding: 6px 8px;
                font-family: "Pretendard-Regular";
                letter-spacing: -0.5px;
                font-size: 12px;
                line-height: 100%;
                color: $main-purple-dark-1;
                border: 1px solid $main-purple-dark-1;
                border-radius: 12px;
              }
            }
          }
        }
        .estimate-list-desc-title {
          margin-top: 20px;
          display: grid;
          grid-template-columns: 0.6fr 0.4fr;
          font-size: 12px;
          line-height: 12px;
          letter-spacing: -0.5px;
          color: $grey-6;
        }
        .estimate-list-desc {
          background-color: $grey-0;
          margin-top: 10px;
          padding: 12px 16px;
          border-radius: 6px;
          font-family: "Pretendard-Regular";
          font-size: 14px;
          line-height: 24px;
          letter-spacing: -0.5px;
          color: $grey-9;
        }
        .estimate-list-img-title {
          margin-top: 20px;
          display: grid;
          grid-template-columns: 0.6fr 0.4fr;
          font-size: 12px;
          line-height: 12px;
          letter-spacing: -0.5px;
          color: $grey-6;
        }
        .estimate-carousel {
          // overflow-x:scroll;
          margin-top: 10px;
          margin-bottom: 47px;
          .estimate-images {
            display: flex;
            flex-wrap: nowrap;
            .slick-slider {
              width: 100%;
              img {
                // border-radius: 6px;
              }
            }
            .estimate-images-slider {
              &:after {
                content: "";
                display: block;
                clear: both;
              }
              width: 320px;
              flex-shrink: 0;
              margin-right: 10px;
              > div {
                float: left;
                position: relative;
                // width: calc(100vw - 32px);
                background-color: white;
                p {
                  &.img {
                    // width: fit-content;
                    position: relative;
                    margin: auto;
                    img {
                      width: 100%;
                      // width: fit-content;
                      display: block;
                      // max-width: calc(100vw - 32px);
                      // max-height: calc((100vw - 32px) / 4 * 3);
                      aspect-ratio: 4 / 3;
                      object-fit: cover;
                    }
                  }
                  text-align: center;
                  line-height: 17px;
                  font-size: 10px;
                  color: $white;
                  bottom: 8px;
                }
              }
            }
          }
        }
        .slick-dots {
          // text-align: center;
          li {
            margin-left: 3px !important;
            width: 4px !important;
            height: 0px !important;
            border-radius: 2px !important;
            border: 0px !important;
            padding: 0px !important;
            button {
              &:before {
                content: "";
                width: 6px;
                height: 6px;
                border-radius: 3px;
                background-color: $grey-9;
              }
              margin-right: 2px;
              // background-color: $grey-9;

              width: 6px;
              height: 6px;
              border-radius: 3px;
              padding: 0;
            }
          }
        }
        .button-area-profile {
          /*min-width: 375px;*/
          max-width: 750px;
          margin-top: 15px;
          width: 100%;
          button {
            &.active {
              background-color: $main-purple-dark-1;
              cursor: pointer;
            }
            cursor: default;
            width: 100%;
            background-color: $grey-4;
            color: $white;
            font-family: "Pretendard-SemiBold";
            font-size: 15px;
            height: 45px;
            line-height: 100%;
            letter-spacing: -0.5px;
            border: none;
            border-radius: 4px;
          }
        }
      }
    }
    .button-area {
      position: fixed;
      /*bottom: 32px;*/
      bottom: 0px;
      min-width: 375px;
      max-width: 750px;
      padding: 0 16px;
      left: 50%;
      transform: translateX(-50%);
      width: 100%;
      height: 77px;
      background-color: #f7f8f9;
      button {
        &.active {
          background-color: $main-purple-dark-1;
          cursor: pointer;
        }
        cursor: default;
        width: 100%;
        background-color: $grey-4;
        color: $white;
        font-family: "Pretendard-SemiBold";
        font-size: 16px;
        height: 48px;
        line-height: 100%;
        letter-spacing: -0.5px;
        border: none;
        border-radius: 4px;
      }
    }
  }
}
.estimate-slider {
  z-index: 50;
  position: fixed;
  left: 50%;
  top: 50%;
  max-width: 750px;
  min-width: 375px;
  width: 100%;
  transform: translate(-50%, -50%);
  background-color: rgba(0, 0, 0, 1);
  .review-slider {
    // position: fixed;
    // left:50%;
    // top:50%;
    // transform: translate(-50%,-50%);
    height: 100vh;
    .review-slider-title {
      position: absolute;
      width: 100%;
      text-align: center;
      .go-back-arrow {
        position: absolute;
        left: 10px;
        top: 10px;
      }
      .slider-point {
        font-size: 18px;
        color: #fff;
        display: inline-block;
        padding-top: 13px;
      }
    }
    .slick-slider {
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
    }
    img {
      width: 100%;
    }
  }
}
