.manage-post {
  width: 100%;
  min-height: calc(var(--vh, 1vh) * 100 - 56px);
  background-color: $grey-0;
  color: $grey-9;
  padding-bottom: 74px;
  .ship-data {
    padding: 12px 16px;

    .ship-zero {
      min-height: 200px;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .ship-info {
      width: 100%;
      font-size: 15px;
      line-height: 25px;
      letter-spacing: -0.38px;
      border: 1px solid $grey-0;
      border-radius: 8px;
      box-shadow: 1px 1px 2px 0 rgba($grey-6, 0.2);
      background-color: $white;
      margin-bottom: 14px;
      padding: 16px;

      .nick-wrapper {
        display: flex;
        align-items: center;

        // .nick {
        //   font-family: "Pretendard-SemiBold";
        //   margin-bottom: 24px;
        // }
        .to-modify {
          font-size: 13px;
          margin-left: auto;
          font-family: "Pretendard-Regular";
          text-decoration: none;

          button {
            border: none;
            background-color: transparent;
            margin-left: 14px;
            color: $grey-9;

            &:first-child {
              color: $blue-500;
            }
          }
        }
      }

      .name {
        color: $grey-8;
        font-family: "Pretendard-Medium";
        margin-bottom: 10px;
      }

      .zip-code {
        color: $grey-8;
        font-size: 12px;
        line-height: 18px;
        letter-spacing: -0.3px;
        font-family: "Pretendard-Regular";
      }
    }
  }

  .add-post {
    position: fixed;
    left: 50%;
    transform: translateX(-50%);
    bottom: 0;
    min-width: 350px;
    max-width: 750px;
    width: 100%;
    padding: 0 16px 16px 16px;
    .add-post-btn {
      width: 100%;
      height: 58px;
      border-radius: 8px;
      color: $white;
      background-color: $main-purple-dark-1;
      font-size: 16px;
      line-height: 25px;
      letter-spacing: -0.16px;
      font-family: "Pretendard-SemiBold";
      border: none;
      outline: none;
      cursor: pointer;
    }
  }
}
