// body{
//   &.unscroll{
//     -ms-overflow-style: none; /* IE and Edge */
//     scrollbar-width: none; /* Firefox */
//     :-webkit-scrollbar {
//       display: none; /* Chrome, Safari, Opera*/
//   }
//   }
// }
.splash-wrapper {
  width: 100%;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  // left:50%;
  // top:50%;
  // transform: translate(-50%,-50%);
  background-color: rgba(0, 0, 0, 0.3);
  z-index: 100000000;
  .splash {
    display: flex;
    flex-direction: column;
    justify-content: center;

    width: 100%;
    // height:100%;
    // position: absolute;
    // left:50%;
    // top:50%;
    // transform: translate(-50%,-50%);
    background-color: rgba(255, 255, 255, 1);

    .splash-skip {
      z-index: 1;
      font-size: 14px;
      line-height: 24px;
      letter-spacing: -0.21px;
      color: $grey-4;
      font-family: "Pretendard-Regular";
      position: absolute;
      top: 21px;
      right: 13px;

      cursor: pointer;
    }
    // overflow:scroll;
    .dots {
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      // margin-top:25px;
      position: absolute;
      top: 25px;
      left: 50%;
      transform: translateX(-50%);
      z-index: 1;
      li {
        width: 19px;
        height: 19px;
        display: flex;
        justify-content: center;
        align-items: center;
        button {
          cursor: pointer;
          width: 8px;
          height: 8px;
          border-radius: 4px;
          font-size: 0;
          text-indent: -9999px;
          border: 0;
          background-color: $grey-2;
        }
      }
    }
    .slick-slider {
      // display:flex;
      // justify-content: center;
      // align-items: center;
      // height:100%;
      .slick-list {
        // height:100%;

        .slick-track {
          // height:100%;
          .slick-slide {
            overflow: hidden;
            > div {
              // height:100%;
              > div {
                // height:100%;
                // padding-top:50px;
              }
            }
          }
        }
      }

      .splash-title {
        p {
          width: 100%;
          text-align: center;
          color: #212121;
          line-height: 36px;
          font-size: 24px;
          font-family: "Pretendard-Bold";
        }
      }
      .splash-sub-title {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        p {
          font-size: 16px;
          font-family: "Pretendard-Regular";
          line-height: 25px;
          letter-spacing: -0.24px;
          color: $grey-6;
        }
      }
      .splash-desc {
        p {
          font-size: 20px;
          line-height: 34px;
          color: #212121;
          text-align: center;
          font-family: "Pretandard-Regular";
        }
      }
      .splash-button {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;

        //position: relative;
        // height: 48px;
        button {
          // position: absolute;
          // left:50%;
          // bottom:0;
          // transform: translate(-50%,0);
          cursor: pointer;
          width: 343px;
          height: 48px;

          line-height: 48px;
          // margin:0 16px;
          border-radius: 9px;
          background-color: #212121;
          border: 0px;
          color: #fff;
          font-size: 14px;
          font-family: "Pretendard-Regular";
        }
        .splash-visible {
          padding: 8px 0 25px;

          input {
            display: none;
          }
          label {
            display: flex;
            justify-content: center;
            align-items: center;
            font-family: "Pretendard-Regular";
            font-size: 14px;
            line-height: 24px;
            letter-spacing: -0.21px;
            color: $grey-4;
            cursor: pointer;
          }
        }
      }
      .splash-slider-content {
        margin-top: 121px;
        margin-bottom: 79px;
        display: flex;
        // height:initial;
        .slick-list {
          // height:initial;
        }
        .slick-track {
          display: flex;
          // height:initial;
        }
        .slick-slide {
          width: 205px !important;
        }
        .splash-slider-img {
          width: 180px;
          height: 180px;
          img {
            width: 100%;
            display: block;
          }
        }
      }
      .splash-content {
        height: 100vh;
        display: flex !important;
        flex-direction: column;
        justify-content: space-around;
        align-items: center;

        &.splash-bg {
          background-image: url(../../assets/img/splash_bg.png);
          background-size: 100% 100%;
          background-repeat: no-repeat;
        }
      }
      .splash-animation {
        display: flex;

        margin-top: 95px;
        margin-bottom: 70px;

        // .splash-slider-img {
        //   width: 205px !important;
        //   margin-right: 10px !important;
        //   flex-grow: 0 !important;
        //   flex-shrink: 0 !important;
        //   img {
        //     width: 100%;
        //     display: block;
        //   }
        // }
      }
    }
  }
}
