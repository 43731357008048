.modal-full-black{
  background-color:rgba(0,0,0,0.5);
  width:100%;
  height:100%;
  position: fixed;
  left:0;
  top:0;
  z-index: 30;
  overflow:hidden;
  font-size:0;
  .modal{
    width:329px;
    position: absolute;
    left:50%;
    top:50%;
    transform: translate(-50%,-50%);
    text-align: center;
    background-color:$white;
    border-radius: 12px;
    padding:36px 0 24px;
    color:$grey-8;
    p{
      line-height:24px;
    }
    .modal-title{
      font-family: "Pretendard-SemiBold";
      font-size:20px;
      letter-spacing: -0.5px;
      text-indent:0;
    }
    .modal-desc{
      font-family: "Pretendard-Regular";
      font-size:15px;
      letter-spacing: -0.38px;
      margin:24px 0 18px;
      text-indent:0;
      white-space:pre-wrap;
    }
    .modal-button-area{
      display:flex;
      flex-direction: row;
      justify-content: space-between;
      text-align: center;
      margin:0 22px;
      button{
        &:first-child{
          border-color:$grey-1;
          color:$grey-8;
        }
        &:last-child{
          border-color: $main-purple-dark-1;
          background-color: $main-purple-dark-1;
          color:$white;
        }
        display:inline-block;
        flex-basis: 140px;
        border-radius: 4px;
        line-height:44px;
        font-size:15px;
        letter-spacing: -0.38px;
        line-height:44px;
        border:0;
        background: none;
        border:1px solid $white;
        margin-right:5px;
        flex-grow:1;
        cursor:pointer;
      }
    }
  
  }
}