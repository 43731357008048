.modal-alert-non-button{
  background-color:$white;
  z-index:80;
  position: fixed;
  left:50%;
  top:50%;
  transform: translate(-50%,-50%);
  padding:24px 16px 28px 20px;
  min-width: 290px;
  .non-button-title{
    font-family: "Pretendard-SemiBold";
    font-size:18px;
    line-height:24px;
    color:$new-black;
    letter-spacing: -0.45px;
    position: relative;
    margin-bottom:28px;
    .close-icon{
      position: absolute;
      right:0;
      top:0;
    }
  }
  ul{
    li{
      &:before{
        content:"";
        display:inline-block;
        width:2px;
        height:2px;
        background-color:$new-black;
        border-radius: 1px;
        margin-bottom:3px;
        margin-right:3px;
      }
      display:flex;
      align-items: center;
      font-family: "Pretendard-Regular";
      font-size:12px;
      margin-bottom:10px;
    }

  }
}