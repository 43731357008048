.repair-guide {
  width: 100%;
  height: 100%;
  padding-bottom: 72px;

  .btn-wrapper {
    border-bottom: 1px solid $grey-1;
    height: 40px;
    padding: 0 16px;

    .type-btn {
      // border: 1px solid;
      border: none;
      height: 40px;
      background-color: transparent;
      margin-right: 28px;
      font-size: 14px;
      line-height: 16px;
      letter-spacing: -0.5px;
      font-family: "Pretendard-Medium";
      color: $grey-7;

      &.actived {
        border-bottom: 2px solid $main-purple-0;
        color: $main-purple-0;
      }
    }
  }

  .sub-btn-wrapper {
    padding: 12px 16px;
    // border-bottom: 1px solid;
    width: 100%;
    height: 60px;
    overflow: scroll;
    overflow-x: auto;
    white-space: nowrap;

    .sub-btn {
      padding: 8px 12px;
      border: 1px solid $grey-1;
      border-radius: 20px;
      margin-left: 8px;
      background-color: $white;
      color: $grey-9;

      &:first-child {
        margin-left: 0;
      }

      &.actived {
        color: $white;
        background-color: $main-purple-0;
      }
    }
  }

  .rpguide-wrapper {
    // border: 1px solid blue;
    padding: 16px 8px;
    display: inline-flex;
    flex-wrap: wrap;

    .up-btn {
      position: fixed;
      bottom: 156px;
      right: 20px;
      z-index: 20;
      background-color: $white;
      opacity: 0.7;
      border-radius: 50%;
      border: none;
      width: 42px;
      height: 42px;
    }

    .board-wrapper {
      min-width: 166px;
      width: 50%;
      // width: 33%;
      min-height: 240px;

      .content-wrapper {
        position: relative;
        padding: 4px 8px;

        .image {
          width: 100%;
          border-radius: 6px;
        }

        .arrow {
          position: absolute;
          top: 14px;
          right: 20px;
        }

        .text {
          position: absolute;
          bottom: 10px;
          color: white;
          padding: 12px;
          font-size: 18px;
          line-height: 24px;
          letter-spacing: -0.5px;
          font-family: "Pretendard-Bold";
          width: 150px;
          word-break: keep-all;
        }
      }
    }
    .detail-wrapper {
      width: 100%;
      padding: 0 16px;
      margin-top: -32px;

      .d-title {
        text-align: start;
        color: $grey-6;
        font-size: 12px;
        font-family: "Pretendard-Medium";
      }

      .d-img {
        width: 100%;
        margin-top: 12px;
      }

      .d-text {
        margin-top: 20px;
        text-align: start;
        color: $grey-7;
        font-size: 14px;
        line-height: 22px;
        font-family: "Pretendard-Regular";
      }
    }
  }
}
