.settings {
  width: 100%;
  height: 100%;
  color: $grey-9;

  .push-noti {
    min-height: 176px;
    display: flex;
    flex-direction: column;
    border-bottom: 1px solid $grey-1;
    // box-shadow: 0 2px 2px 0 rgba($grey-8, 0.2);
    padding: 12px 16px;

    .noti, .promotion {
      display: flex;
      align-items: center;
      min-height: 86px;
      // border-bottom: 1px solid $grey-1;

      .title {
        font-size: 14px;
        line-height: 25px;
        letter-spacing: -0.35px;
        font-family: "Pretendard-SemiBold";
      }
      .sub {
        font-size: 12px;
        line-height: 20px;
        letter-spacing: -0.3px;
        font-family: "Pretendard-Regular";
        color: $grey-6;
      }
    }
  }

  .box-line {
    width: 100%;
    height: 8px;
    background-color: $grey-0;
  }

  .modify, .logout {
    padding: 24px 16px 8px;
    font-family: "Pretendard-Medium";
    font-size: 14px;
    
    button {
      font-size: 14px;
      color: $grey-9;
      font-family: "Pretendard-Medium";
      border: none;
      background-color: transparent;
      
    }
  }
}