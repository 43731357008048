// .integrated-login{
// display:flex;
// flex-direction: column;
.login-content {
  // position: absolute;
  // left:50%;
  // top:50%;
  // transform:translate(-50%,-50%);
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: 44px;
  min-height: calc(100vh - 180px);
  .logo-area {
    margin: 0 auto;
    h1 {
      width: 86px;
      margin: 0 auto;
      img {
        width: 100%;
        display: block;
      }
    }
    .logo-title {
      width: 101px;
      margin: 0 auto;
      img {
        width: 100%;
        display: block;
      }
    }
  }
  .social-login {
    margin: 56px auto 0;

    .social-title {
      max-width: 303px;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 12px;
      text-align: center;
      line-height: 14px;
      letter-spacing: -0.35px;
      color: $grey-5;
      margin: 0 auto 24px;
      &:before {
        content: "";
        display: inline-block;
        width: 90px;
        height: 1px;
        background-color: $grey-1;
        margin-right: 12px;
      }
      &:after {
        content: "";
        display: inline-block;
        width: 90px;
        height: 1px;
        background-color: $grey-1;
        margin-left: 12px;
      }
    }
    .social-wrapper {
      display: flex;
      margin: 0 19px;
      flex-shrink: 0;
      flex-grow: 0;
      justify-content: center;
      .individual-wrapper {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin: 0 8px;
        .social-name {
          font-size: 12px;
          line-height: 12px;
          color: $grey-5;
          letter-spacing: -0.3px;
          font-family: "Pretendard-Regular";
        }
      }
    }
    .sign-in {
      width: 52px;
      height: 52px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      // line-height:40px;
      margin: 0 auto;
      border-radius: 26px;
      margin-bottom: 8px;
      cursor: pointer;
      position: relative;
      .last-logined {
        &:after {
          border-color: #fff transparent;
          border-style: solid;
          border-width: 6.5px 8px 0 6px;
          content: "";
          display: block;
          left: 40px;
          position: absolute;
          bottom: -6px;
          width: 0;
          z-index: 1;
        }
        &:before {
          border-color: #fff transparent;
          border-style: solid;
          border-width: 6.5px 8px 0 6px;
          content: "";
          display: block;
          left: 40px;
          position: absolute;
          bottom: -7px;
          width: 0;
          z-index: 0;
        }
        background-color: #fff;
        // border: 1px solid rgba(0,0,0,0.16);
        border-radius: 5px;
        color: $grey-5;
        font-size: 12px;
        height: 48px;
        letter-spacing: -0.25px;
        position: absolute;
        width: 94px;
        right: -22px;
        top: -44px;
        box-shadow: 0 5px 10px rgba(0, 0, 0, 0.16);

        // &:after{
        //   content:"";
        //   display:inline-block;
        //   // width:15px;
        //   // height:15px;
        //   // background-color:#fff;
        //   // position: relative;
        //   // transform:  translateY(-15px) rotateZ(45deg);

        //   // z-index: 1;
        //   // background-color: #f00;
        //   // box-shadow: 0 0 2px rgba(0,0,0,0.16);
        //   width: 0;
        //   height: 0;
        //   border-bottom: 10px solid transparent;
        //   border-top: 10px solid #fff;
        //   border-left: 10px solid transparent;
        //   border-right: 10px solid transparent;
        //   transform:  translateY(-3px)

        // }
        // transform:rotateZ(0deg);
        // width:94px;
        // z-index: 9999;
        // height:48px;
        // font-size:12px;
        // letter-spacing: -0.3px;
        // position:relative;
        // right:0;
        // top:0;
        // color:$grey-5;
        // text-align:center;
        // background-color:#fff;
        // box-shadow: 0 0 2px rgba(0,0,0,0.16);
        // border-radius: 5px;
        // cursor:default;
        span {
          // position: relative;
          // transform:rotateZ(0deg);
          // z-index: 9999;
          font-family: "Pretendard-Medium";
          float: left;
          width: 100%;
          height: 14px;
          display: inline-block;
          line-height: 14px;
          text-align: center;
          &:first-child {
            margin-top: 8px;
          }
        }
      }
      p {
        display: inline-block;
        vertical-align: middle;
        font-family: "Pretendard-Regular";
        font-size: 12px;
        color: $grey-5;
        letter-spacing: -0.3px;
      }
      .social-icon {
        width: 24px;
        // margin:0 90px 0 10px;
        img {
          width: 100%;
          display: block;
        }
      }
    }
    .kakao {
      background-color: rgba(254, 229, 0, 1);
    }
    .naver {
      background-color: rgba(3, 189, 90, 1);
      // p{
      //   color:$white;
      // }
    }
    .apple {
      background-color: $grey-9;
      // p{
      //   color:$white;
      // }
    }
    .google {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      background-color: $white;
      border: 1px solid $grey-1;
      color: $grey-5;
      .circle-button {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
      }
      .social-icon {
        width: 24px;
        // margin:0 82px 0 10px;
      }
      // p{
      //   &:last-child{
      //     width:90px;
      //   }
      // }
      // .nsm7Bb-HzV7m-LgbsSe{
      //   height:500px;
      // } .jVeSEe{ height:500px;} .i5vt6e-Ia7Qfc{ height:500px;} .uaxL4e-RbRzK{ height:500px;}
    }
    .luxury {
      cursor: default;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      p {
        line-height: 26px;
        font-size: 13px;
        letter-spacing: -0.32px;
        color: $grey-6;
        cursor: pointer;
      }
    }
  }
}

// }
