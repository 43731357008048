.forcash {
  width: 100%;
  height: 100%;
  color: $grey-9;

  .cash-details {
    width: 100%;
    min-height: 220px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-bottom: 1px solid $grey-1;
    // box-shadow: 0 2px 2px 0 rgba($grey-8, 0.2);
    padding: 0 16px;

    .icon {
      min-width: 24px;
      width: 1.4vw;
      height: 24px;
    }

    .total-cash {
      margin-top: 8px;
      font-size: 14px;
      line-height: 24px;
      letter-spacing: -0.35px;
      font-family: "Pretendard-Medium";
    }

    .point {
      font-size: 34px;
      letter-spacing: -0.85px;
      font-family: "Pretendard-Bold";
      margin-top: 12px;

      span {
        font-family: "Pretendard-Regular";
        margin-left: 4px;
      }
    }

    .note {
      min-width: 343px;
      width: 100%;
      min-height: 62px;
      border: 1px solid $grey-1;
      border-radius: 8px;
      padding: 14px;
      margin-top: 20px;

      p {
        font-size: 12px;
        line-height: 16px;
        letter-spacing: -0.3px;
        color: $grey-5;
        font-family: "Pretendard-Regular";
      }
    }
  }

  .box-line {
    width: 100%;
    height: 8px;
    background-color: $grey-0;
  }

  .cash-history {
    width: 100%;

    .history-tab {
      min-height: 88px;
      padding: 0 16px;
      border-bottom: 1px solid $grey-1;
      display: flex;
      flex-direction: column;
      justify-content: space-evenly;

      .title {
        font-size: 16px;
        line-height: 25px;
        letter-spacing: -0.4px;
        font-family: "Pretendard-SemiBold";
      }

      .period-btn {
        button {
          padding: 6px 12px;
          font-size: 12px;
          line-height: 18px;
          letter-spacing: -0.3px;
          color: $grey-8;
          background-color: $white;
          border: 1px solid $grey-1;
          border-radius: 30px;
          margin: 0 2px;

          &.active {
            border: none;
            background-color: $main-purple-0;
            color: $white;
          }
        }
      }
    }

    .history {
      min-height: 500px;
      background-color: $grey-0;
      // padding: 0 16px;

      .history-zero {
        font-size: 17px;
        line-height: 30px;
        letter-spacing: -0.42px;
        color: $grey-5;
        font-family: "Pretendard-Regular";
      }
      
      .history-exist {
        min-height: 80px;
        background-color: $white;
        border-bottom: 1px solid $grey-1;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0 16px;

        .detail{
          
          .text {
            font-size: 15px;
            line-height: 25px;
            letter-spacing: -0.38px;
            font-family: "Pretendard-SemiBold";
          }
          .time {
            font-size: 11px;
            line-height: 18px;
            letter-spacing: -0.28px;
            color: $grey-5;
            font-family: "Pretendard-Regular";
          }
        }
        .mileage {
          font-size: 16px;
          line-height: 30px;
          letter-spacing: -0.4px;
          font-family: "Pretendard-Medium";
        }
      }
    }
  }
}