.new-post{
  display:flex;
  margin:0 16px;
  flex-direction: column;
  height:100vh;
  .sub-title{
    label{
      display:block;
      width:100%;
      margin:18px 0 10px;
      font-family: "Pretendard-Medium";
      font-size:15px;
      letter-spacing: -0.38px;
      color: $new-black;
    }
    input{
      display:inline-block;
      width:100%;
      text-indent:15px;
      color:$grey-5;
      font-size:15px;
      line-height:44px;
      border:1px solid $grey-1;
    }
  }
  .zonecode-area{
    margin-bottom:9px;
    #post-zonecode{
      width:43%;
    }
    button{
      width:33%;
      height: 45px;
      background-color: $main-purple-0;
      color:$white;
      border-radius: 22.5px;
      border:1px solid $main-purple-0;
      font-size:15px;
      margin-left:6px;
      cursor:pointer;
    }
  }
  .address-area{
    input{
      &:first-child{
        margin-bottom:8px;
      }
    }
  }
}
.daum-post-api{
  position: fixed;
  width:100%;
  min-width: 375px;
  max-width: 750px;
  z-index: 20;
  transition: all .3s linear;
  background-color: $white;
  border-radius: 10px 10px 0 0;
  .main-title-area{
    padding:26px 0 13px;
    position: relative;

    .main-title{
      margin-left:16px;
      font-size:20px;
      font-family:"Pretendard-SemiBold";
      letter-spacing: -0.5px;
      color: $new-black;
    }
    .close-icon{
      position: absolute;
      width:24px;
      height:24px;
      right:16px;
      top:26px;
      cursor:pointer;
      img{
        display:block;
        width:100%;
      }
    }
  }
}
.full-black{
  display:none;
  z-index: 10;
  position: fixed;
  width:100%;
  height:100%;
  top:0;
  min-width:375px;
  max-width: 750px;
  background-color:rgba(0,0,0,0.5);
  font-size:0;
  text-indent:-9999px;
}