.loading-spinner {
  width: 100%;
  min-width: 375px;
  max-width: 750px;
  height: 100vh;
  background: rgba(255, 255, 255, 0.6);
  position: fixed;
  top: 0;
  z-index: 49;
  left: 50%;
  transform: translateX(-50%);
}
