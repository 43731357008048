.popup-slide-banner {
  position: relative;
  overflow-y: none;
  z-index: 90000;

  .slick-slide div {
    outline: none;
    overflow: hidden;
    width: 329px;
    height: 329px;
  }
  .slick-dots {
    bottom: 10px;

    li {
      margin: 0;
      button {
        &::before {
          color: $grey-3;
        }
      }
    }
    li.slick-active {
      margin: 0;
      button {
        &::before {
          color: $white;
        }
      }
    }
  }
}
