.category{
  position: absolute;
  bottom:-60%;
  opacity:0;
  border-radius: 10px 10px 0 0 ;
  max-width: 750px;
  min-width: 375px;
  background-color: $white;
  z-index:20;
  transition: all .3s linear;
  .main-title{
    display:flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin:0 16px;
    padding:26px 0 16px;
    .text{
      font-family:"Pretendard-SemiBold";
      line-height:28px;
      letter-spacing: -0.5px;
      font-size:20px;
      color:$grey-9;
    }
  }
  .cate-solt{
    .title-area{
      display:flex;
      flex-direction: row;
      background-color:$grey-0;
      justify-content: space-between;
      align-items: center;
      padding:18px 16px 10px;
      .cate-title{
        font-size:16px;
        font-family:"Pretendard-Medium";
        letter-spacing: -0.4px;
        line-height:25px;
        color:$grey-9;
      }
      .select-all{
        font-size:13px;
        line-height:20px;
        letter-spacing: -0.39px;
        color:$grey-7;
        
        span{
          display:inline-block;
          margin-right:5px;
        }
      }
    }
    >ul{
      overflow:hidden;
      margin-bottom:24px;
      > li{
        margin:0 16px;
        .cate-sub-title{
          font-family:"Pretendard-Medium";
          margin:16px 0 10px;
          font-size:14px;
          line-height:25px;
          letter-spacing: -0.35px;
          color:$grey-8;
          
        }
        ul{
          display:flex;
          flex-direction: row;
          flex-wrap: wrap;
          li{
            flex-basis: 168px;
            flex-shrink: 0;
            flex-grow: 0;
            display:inline-block;
            padding:12px 16px;
            border:1px solid $grey-1;
            margin-right:3px;
            margin-bottom:4px;
            border-radius: 4px;
            cursor: pointer;
            p{
              &:first-child{
                margin-right:12px;
              }
              display:inline-block;
            }
          }
        }
      }
    }
  }
}