// .login {
//   width: 100%;
//   display: flex;
//   flex-direction: column;
//   align-items: center;

//   .state {
//     display: flex;
//     margin-top: 20px;
//   }

//   button {
//     width: 80%;
//     height: 40px;
//     margin: 12px;
//     background-color: $main-color-300;
//     color: $white;
//   }
// }
.luxury-sign-in{
  // position:absolute;
  // left:50%;
  // top:50%;
  // transform: translate(-50%,-50%);
  max-width: 375px;
  min-width:375px;
  margin:0 auto;

  .main-title {
    font-family:"Pretendard-Bold";
    font-size:24px;
    line-height:40px;
    color: #333;;
    margin:0 36px;
    text-align: center;
  }
//   .login-input:-webkit-autofill,
//   .login-input:-webkit-autofill:hover,
//   .login-input:-webkit-autofill:focus,
//   .login-input:-webkit-autofill:active {
//    transition: background-color 5000s ease-in-out 0s;
//    -webkit-transition: background-color 9999s ease-out;
//    -webkit-box-shadow: 0 0 0px 1000px white inset !important;
//    -webkit-text-fill-color: #fff !important;
//  }
 

  .login-input {
    margin:0 36px;
   
    #user-id:-webkit-autofill,
    #user-id:-webkit-autofill:hover, 
    #user-id:-webkit-autofill:focus {
      // border: 1px solid green;
      // -webkit-text-fill-color: green;
      -webkit-box-shadow: 0 0 0px 1000px #fff inset;
      transition: background-color 5000s ease-in-out 0s;
    }
    #user-pw:-webkit-autofill,
    #user-pw:-webkit-autofill:hover, 
    #user-pw:-webkit-autofill:focus {
      // border: 1px solid green;
      // -webkit-text-fill-color: green;
      -webkit-box-shadow: 0 0 0px 1000px #fff inset;
      transition: background-color 5000s ease-in-out 0s;
    }
    .login-btn {
      width: 100%;
      margin-top: 40px;
      font-family:"Pretendard-SemiBold";
      background-color:$grey-3;
      color: $white;
      border: none;
      // padding: 0;
      line-height:52px;
      height:52px;
      cursor:pointer;
      border-radius: 4px;
      font-size: 16px;
      letter-spacing: -0.2px;
      background-color:$main-purple-dark-1;
      // &.active {
      //   background-color:$main-purple-dark-1;
      // }
    }

    input[type="button"] {
      &.active{
        background-color:$main-purple-dark-1;
        border-radius: 4px;
      }
      font-family:"Pretendard-SemiBold";
      background-color:$grey-3;
      color: $white;
      border:0;
      padding:0;
      line-height:40px;
      cursor:pointer;
      border-radius: 4px;
    }

    input {
      width:100%;
      // padding-bottom:11.5px;
      margin-top:28px;
      outline:none;
      font-family:"Pretendard-Regular";
      font-size:15px;
      line-height:48px;
      letter-spacing: -0.38px;
      color:$grey-5;
      // border:0;
      border:1px solid $grey-1;
      text-indent:16px;
      border-radius: 4px;
      &#user-pw{
        margin-top:12px;
      }
    }

    #user-id,#user-pw {
      &:focus {
        border-color: $main-purple-light-4;
        caret-color: $main-purple-light-4;
    
      }
    }

    label[for="user-pw"] {
      position: absolute;
      right:16px;
      top:24px;
    }

    .pw-guide {
      position: absolute;
      margin-top:11px;
      font-size:13px;
      letter-spacing: -0.39px;
      color:$main-purple-0;
      line-height:20px;
      font-family:"Pretendard-Regular";
    }
    .error-mark{
      display: block;
      width: 18px;
      height: 18px;
      line-height: 18px;
      border-radius: 9px;
      color: #E1212C;
      border: 1px solid #E1212C;
      text-align: center;
      margin-left: 3px;
    }
  }
  .sign-up-find{
    margin:16px 36px 0;
    display:flex;
    justify-content: space-between;

    button {
      font-family:"Pretendard-Medium";
      font-size:14px;
      line-height:21px;
      
      letter-spacing: -0.35px;
      color:$grey-7;
      cursor:pointer;
      border:0;
      background:none;
      a{
      color:$grey-7;

      }
    }

    .bar {
      color:$grey-1;
    }
  }
}