.steps-11st-list {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  max-width: 70px;
  min-width: 70px;

  // &:after {
  //   content: "";
  //   display: flex;
  //   justify-content: center;

  //   min-width: 48px;
  //   max-width: 96px;
  //   border-bottom: 1px dotted #c9cdd2;

  //   position: absolute;
  //   top: 9px;
  //   right: -30px;
  // }

  // &:last-child {
  //   &:after {
  //     display: none;
  //   }
  // }
}
.select-item {
  &.active {
    border-color: #0f0fd9 !important;
    background-color: rgba(15, 15, 217, 0.05) !important;
    p {
      color: #0f0fd9 !important;
    }
  }
}
.promo11st-verify-wrap {
  max-width: 375px;
  min-width: 375px;
  margin: 0 auto;
  .promo11st-verify-mobile-11st-title {
    padding: 52px 0 24px;
    margin-left: 16px;
    font-size: 24px;
    line-height: 36px;
    letter-spacing: -0.5px;
    font-family: "Pretendard-Bold";
  }

  form {
    input {
      &#promo11st-mobile-number {
        width: 235px;
      }
      width: 100%;
      height: 44px;
      line-height: 44px;
      text-indent: 16px;
      color: #848b91;
      border-radius: 4px;
      border: 1px solid #e8ebed;
    }
    button {
      width: 100px;
      background-color: #0f0fd9;
      border-radius: 4px;
      border: 0;
      color: #fff;
      font-family: "Pretendard-Regular";
      font-size: 16px;
      letter-spacing: -0.5px;
    }
    .promo11st-verify-guide {
      color: #0f0fd9;
      font-size: 14px;
      letter-spacing: -0.5px;
      margin-top: 12px;
    }
  }
  .promo11st-terms {
    display: flex;
    padding: 0 16px;
    margin-top: 56px;
    font-size: 18px;
    letter-spacing: -0.5px;
    font-family: "Pretendard-Bold";
    align-items: center;
    gap: 8px;
    .terms-title {
      min-width: 95px;
    }
    .terms-line {
      height: 1px;
      flex-grow: 1;
      flex-shrink: 0;
      background-color: #c9cdd2;
      font-size: 0;
    }
  }
  .terms-wrap {
    margin-top: 30px;
    padding: 0 16px;
    input {
      display: none;
    }
    label {
      display: flex;
      align-items: center;
      .checkbox_bg {
        display: block;
        background-image: url("../../assets/img/icCheck21Off.svg");
        font-size: 0;
        background-repeat: no-repeat;
        width: 20px;
        height: 20px;
        background-size: 100% 100%;
      }
    }
    label[for="promo11st-terms-all-agree"] {
      .text {
        line-height: 16px;
        margin-left: 6px;
        font-size: 16px;
        letter-spacing: -0.5px;
        font-family: "Pretendard-Bold";
      }
    }

    input:checked + label .checkbox_bg {
      background-image: url("../../assets/img/icCheckBlueOn.svg");
    }

    .terms-list {
      padding-left: 20px;
      padding-top: 20px;
      > div {
        display: flex;
        align-items: center;
      }
      label .text {
        margin-left: 10px;
        font-size: 14px;
        letter-spacing: -0.5px;
        font-family: "Pretendard-Regular";
      }
      .terms-view {
        color: rgba(15, 15, 217, 0.6);
        font-size: 14px;
        font-family: "Pretendard-Regular";
        margin-left: 8px;
        cursor: pointer;
      }
    }
  }
}

// .promo11st-selected-unit {
//   margin-top: 8px;
//   display: inline-block;
//   border-radius: 8px;
//   background-color: #0f0fd9;
//   color: #fff;
//   padding: 8px 12px;
//   font-family: "Pretendard-Regular";
//   cursor: pointer;
// }
.promo11st-terms-popup {
  position: absolute;
  width: 100%;
  max-width: 750px;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 100;
  overflow: scroll;
  height: 100vh;
}

.entered-wrapper.promo11st {
  // border-top: 1px solid $grey-1;
  background-color: $white;
  position: fixed;
  bottom: 0;
  min-width: 343px;
  width: 100%;
  max-width: 750px;
  min-height: 96px;
  z-index: 10;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 16px;

  .request-entered {
    width: 100%;
    min-height: 48px;
    border: none;
    border-radius: 4px;
    font-size: 16px;
    line-height: 24px;
    font-family: "Pretendard-Medium";
    background-color: $grey-3;
    pointer-events: none;
    cursor: pointer;

    p {
      color: $white;
    }
    a {
      color: $white;
    }
    a:visited {
      color: $white;
    }
    &.selected {
      background-color: $main-purple-dark-1;
    }
    &.selected.promo11st {
      background-color: #0f0fd9;
      pointer-events: initial;
    }
  }
}
.promo11st-complete-wrap {
  display: flex;
  flex-direction: column;
  align-items: center;

  height: calc(100vh - 84px);
  max-width: 750px;
  min-width: 375px;
  .promo11st-complete-title {
    width: 100%;
    display: flex;
    flex-direction: column;

    align-items: center;
    justify-content: center;
    margin-top: 120px;
    p {
      width: 42px;
      height: 42px;
      img {
        width: 100%;
        display: block;
      }
    }
    > div {
      padding: 16px 0 56px;
      p {
        width: 100%;
        text-align: center;
        font-size: 28px;
        line-height: 21px;
        font-family: "Pretendard-Bold";
      }
    }
  }
  .promo11st-complete-text {
    text-align: center;
    font-family: "Pretendard-Regular";
    font-size: 14px;
    letter-spacing: -0.5px;

    p {
      line-height: 25.2px;
      span {
        font-family: "Pretendard-Bold";
      }
    }
  }
}
.promo11st-estimate-title {
  font-size: 24px;
  line-height: 24px;
  letter-spacing: -0.5px;
  text-align: center;
  font-family: "Pretendard-Bold";
  padding: 40px 0;
}
.promo11st-borading {
  width: 100%;
  max-width: 750px;
  min-width: 375px;
  padding-bottom: 34px;
  background-image: url("../../assets/img/splash_bg@3x.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  .promo11st-gretting {
    font-family: "Pretendard-Bold";
    color: #fff;
    font-size: 16px;
    line-height: 16px;
    letter-spacing: -0.5px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding-top: 25px;
    padding-bottom: 34px;
    .top-line {
      margin-top: 7px;
      font-size: 0;
      border-top: 1px solid #fff;
      width: 166px;
    }
  }
  .promo11st-brand-slogan {
    font-family: "Pretendard-Regular";
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    color: #fff;
    font-size: 32px;
    .promo11st-brand-name {
      margin-top: 10px;
      span {
        font-family: "Pretendard-Bold";
      }
    }
  }

  .promo11st-mobile-preview {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
  .promo11st-discount {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 21px;
    position: relative;
    p {
      background-color: #0f0fd9;
      color: #fff;
      border-radius: 25.5px;
      font-size: 15px;
      letter-spacing: -0.5px;
      font-family: "Pretendard-SemiBold";
      text-align: center;
      padding: 12px 20px;
    }
    span {
      position: absolute;
      top: -15px;
    }
  }
  .promo11st-kakao {
    display: flex;
    align-items: center;
    height: 51px;
    width: 100%;
    max-width: 343px;
    margin: 0 auto;
    background-color: #ffe400;
    border-radius: 5px;
    position: relative;
    .kakao-logo {
      position: absolute;
      left: 10px;
      top: 10.5px;
    }
    .kakao-logo-text {
      width: 100%;
      display: inline-block;
      text-align: center;
      font-family: "Pretendard-SemiBold";
    }
  }
}

.promo11st-estimate-wrap {
  background-color: $grey-0;
  overflow: hidden;
  padding-bottom: 89px;
  min-height: 100vh;
  font-family: "Pretendard-Regular";
  .compare-estimate-notice {
    display: flex;
    justify-content: space-between;
    // align-items: nter;
    font-family: "Pretendard-Regular";
    background-color: $white;
    border-radius: 8px;
    box-shadow: 0px 0px 8px rgba(109, 109, 110, 0.1);
    margin: 12px 16px 0;
    padding: 15px 11px 14px 15px;
    p {
      color: $grey-6;
      font-size: 13px;
      line-height: 21px;
      letter-spacing: -0.32px;
      span {
        font-family: "Pretendard-SemiBold";
      }
    }
  }
  .total-simple-case {
    display: flex;
    justify-content: space-between;
    margin: 0 16px;
    padding-bottom: 14px;
    .total-estimate {
      font-family: "Pretendard-Medium";
      font-size: 14px;
      letter-spacing: -0.5px;
    }
    input {
      display: none;
    }
    input:checked + label .checkbox-bg {
      background-image: url("../../assets/img/icCheckbox18On.svg");
    }
    label {
      cursor: pointer;
      .checkbox-bg {
        display: inline-block;
        vertical-align: middle;
        width: 18px;
        height: 18px;
        margin-right: 5px;
        background-image: url("../../assets/img/icons/icCheckbox18Off01.svg");
        background-size: 100% 100%;
      }
      .simple-case-text {
        font-family: "Pretendard-Regular";
        font-size: 14px;
      }
    }
  }
  .estimate-solt {
    border-bottom: 1px solid $grey-1;
    overflow: hidden;
    padding-bottom: 12px;
    ul {
      display: flex;
      flex-direction: row;
      margin: 13px 16px 12px;
      li {
        input {
          display: none;
        }
        input:checked + label {
          background-color: #0f0fd9;
          color: $white;
        }
        label {
          cursor: pointer;
          font-size: 14px;
          font-family: "Pretendard-Regular";
          letter-spacing: -0.5px;
          padding: 8px 12px;
          background-color: $white;
          border-radius: 16px;
          margin-right: 6px;
          color: $grey-6;
        }
      }
    }
  }
  .simple-case {
    display: none;
    .simple-case-solt {
      display: flex;
      margin: 16px 32px 12px;
      li {
        // &.solt-price{
        //   text-align: center;
        // }
        &.solt-period {
          width: 30%;
        }
        width: 35%;
        font-size: 12px;
        line-height: 12px;
        letter-spacing: 0.5px;
        // flex-grow:1;
        color: $grey-6;
      }
    }
    // .simple-case-list {
    //   display: flex;
    //   flex-direction: column;
    //   margin: 0 16px;
    //   li {
    //     &.active {
    //       border: 2px solid $main-purple-0;
    //     }
    //     display: flex;
    //     flex-grow: 1;
    //     background-color: $white;
    //     padding: 16px;
    //     border-radius: 6px;
    //     border: 1px solid $grey-1;
    //     margin-bottom: 8px;
    //     p {
    //       &.meister-name {
    //         cursor: pointer;
    //       }
    //       &.expect-period {
    //         width: 30%;
    //       }
    //       letter-spacing: -0.5px;
    //       width: 35%;
    //       font-size: 14px;
    //       font-family: "Pretendard-Medium";
    //       line-height: 14px;
    //     }
    //   }
    // }
  }
  .estimate-list {
    display: flex;
    margin: 16px;
    > ul {
      width: 100%;
      > li {
        cursor: pointer;
        &.active {
          border: 1px solid #0f0fd9;
        }
        margin-bottom: 16px;
      }
    }
    li {
      position: relative;
      flex-grow: 1;
      background-color: $white;
      border-radius: 8px;
      padding-bottom: 16px;
      border: 1px solid $white;

      .estimate-list-head {
        padding: 14px 20px 17px;
        .estimate-list-head-title {
          font-family: "Pretendard-SemiBold";
          font-size: 20px;
          letter-spacing: -0.5px;
          .meister-name {
            cursor: pointer;
          }
        }
        .estimate-list-head-grade {
          display: flex;
          align-items: center;
          margin-top: 6px;
          border-bottom: 1px solid $grey-1;
          padding-bottom: 17px;
          p {
            margin-top: 1px;
            font-size: 14px;
            color: $grey-6;
            line-height: 16px;
            // span{
            //   font-size:14px;
            //   display:inline-block;

            // }
            // .purple-text{
            //   font-family:"Pretendard-Medium";
            //   color:$main-purple-dark-1;
            // }
          }
          .grade-point {
            color: $grey-9;
          }
          .star-icon {
            margin-top: 0;
            width: 16px;
            img {
              display: block;
              width: 100%;
            }
          }
        }
      }
      .fixation {
        pointer-events: none;
        position: absolute;
        right: 20px;
        top: 18px;
        input {
          display: none;
        }
        input:checked + label {
          .fixation-bg {
            background-image: url("../../assets/img/icCheckBlueOn.svg");
          }
          .bg-text {
            font-size: 0;
          }
        }
        label {
          cursor: pointer;
          .fixation-bg {
            display: block;
            width: 32px;
            height: 32px;
            margin: 0 auto;
            background-image: url("../../assets/img/icCheck21Off.svg");
            background-size: 100% 100%;
            border-radius: 16px;
            background-color: $grey-0;
          }
          .bg-text {
            margin-top: 6px;
            display: block;
            text-align: center;
            width: 36px;
            font-size: 10px;
            letter-spacing: -0.5px;
            font-family: "Pretendard-Regular";
            font-size: 0;
          }
        }
      }
      .estimate-list-price-period {
        margin: 0 20px 14px;
        .estimate-list-price-period-title {
          display: flex;
          font-size: 12px;
          line-height: 12px;
          color: $grey-6;
        }
        .estimate-list-price-period-text {
          display: flex;
          font-size: 22px;
          line-height: 26px;
          letter-spacing: -0.5px;
          font-family: "Pretendard-SemiBold";
          color: $grey-9;
          li {
            &:first-child {
              width: 60%;
            }
            width: 40%;
            p {
              &:first-child {
                margin-top: 10px;
              }
              display: inline-block;
            }
            .purple-text {
              vertical-align: bottom;
              display: inline-block;
              margin-left: 4px;
              padding: 6px 8px;
              font-family: "Pretendard-Regular";
              letter-spacing: -0.5px;
              font-size: 10px;
              line-height: 12px;
              color: #0f0fd9;
              border: 1px solid #0f0fd9;
              border-radius: 12px;
            }
          }
        }
      }
      .estimate-list-desc {
        background-color: $grey-0;
        margin: 0 16px;
        padding: 16px;
        border-radius: 6px;
        font-family: "Pretendard-Regular";
        font-size: 14px;
        line-height: 22px;
        letter-spacing: -0.5px;
      }
    }
  }
  .button-area {
    position: fixed;
    bottom: 32px;
    display: flex;
    width: 100%;
    min-width: 375px;
    max-width: 750px;
    button {
      &.active {
        background-color: #0f0fd9;
        cursor: pointer;
      }
      cursor: default;
      margin: 0 16px;
      flex-grow: 1;
      background-color: $grey-4;
      color: $white;
      font-family: "Pretendard-SemiBold";
      font-size: 16px;
      line-height: 57px;
      letter-spacing: -0.4px;
      border: 0;
      border-radius: 6px;
      display: block;
    }
  }
}
