.forcoupon {
  width: 100%;
  height: 100%;
  color: $grey-9;

  .coupon-input {
    min-height: 130px;
    padding: 0 16px;
    display: flex;
    flex-direction: column;
    // justify-content: center;
    border-bottom: 1px solid $grey-1;
    // box-shadow: 0 2px 2px 0 rgba($grey-8, 0.2);

    .regist {
      font-size: 13px;
      letter-spacing: -0.32px;
      font-family: "Pretendard-Medium";
      margin-top: 14px;
    }
    .coupon-form {
      min-height: 46px;
      margin: 8px 0 14px;
      display: flex;
      align-items: center;

      .input-box {
        min-height: 46px;
        width: 72%;
        font-size: 15px;
        line-height: 20px;
        letter-spacing: -0.45px;
        color: $grey-5;
        font-family: "Pretendard-Regular";
        border: 1px solid $grey-1;
        border-radius: 4px;
        padding: 12px;
      }
      .submit-btn {
        min-height: 46px;
        width: 26%;
        border: none;
        border-radius: 4px;
        background-color: $main-purple-dark-1;
        color: $white;
        margin-left: 2%;
        cursor: pointer;
        font-size: 14px;
        line-height: 20px;
        letter-spacing: -0.45px;
        font-family: "Pretendard-SemiBold";
      }
    }
    .re-login {
      font-size: 11px;
      line-height: 20px;
      letter-spacing: -0.28px;
      color: $grey-6;
      font-family: "Pretendard-Regular";
      display: flex;
      align-items: center;
    }
  }

  .box-line {
    width: 100%;
    height: 8px;
    background-color: $grey-0;
  }

  .coupon-history {
    .history-tab {
      min-height: 50px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0 16px;
      border-bottom: 1px solid $grey-1;

      .my-history {
        font-size: 16px;
        line-height: 25px;
        letter-spacing: -0.4px;
        font-family: "Pretendard-SemiBold";
      }
      .order-by {
        position: relative;
        height: 24px;
        display: flex;
        cursor: pointer;

        .arrow-down-icon {
          position: absolute;
          right: 0;
          top: 3px;
          z-index: 20;
        }

        ul {
          width: 106px;
          height: 24px;
          overflow: hidden;
          z-index: 10;
          transition: all 0.3s linear;
          border-radius: 4px;
          border: 1px solid $grey-2;
          background: white;

          li {
            line-height: 24px;
            font-size: 14px;
            background-color: white;
            border-bottom: 1px solid $grey-2;
            // width: 100%;
            // text-align: left;
            // text-indent:10px;
            // width: 80px;
            // text-align: center;
            text-indent: 15px;
            font-family: "Pretendard-Regular";
          }
        }
      }
    }

    .coupon-list {
      background-color: rgba(247, 248, 249, 1);
      display: flex;
      flex-direction: column;
      padding: 16px;
      padding-bottom: 0;

      .data-zero {
        min-height: 520px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 16px;
        line-height: 30px;
        color: $grey-5;
      }

      li {
        border-radius: 8px;
        > div {
          display: flex;
          margin-left: 34px;
        }
        position: relative;
        color: $grey-8;
        background-color: $white;
        margin-bottom: 16px;
        padding: 20px 0 21px;

        .coupon-title {
          font-family: "Pretendard-Medium";
          letter-spacing: -0.2px;
          font-size: 12px;
        }

        .coupon-amount {
          font-family: "Pretendard-SemiBold";
          font-size: 22px;
          letter-spacing: -0.6px;
          margin: 10px 0 24px 34px;
        }

        .coupon-limit {
          margin-bottom: 8px;
        }

        .coupon-limit,
        .coupon-expiry-date {
          font-family: "Pretendard-Regular";
          font-size: 12px;
          letter-spacing: -0.11px;
        }

        .coupon-expiry-explain {
          margin: 8px 0 0 34px;
          font-family: "Pretendard-Regular";
          font-size: 12px;
          letter-spacing: -0.11px;
        }

        .remain-date {
          // display: none;
          color: $main-purple-dark-1;
          font-family: "Pretendard-SemiBold";
          font-size: 11px;
          letter-spacing: -0.11px;
        }
        .luxurynall-logo {
          display: flex;
          min-width: 75px;
          max-width: 85px;
          position: absolute;
          right: 0;
          bottom: 0;
          img {
            width: 100%;
          }
        }
        .dot-menu {
          width: 24px;
          position: absolute;
          right: 13px;
          top: 13px;
          cursor: pointer;
          img {
            width: 100%;
          }
        }
        .gray-half-circle {
          z-index: 1;
          position: absolute;
          left: 5px;
          bottom: 50%;
          transform: translate(-50%, 50%);
          width: 24px;
          height: 24px;
          background-color: rgba(247, 248, 249, 1);
          text-indent: -9999px;
          font-size: 0;
          border-radius: 12px;
        }
        .black-blur {
          display: none;
          width: 100%;
          height: 100%;
          text-align: center;
          position: absolute;
          line-height: 136px;
          left: 0;
          top: 0;
          background-color: rgba(0, 0, 0, 0.5);
          color: $white;
          margin-left: 0;
          border-radius: 8px;

          p {
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
            font-size: 16px;
            letter-spacing: -0.38px;
          }
        }
      }
    }

    .history-content {
      min-height: 300px;
      background-color: $grey-0;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}
