.purple-text {
  color: $main-purple-0;
  font-family: "Pretendard-Medium";
}
.chkbox-bg {
  vertical-align: middle;
  display: inline-block;
  width: 24px;
  height: 24px;
  margin-left: 14px;
  margin-bottom:2px;
  background-image: url("../../assets/img/icCheck21Off.svg");
  background-size: 100% 100%;
  background-position: center center;
}

.agree {
  display: flex;
  flex-direction: column;
  padding-bottom:30px;
  height:100vh;
  input {
    display: none;
  }
  input:checked + label .chkbox-bg{
    background-image: url("../../assets/img/icCheck21On.svg");
  }

  .agree-title {
    display: inline-block;
    font-size: 16px;
    font-family: "Pretendard-Regular";
    font-weight: 400;
    letter-spacing: -0.4px;
    line-height: 56px;
    color: $new-black;
    margin-left: 8px;
  }

  // .chkbox-bg {
  //   vertical-align: middle;
  //   display: inline-block;
  //   width: 24px;
  //   height: 24px;
  //   margin-left: 14px;
  //   margin-bottom:2px;
  //   background-image: url("../../assets/img/icons/icCheck21Off.svg");
  //   background-size: 100% 100%;
  //   background-position: center center;
  // }

  .main-text {
    font-size: 20px;
    font-family: 'Pretendard-Bold';
    font-weight: 700;
    letter-spacing: -0.5px;
    margin: 0 16px;

    p {
      &:first-child {
        margin: 24px 0 30px;

        span {
          display: block;
          line-height: 32px;
          ;
        }
      }

      &.all-agree {
        cursor:pointer;
        border: 1px solid $grey-1;
        line-height: 50px;
        margin-bottom: 18px;
        border-radius: 4px;
        label{
          cursor:pointer;
          display: inline-block;
          width:100%;
        }
      }
    }
  }

  .box-line {
    width: 100%;
    height: 8px;
    background-color: $grey-0;
    flex: 0 0 auto;
    border-top: 1px solid $grey-1;
  }

  .agree-part-list {
    background-color: $grey-0;

    li {
      &:first-child {
        margin-top: 7px;
      }

      background-color:$white;

      .agree-check-parents {
        position: relative;
        cursor:pointer;
        .agree-check-button {
          display:inline-block;
          line-height: 56px;
          cursor:pointer;
        }
        .dropdown-arrow-icon {
          display: inline-block;
          position: absolute;
          right:16px;
          top:16px;
          width: 24px;
          height: 24px;


          img {
            width: 100%;
          }
        }
      }

      .agree-text {
        &:first-child {
          span {
            &:last-child {
              margin-top: 14px;
            }
          }
        }

        transition:all .3s linear;
        background-color: $grey-0;
        font-size:13px;
        color:$grey-7;
        letter-spacing: -0.39px;
        line-height: 21px;
        overflow: scroll;

        span {
          &:first-child {
            margin-top: 20px;
          }

          &:first-child~span {
            margin-top: 16px;
          }

          &:last-child {
            margin-bottom: 28px;
          }

          display: block;
          margin:0 16px;
        }
      }
    }
  }
}