.Tab-menu {
  position: sticky;
  left: 0;
  top: 56px;
  width: 100%;
  padding: 0 16px;
  border-bottom: 1px solid $grey-300;
  display: flex;
  justify-content: space-between;
  background: $white;
  z-index: 100;
  span {
    cursor: pointer;
    width: calc(100%);
    padding: 12px 0;
    display: flex;
    justify-content: center;
    align-items: center;
    color: $grey-600;
    font-size: 16px;
    line-height: 16px;
    letter-spacing: -0.5px;
    border-bottom: 2px solid transparent;
    font-family: "Pretendard-Regular";
    &.active {
      font-family: "Pretendard-Medium";
      color: $purple-700;
      border-bottom: 2px solid $purple-700;
      transition: border 0.3s;
    }
  }
}
