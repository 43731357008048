.manage-addr-list {
  position: fixed;
  left: 50%;
  transform: translateX(-50%);
  top: 0;
  width: 100%;
  min-width: 375px;
  max-width: 750px;
  height: calc(var(--vh, 1vh) * 100);
  background-color: $grey-0;
  z-index: 100;
  overflow: scroll;
  .item-area {
    padding: 16px;
    display: flex;
    flex-direction: column;
    gap: 16px;
    .item {
      background-color: $white;
      border: 0.5px solid $grey-1;
      padding: 20px;
      border-radius: 8px;
      box-shadow: 0px 2px 10px 0px rgba(83, 83, 84, 0.08);
      .name-line {
        display: flex;
        align-items: center;
        gap: 6px;
        font-family: "Pretendard-Regular";
        font-size: 14px;
        margin-bottom: 12px;
        color: $grey-9;
        .default-tag {
          color: $main-purple-dark-1;
          font-family: "Pretendard-Medium";
          font-size: 10px;
          line-height: 10px;
          border: 1px solid $main-purple-0;
          padding: 4px 6px;
          border-radius: 4px;
        }
        .bar {
          color: $grey-3;
        }
      }
      .address {
        line-height: 22.4px;
        font-size: 14px;
        font-family: "Pretendard-Regular";
        color: $grey-7;
        margin-bottom: 20px;
      }
      .btn-area {
        display: flex;
        justify-content: space-between;
        align-items: center;
        color: $grey-9;
        button {
          color: $grey-9;
          cursor: pointer;
          &.select-btn {
            background: $main-purple-dark-1;
            border: none;
            color: $white;
          }
          background: $white;
          border: 1px solid $grey-1;
          border-radius: 4px;
          padding: 8px 10px;
          line-height: 100%;
          font-size: 14px;
        }
        .left-btn-area {
          display: flex;
          gap: 8px;
        }
      }
    }
    .add-btn {
      margin-top: 12px;
      button {
        width: 100%;
        background: $main-purple-dark-1;
        color: $white;
        border-radius: 4px;
        height: 48px;
        border: none;
        font-size: 16px;
        line-height: 100%;
        letter-spacing: -0.5px;
        font-family: "Pretendard-Medium";
        cursor: pointer;
      }
    }
  }
  // .empty-area {
  .empty {
    font-family: "Pretendard-Regular";
    font-size: 16px;
    letter-spacing: -0.5px;
    color: $grey-7;
    line-height: 100%;
    text-align: center;
    display: flex;
    flex-direction: column;
    gap: 20px;
    margin-top: 266px;
    button {
      color: $white;
      background: $main-purple-dark-1;
      height: 44px;
      width: 148px;
      font-size: 16px;
      letter-spacing: -0.5px;
      border: none;
      border-radius: 4px;
      cursor: pointer;
      font-family: "Pretendard-Medium";
    }
  }
  // }
}
// 신규 배송지 등록
.add-new-addr {
  background-color: $white;
  position: fixed;
  left: 50%;
  top: 0;
  transform: translateX(-50%);
  width: 100%;
  max-width: 750px;
  min-width: 375px;
  height: calc(var(--vh, 1vh) * 100);
  z-index: 200;
  .contents {
    margin-bottom: 12px;
    border-top: 0px;
    padding: 24px 16px;
    font-family: "Pretendard-Regular";
    font-size: 14px;
    line-height: 100%;
    letter-spacing: -0.5px;
    display: flex;
    flex-direction: column;
    color: $grey-9;
    gap: 10px;
    .line {
      display: flex;
      align-items: center;
      .label {
        width: 64px;
        flex: 0 0 auto;
      }
      input {
        border: 1px solid $grey-1;
        width: 100%;
        padding: 15px 16px;
        line-height: 14px;
        border-radius: 4px;
        font-size: 14px;
        height: 44px;
        &:focus {
          border: 1px solid $main-purple-light-4;
          outline: none;
        }
      }
      .input-wrap {
        width: 100%;
        @supports (-webkit-touch-callout: none) {
          input {
            &:disabled {
              border: 1px solid $grey-2;
              background-color: $grey-1;
            }
          }
        }
      }
      .zip-code {
        display: flex;
        width: 100%;
        gap: 8px;
        .input-wrap {
          width: calc(100% / 3 * 2);
          input {
            width: 100%;
            height: 44px;
            background-color: $grey-0;
          }
        }
        button {
          all: unset;
          cursor: pointer;
          width: calc(100% / 3);
          background-color: $white;
          text-align: center;
          color: $main-purple-dark-1;
          border-radius: 4px;
          border: 1px solid $main-purple-dark-1;
        }
      }
      .addr1 {
        background-color: $grey-0;
      }
    }
    .chk-area {
      padding-top: 9px;
      padding-bottom: 3px;
      display: flex;
      align-items: center;
      label {
        cursor: pointer;
        width: 100%;
        display: flex;
        align-items: center;
        gap: 7px;
        .chk-img {
          width: 24px;
          height: 24px;
          aspect-ratio: 1/1;
          background: url(../../../assets/img/check_small_default.svg) no-repeat
            center / contain;
        }
        input {
          display: none;
          &:checked {
            & + .chk-img {
              background: url(../../../assets/img/check_small_selected.svg)
                no-repeat center / contain;
            }
          }
        }
      }
    }
    .add-btn {
      position: absolute;
      bottom: 16px;
      height: 48px;
      line-height: 100%;
      border-radius: 4px;
      color: $white;
      font-size: 16px;
      letter-spacing: -0.5px;
      font-family: "Pretendard-Medium";
      border: none;
      background-color: $grey-3;
      width: calc(100% - 32px);
      &.active {
        cursor: pointer;
        background-color: $main-purple-dark-1;
      }
    }
  }
}
// 주소검색 팝업
.postcode {
  width: 100%;
  height: calc(var(--vh, 1vh) * 100);
  max-width: 750px;
  min-width: 375px;
  position: fixed;
  z-index: 300;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  background-color: rgba(0, 0, 0, 0.5);
  @supports (-webkit-touch-callout: none) {
    .postcode-popup {
      height: -webkit-fill-available;
    }
  }
  .postcode-popup {
    @keyframes slideUp {
      from {
        transform: translateY(100%);
      }
      to {
        transform: translateY(0);
      }
    }
    animation: slideUp 0.2s;
    padding: 26px 16px 0px;
    border-radius: 10px 10px 0 0;
    position: absolute;
    bottom: 0;
    width: 100%;
    height: fit-content;
    max-height: calc(100vh - 60px);
    background-color: $white;
    // left: 0;
    .popup-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 13px;
      .title {
        font-family: "Pretendard-SemiBold";
        font-size: 20px;
        line-height: 100%;
        letter-spacing: -0.5px;
      }
      .close-btn {
        all: unset;
        cursor: pointer;
        width: 24px;
        height: 24px;
        background: url(../../../assets/img/icClose24Black.svg) no-repeat center /
          contain;
      }
    }
  }
}
// 확인 팝업
.Popup {
  width: 100%;
  height: calc(var(--vh, 1vh) * 100);
  position: fixed;
  left: 50%;
  top: 0;
  background-color: rgba(0, 0, 0, 0.6);
  z-index: 100;
  transform: translateX(-50%);
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 750px;
  min-width: 375px;
  z-index: 300;
  .contents {
    background-color: $white;
    border-radius: 8px;
    overflow: hidden;
    width: 328px;
    .text-area {
      padding: 40px 24px 36px;
      text-align: center;
      .title {
        font-family: "Pretendard-Bold";
        font-size: 20px;
        letter-spacing: -0.5px;
        margin-bottom: 20px;
        line-height: 100%;
      }
      .desc {
        font-family: "Pretendard-Regular";
        font-size: 16px;
        letter-spacing: -0.5px;
        line-height: 25.6px;
      }
    }
    .btn {
      width: 100%;
      border: none;
      padding: 20px;
      line-height: 100%;
      color: $white;
      font-family: "Pretendard-Medium";
      background: $main-purple-dark-1;
      cursor: pointer;
    }
  }
}

input {
  caret-color: $main-purple-dark-1;
}
