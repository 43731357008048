.navbar {
  position: fixed;
  z-index: 10;
  bottom: 0;
  width: 100%;
  min-width: 375px;
  max-width: 750px;
  height: 48px;
  display: flex;
  justify-content: space-around;
  border-top: 1px solid $grey-300;
  padding: 4px 10px 8px;
  font-family: "Pretendard-SemiBold";
  background-color: $white;
  .tab-btn {
    display: flex;
    flex-direction: column;
    align-items: center;
    cursor: pointer;
    width: 44px;
    height: 44px;
    .tab-img {
      width: 24px;
      height: 24px;
    }
    &.request {
      transform: translateY(-65%);
      .request-btn {
        flex: 0 0 auto;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 44px;
        height: 44px;
        aspect-ratio: 1/1;
        background: linear-gradient(147.48deg, #7f76ff -0.79%, #642ffa 106.5%);
        box-shadow: 0px 2px 8px rgba(127, 118, 255, 0.45);
        border-radius: 50%;
        margin-bottom: 2px;
      }
    }
    span {
      font-size: 10px;
      line-height: 100%;
      font-family: "Pretendard-Regular";
      color: $grey-600;
      margin-top: 2px;
    }
    &.active {
      svg {
        path {
          fill: $purple-700;
        }
      }
      span {
        color: $purple-700;
      }
    }
  }
}
