.terms {
  padding: 18px 12px;
  color: $grey-8;
  background-color: $grey-0;
  font-family: "Pretendard-Light";

  h6 {
    font-family: "Pretendard-SemiBold";
    font-size: 12px;
    line-height: 19px;
    letter-spacing: -0.6px;

    &:first-child {
      margin-top: 0;
    }
    margin-top: 12px;
  }
  
  .content {
    font-size: 12px;
    line-height: 16px;
    letter-spacing: -0.6px;
    // font-family: "Pretendard-Regular";
    white-space: inherit;
    word-wrap: break-word;
    word-break: normal;
    margin-top: 2px;
  }
}