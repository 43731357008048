.review-report {
  //
  .title {
    font-family: "Pretendard-Medium";
    font-size: 13px;
    letter-spacing: -0.32px;
    color: $grey-9;
    margin-bottom: 9px;
  }
  .report-reason {
    margin-top: 14px;
    margin-bottom: 30px;
    padding: 0 18px;
    .selectbox {
      position: relative;
      width: 100%;
      &.active .optionList {
        max-height: 500px;
        overflow-y: scroll;
        opacity: 1;
      }
      .selectbox-btn {
        &.selected {
          background: $white;
          color: $grey-8;
          .arrow {
            background: url(../../../assets/img/icdropdown24Active.svg)
              no-repeat center / contain;
          }
        }
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        height: 43px;
        border-radius: 4px;
        border: 1px solid $grey-1;
        padding-left: 15px;
        padding-right: 10px;
        color: $grey-5;
        background: $grey-0;
        font-family: "Pretendard-Regular";
        font-size: 14px;
        letter-spacing: -0.42px;
        cursor: pointer;
        display: flex;
        .arrow {
          width: 24px;
          height: 24px;
          background: url(../../../assets/img/icdropdown24Inactive.svg)
            no-repeat center / contain;
          pointer-events: none;
        }
      }
      .optionList {
        box-shadow: 0 3px 8px 0 rgba(85, 85, 85, 0.08);
        padding: 3px 0px;
        position: absolute;
        top: 41px;
        left: 0;
        z-index: 1;
        width: 100%;
        background: $white;
        color: $grey-5;
        border-radius: 4px;
        overflow: hidden;
        max-height: 0;
        transition: 0.2s linear;
        display: flex;
        flex-direction: column;
        opacity: 0;
        .optionItem {
          cursor: pointer;
          padding: 8px 20px;
          transition: 0.1s;
          display: block;
          font-family: "Pretendard-Regular";
          font-size: 14px;
          letter-spacing: -0.35px;
          &:not(.optionItem:last-child) {
            border-bottom: 0.5px solid $grey-1;
          }
          .item-label {
            width: 100%;
            display: flex;
            align-items: center;
            &::after {
              content: "";
              display: inline-block;
              width: 24px;
              height: 24px;
              background: none;
            }
          }
          .item-input {
            display: none;
          }
          &.active {
            .item-label {
              color: $main-purple-0;
              &::after {
                content: "";
                display: inline-block;
                width: 24px;
                height: 24px;
                background: url(../../../assets/img/icon_check_purple_24.svg)
                  no-repeat center / contain;
              }
            }
          }
        }
      }
    }
  }
  .report-comment {
    padding: 0 18px;
    .textarea {
      &::placeholder {
        color: $grey-5;
      }
      &:focus {
        outline: 1px solid $main-purple-light-4;
      }
      font-family: "Pretendard-Regular";
      letter-spacing: -0.45px;
      font-size: 15px;
      margin-bottom: 14px;
      padding: 14px 15px;
      color: $grey-8;
      resize: none;
      width: 100%;
      height: 145px;
      border: 1px solid $grey-1;
      border-radius: 6px;
      outline: none;
      caret-color: $main-purple-dark-1;
      line-height: 20px;
    }
    .info {
      color: $grey-8;
      font-family: "Pretendard-Regular";
      font-size: 11px;
      letter-spacing: -0.33px;
    }
  }
}
