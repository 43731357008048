@font-face {
  font-family: "Pretendard-Light";
  src: url("../../assets/fonts/Pretendard-Light.woff2") format("woff2"),
    url("../../assets/fonts/Pretendard-Light.woff") format("woff");
  font-weight: 300;
  font-style: normal;
}
@font-face {
  font-family: "Pretendard-Regular";
  src: url("../../assets/fonts/Pretendard-Regular.woff2") format("woff2"),
    url("../../assets/fonts/Pretendard-Regular.woff") format("woff");
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: "Pretendard-Medium";
  src: url("../../assets/fonts/Pretendard-Medium.woff2") format("woff2"),
    url("../../assets/fonts/Pretendard-Medium.woff") format("woff");
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: "Pretendard-SemiBold";
  src: url("../../assets/fonts/Pretendard-SemiBold.woff2") format("woff2"),
    url("../../assets/fonts/Pretendard-SemiBold.woff") format("woff");
  font-weight: 600;
  font-style: normal;
}
@font-face {
  font-family: "Pretendard-Bold";
  src: url("../../assets/fonts/Pretendard-Bold.woff2") format("woff2"),
    url("../../assets/fonts/Pretendard-Bold.woff") format("woff");
  font-weight: 700;
  font-style: normal;
}
