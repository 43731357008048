.header {
  width: 100%;
  height: 56px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid $grey-1;
  position: sticky;
  left: 0;
  top: 0;
  z-index: 10;
  padding: 0 16px;
  background-color: $white;
  .left-btn {
    width: 24px;
    height: 24px;
    .left-btn-icon {
      width: 100%;
      height: 100%;
      background: url(../../assets/img/lwhite.svg) no-repeat center / contain;
      cursor: pointer;
      &.black {
        background-image: url(../../assets/img/icBack24Black.svg);
      }
    }
  }
  .header-name {
    font-family: "Pretendard-SemiBold";
    font-size: 18px;
    display: flex;
    align-items: center;
    justify-content: center;
    letter-spacing: -0.5px;
    color: $grey-9;
    .logo {
      width: 144px;
      height: 36px;
      background: url(../../assets/img/logoText.svg) no-repeat center / contain;
    }
  }
  .right-btn {
    width: 24px;
    height: 24px;
    .right-btn-icon {
      cursor: pointer;
      width: 24px;
      height: 24px;
      background-position: center;
      background-repeat: no-repeat;
      background-size: contain;
    }
  }
}
