.post-modal {
  // display: flex;
  // flex-direction: column;
  // justify-content: center;
  // align-items: center;
  // width: 100vw;
  width: 100%;
  max-width: 750px;
  min-height: 100vh;
  height: 100vh;
  // position: fixed;
  position: absolute;
  // position: sticky;
  z-index: 100;
  top: 0;
  bottom: 50%;
  // bottom: 0;
  // left: 0;
  // right: 0;
  // left: 50%;
  // transform: translatex(-50%);
  background-color: $white;

  .modal-header {
    height: 56px;
    border-bottom: 1px solid $grey-1;
    position: relative;
    font-size: 18px;
    font-family: "Pretendard-Bold";
    display: flex;
    justify-content: center;
    align-items: center;

    button {
      position: absolute;
      right: 0;
      padding: 16px;
      border: none;
      background-color: transparent;
    }
  }

  .new-post-modal {
    height: 100vh;
    // min-height: 100vh;
    // top: 0;
    // bottom: 0;
    // left: 0;
    // right: 0;
    // min-height: 800px;
    // min-height: 100vh;
    // min-height: 1048px;
    // max-height: 800px;
    // height: calc(100vh - 56px - 50px - 107.5px);
    // height: calc(100vh - 92px);
    // display: flex;
    flex-direction: column;
    padding: 0 16px;
    background-color: $white;

    .sub-title {
      label {
        width: 100%;
        display: block;
        color: $new-black;
        font-family: "Pretendard-Medium";
        font-size: 15px;
        letter-spacing: -0.38px;
        margin: 18px 0 10px;
      }
      input {
        width: 100%;
        display: inline-block;
        color: $grey-5;
        font-size: 15px;
        line-height: 44px;
        border: 1px solid $grey-1;
        text-indent: 15px;
      }
    }

    .zonecode-area {
      margin-bottom: 9px;
      #post-zonecode {
        width: 43%;
      }
      button {
        width: 33%;
        height: 45px;
        background-color: $main-purple-0;
        color: $white;
        border-radius: 22.5px;
        border: 1px solid $main-purple-0;
        font-size: 15px;
        margin-left: 6px;
        cursor: pointer;
      }
    }
    .address-area {
      input {
        &:first-child {
          margin-bottom: 8px;
        }
      }
    }
  }

  // .submit-btn {
  //   position: absolute;
  //   bottom: 0;
  //   width: 100%;
  //   max-width: 750px;
  //   // position: absolute;
  //   // bottom: 0;
  //   // height: 92px;
  //   height: 200px;
  // }

  .daum-post-api {
    width: 100%;
    min-width: 375px;
    max-width: 750px;
    position: fixed;
    z-index: 20;
    transition: all 0.3s linear;
    background-color: $white;
    border-radius: 10px 10px 0 0;

    .main-title-area {
      padding: 26px 0 13px;
      position: relative;

      .main-title {
        margin-left: 16px;
        font-size: 20px;
        font-family: "Pretendard-SemiBold";
        letter-spacing: -0.5px;
        color: $new-black;
      }
      .close-icon {
        width: 24px;
        height: 24px;
        position: absolute;
        right: 16px;
        top: 2 6px;
        cursor: pointer;

        img {
          display: block;
          width: 100%;
        }
      }
    }
  }

  // .full-black {
  //   display: none;
  //   z-index: 10;
  //   position: fixed;
  //   width: 100%;
  //   height: 100%;
  //   top: 0;
  //   min-width: 375px;
  //   max-width: 750px;
  //   background-color: $grey-9;
  //   font-size: 0;
  //   text-indent: -9999px;
  // }
}
