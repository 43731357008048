.request {
  // display: flex;
  // flex-direction: column;
  // align-items: center;
  height: 100%;
  width: 100%;
  // padding: 16px 16px 56px;
  // margin-top: 60px !important;

  .step-percent {
    width: 33%;
    border-top: 2px solid $main-purple-0;
  }

  .step {
    font-family: "Pretendard-Medium";
    font-size: 14px;
    line-height: 20px;
    letter-spacing: -0.5px;
  }

  .explain {
    font-size: 20px;
    line-height: 32px;
    letter-spacing: -0.5px;
    font-family: "Pretendard-Bold";
    margin-top: 20px;
  }

  .content-wrapper {
    padding: 4px 0;

    .request-content {
      min-width: 343px;
      width: 100%;
      min-height: 96px;
      border: 1px solid $grey-1;
      border-radius: 6px;
      display: flex;
      align-items: center;
      padding: 24px;
      margin: 12px 0;
      background-color: white;
      cursor: pointer;

      .base-circle {
        width: 56px;
        height: 56px;
        background-color: $main-purple-light-1;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
      }
      .base-text {
        font-size: 18px;
        line-height: 30px;
        letter-spacing: -0.5px;
        color: $grey-9;
        font-family: "Pretendard-Bold";
        // margin-left: 20px;
        width: calc(100% - 56px);
        text-align: center;
      }
      .base-sub {
        font-size: 14px;
        line-height: 24px;
        letter-spacing: -0.5px;
        font-family: "Pretendard-Regular";
        color: $grey-6;
        // margin-left: 20px;
      }
      .beta-icon {
        position: absolute;
        right: 8px;
        top: -10px;
        border: 1px solid $grey-1;
        padding: 5px 9px;
        border-radius: 6px;
        font-weight: 700;
        font-size: 11px;
        color: #000;
        letter-spacing: -0.2px;
        background-color: $grey-1;
      }
      &.active {
        border: 1px solid $main-purple-0;
        background-color: #faf9ff;
        p {
          color: $main-purple-0;
        }

        .beta-icon {
          position: absolute;
          right: 8px;
          top: -10px;
          border: 1px solid $main-purple-0;
          padding: 5px 9px;
          border-radius: 6px;
          font-weight: 700;
          font-size: 11px;
          color: $main-purple-0;
          letter-spacing: -0.2px;
          background-color: #fff;
        }
      }
    }
  }
  .entered-wrapper {
    // border-top: 1px solid $grey-1;
    background-color: $white;
    position: fixed;
    bottom: 0;
    min-width: 343px;
    width: 100%;
    max-width: 750px;
    min-height: 96px;
    z-index: 10;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 16px;

    .request-entered {
      width: 100%;
      min-height: 48px;
      border: none;
      border-radius: 4px;
      font-size: 16px;
      line-height: 24px;
      font-family: "Pretendard-Medium";
      // background-color: $main-purple-dark-1;
      background-color: $grey-3;

      p {
        color: $white;
      }

      &.selected {
        background-color: $main-purple-dark-1;
      }
    }
  }

  .cancel-modal {
    .sub-title-wrapper {
      display: flex;
      justify-content: center;
      padding-top: 24px;

      .sub-btn {
        display: flex;
        align-items: center;
        margin: 0 8px;
        cursor: pointer;

        .btn {
          width: 24px;
          margin-right: 8px;
        }
      }
    }
  }
}
