.review-all {
  .search-input-area {
    width: 100%;
    position: relative;
    .search-input {
      width: 100%;
      padding: 12px 64px 12px 12px;
      border: 1px solid $grey-1;
      font-family: "Pretendard-Regular";
      font-size: 14px;
      letter-spacing: -0.45px;
      color: $grey-9;
      outline: none;
      caret-color: $main-purple-dark-1;
      border-radius: 4px;
      background: $white;
      &::placeholder {
        color: $grey-5;
      }
    }
    .delete-btn {
      all: unset;
      width: 24px;
      height: 24px;
      background: url(../../../assets/img/icInputDelete24Gray.svg) no-repeat
        center / contain;
      position: absolute;
      bottom: 9px;
      right: 40px;
      cursor: pointer;
    }
    .search-btn {
      all: unset;
      width: 24px;
      height: 24px;
      background: url(../../../assets/img/icSearch24Black.svg) no-repeat center /
        contain;
      position: absolute;
      bottom: 9px;
      right: 12px;
      cursor: pointer;
    }
  }
  .control-area {
    padding: 16px 16px 0px;
    .row {
      display: flex;
    }
    .sort-area {
      justify-content: space-between;
      align-items: center;
      margin-bottom: 8px;
      .all-num {
        font-family: "Pretendard-Bold";
        font-size: 16px;
        letter-spacing: -0.48px;
        color: $grey-9;
      }
      .sort-btn-area {
        display: flex;
        align-items: center;
        column-gap: 13px;
        .sort-btn {
          &.active {
            color: $grey-8;
            &::before {
              background: $main-purple-0;
            }
          }
          &::before {
            display: inline-block;
            content: "";
            width: 5px;
            height: 5px;
            border-radius: 50%;
            background: $grey-3;
          }
          display: flex;
          align-items: center;
          column-gap: 4px;
          cursor: pointer;
          background: transparent;
          border: none;
          color: $grey-3;
        }
      }
    }
    .filter-area {
      display: flex;
      column-gap: 4px;
      padding: 8px 0px;
      .filter-btn {
        &.filter-reload-btn {
          padding: 4px 8px 4px 4px;
        }
        font-size: 13px;
        font-family: "Pretendard-Regular";
        color: $grey-8;
        &.filter-arrow-btn {
          &.active {
            background: $main-purple-light-1;
            border: 1px solid $main-purple-0;
            color: $main-purple-dark-1;
            .filter-btn-icon {
              &.arrow-icon {
                background-image: url(../../../assets/img/icDropdown24Purple.svg);
              }
            }
          }
          padding: 8px 0 8px 12px;
        }
        min-width: 72px;
        height: 32px;
        border: 1px solid $grey-1;
        background: $white;
        border-radius: 4px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        cursor: pointer;
        .filter-btn-icon {
          width: 24px;
          height: 24px;
          background-position: center;
          background-size: cover;
          background-repeat: no-repeat;
          &.reload-icon {
            background-image: url(../../../assets/img/icReset24BlackS.svg);
          }
          &.arrow-icon {
            background-image: url(../../../assets/img/icDropdown24Black.svg);
          }
        }
      }
    }
  }
  .review-item-box {
    padding: 24px 16px;
    border-bottom: 1px solid $grey-1;
    .point-area {
      display: flex;
      align-items: center;
      margin-bottom: 17px;
      .star {
        &.active {
          background: url(../../../assets/img/icStrar14On.svg) no-repeat center /
            contain;
        }
        width: 14px;
        height: 14px;
        background: url(../../../assets/img/icStrar14Off.svg) no-repeat center /
          contain;
      }
    }
    .row-1 {
      display: flex;
      justify-content: space-between;
      margin-bottom: 12px;
      .line-0 {
        display: flex;
        justify-content: space-between;
        align-items: center;
        .line-1 {
          display: flex;
          gap: 8px;
          font-size: 14px;
          line-height: 100%;
          letter-spacing: -0.5px;
          font-family: "Pretendard-Medium";
          color: $grey-900;
          .bar {
            color: $grey-300;
          }
          .line-2 {
            display: flex;
            align-items: center;
            gap: 4px;
          }
          .star-img {
            width: 16px;
            height: 16px;
            background: url(../../../assets/img/icon/16px/star_full.svg)
              no-repeat center / contain;
          }
          .name {
            font-family: "Pretendard-Regular";
            color: $grey-600;
          }
        }
        .report-btn {
          font-family: "Pretendard-Regular";
          font-size: 14px;
          line-height: 100%;
          letter-spacing: -0.5px;
          color: $grey-600;
        }
      }
    }
    .img-area {
      display: flex;
      column-gap: 8px;
      margin-bottom: 12px;
      .data-img {
        width: 50%;
        aspect-ratio: 1/1;
        border-radius: 6px;

        position: relative;
        overflow: hidden;
        .data-img-img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          object-position: center center;
        }
        .data-img-caption {
          background: rgba(0, 0, 0, 0.6);
          width: fit-content;
          font-family: "Pretendard-Medium";
          font-size: 12px;
          line-height: 16px;
          letter-spacing: -0.3px;
          color: $white;
          position: absolute;
          bottom: 0px;
          padding: 4px 8px;
          &.before {
            left: 0px;
            border-radius: 0 4px 0 0;
          }
          &.after {
            right: 0px;
            border-radius: 4px 0 0 0;
          }
        }
      }
    }
    .tags-area {
      display: flex;
      gap: 8px;
      margin-bottom: 8px;
      word-wrap: break-word;
      overflow-x: hidden;
      text-overflow: ellipsis;
      .tag {
        flex: 0 0 auto;
        padding: 3px 8px;
        width: fit-content;
        border-radius: 4px;
        font-family: "Pretendard-Regular";
        white-space: nowrap;
        font-size: 12px;
        color: $grey-700;
        line-height: 22px;
        letter-spacing: -0.5px;
        background-color: $grey-200;
        cursor: default;
      }
    }
    .message-area {
      margin-bottom: 16px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .message {
        font-family: "Pretendard-Regular";
        font-size: 14px;
        line-height: 24px;
        letter-spacing: -0.5px;
        text-overflow: ellipsis;
        overflow: hidden;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
      }
      .view-more-btn {
        flex: 0 0 auto;
        line-height: 22px;
        font-family: "Pretendard-Regular";
        font-size: 14px;
        letter-spacing: -0.5px;
        color: $grey-4;
        text-decoration: underline;
        padding-left: 4px;
      }
    }
    .likes-btn {
      &.active {
        border: 1px solid $purple-500;
        color: $purple-700;
        background: $purple-50;
        .grey-800 {
          color: $purple-700;
        }
      }
      svg {
        stroke: currentColor;
      }
      all: unset;
      margin-top: 16px;
      cursor: pointer;
      border: 1px solid $grey-300;
      padding: 10px 12px;
      border-radius: 99px;
      display: flex;
      align-items: center;
      gap: 4px;
      font-size: 12px;
      line-height: 100%;
      letter-spacing: -0.3px;
      font-family: "Pretendard-Medium";
      color: $grey-600;
      .grey-800 {
        color: $grey-800;
      }
    }
  }
  .have-no-result-text {
    min-height: calc(100vh - 56px - 91px);
    display: flex;
    justify-content: center;
    padding-top: 80px;
    font-size: 15px;
    font-family: "Pretendard-Regular";
    letter-spacing: -0.38px;
    color: $grey-5;
  }
  .scroll-to-top-btn-wrap {
    position: fixed;
    bottom: 48px;
    left: 50%;
    z-index: 10;
    transform: translateX(-50%);
    width: 100%;
    min-width: 375px;
    max-width: 750px;
    padding: 0 12px;
    pointer-events: none;
    display: flex;
    justify-content: flex-end;
    .scroll-to-top-btn {
      pointer-events: auto;
      flex: 0 0 auto;
      width: 40px;
      height: 40px;
      background: rgba(232, 235, 237, 0.75);
      border-radius: 50%;
      border: 0.5px solid rgb(214, 219, 222);
      box-shadow: 0 1px 2px 0 rgba(108, 114, 121, 0.4);
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      .img {
        width: 24px;
        height: 24px;
        background: url(../../../assets/img/icTop24Gray.svg) no-repeat center /
          contain;
      }
    }
  }
}

// slideup-modalpopup-tagcontents
.slideup-modalpopup-tagcontents {
  // border-bottom: 1px solid $grey-1;
  padding: 0 16px 24px;
  margin-top: 24px;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  column-gap: 8px;
  row-gap: 8px;
  .tag-box {
    &.active {
      border: 1px solid $main-purple-0;
      .tag-name {
        color: $main-purple-dark-1;
      }
    }
    width: calc(50% - 4px);
    border-radius: 4px;
    border: 1px solid $grey-1;
    height: 48px;
    display: flex;
    align-items: center;
    column-gap: 20px;
    padding-left: 20px;
    .tag-img {
      width: 32px;
      height: 32px;
      background-position: center;
      background-repeat: no-repeat;
      background-size: contain;
    }
    .tag-name {
      font-family: "Pretendard-Regular";
      font-size: 14px;
      letter-spacing: -0.35px;
      color: $grey-6;
    }
  }
}

// slideup-modalpopup-brandcontents
.slideup-modalpopup-brandcontents {
  max-height: calc(var(--vh, 1vh) * 100 - 56px - 50px - 107.5px);
  overflow-y: scroll;
  overflow-x: hidden;
  margin-top: 13px;
  position: relative;
  .brandcontents-control-area {
    // padding: 0 16px;
    border-bottom: 1px solid $grey-1;
    .search-area {
      margin: 0 16px;
      position: relative;
      margin-bottom: 12px;
      .input-search {
        &::placeholder {
          color: $grey-5;
        }
        &::-webkit-search-cancel-button {
          position: relative;
          right: 6px;
        }
        width: 100%;
        border: none;
        outline: none;
        padding: 12px 36px 12px 42px;
        border-radius: 4px;
        color: $grey-8;
        background: $grey-0;
        font-size: 15px;
        letter-spacing: -0.45px;
        font-family: "Pretendard-Regular";
        caret-color: $main-purple-dark-1;
      }
      .search-img {
        width: 24px;
        height: 24px;
        background: url(../../../assets/img/icSearch24Gray.svg) no-repeat center /
          contain;
        position: absolute;
        left: 10px;
        top: 9px;
      }
      .delete-btn {
        width: 24px;
        height: 24px;
        background: url(../../../assets/img/icInputDelete24Gray.svg) no-repeat
          center / contain;
        position: absolute;
        right: 11px;
        top: 10px;
        // pointer-events: none;
      }
    }
    .sort-area {
      margin-left: 16px;
      display: flex;
      flex-wrap: nowrap;
      align-items: center;
      column-gap: 8px;
      margin-bottom: 11px;
      .sort-lang-btn {
        display: flex;
        flex: 0 0 auto;
        align-items: center;
        padding: 5px 11px 5px 8px;
        border-radius: 17px;
        border: 1px solid $grey-1;
        background: $white;
        color: $grey-5;
        font-family: "Pretendard-Regular";
        font-size: 15px;
        letter-spacing: -0.38px;
        .img {
          width: 24px;
          height: 24px;
          background: url(../../../assets/img/26202.svg) no-repeat center /
            contain;
        }
      }
      .link-list {
        &::-webkit-scrollbar {
          display: none;
        }
        overflow-x: scroll;
        display: flex;
        align-items: center;
        column-gap: 4px;
        .link-item {
          &.active {
            .link-btn {
              background: $main-purple-dark-1;
              color: $white;
            }
          }
          input {
            display: none;
          }
          .link-btn {
            width: 32px;
            height: 32px;
            flex: 0 0 auto;
            border-radius: 50%;
            border: none;
            background: $grey-0;
            display: flex;
            align-items: center;
            justify-content: center;
            font-family: "Pretendard-Regular";
            font-size: 15px;
            letter-spacing: -0.38px;
            color: $grey-5;
            line-height: 22px;
            cursor: pointer;
          }
        }
      }
    }
  }
  .checkbox-area {
    height: calc(var(--vh, 1vh) * 100 - 56px - 50px - 107.5px - 115px);
    overflow-y: scroll;
    border-top: 8px solid $grey-0;
    padding: 4px 0px 0px;
    scroll-behavior: smooth;
    .checkbox-li {
      &.active {
        label {
          .img {
            background: url(../../../assets/img/icCheckbox18On.svg) no-repeat
              center / contain;
          }
        }
      }
      list-style-type: none;
      display: flex;
      align-items: center;
      height: 46px;
      padding-left: 18px;
      input {
        display: none;
      }
      label {
        display: flex;
        align-items: center;
        .img {
          width: 18px;
          height: 18px;
          background: url(../../../assets/img/icCheckboxB18Off03.svg) no-repeat
            center / contain;
          margin-right: 16px;
        }
        .lang1 {
          font-family: "Pretendard-Regular";
          font-size: 14px;
          letter-spacing: -0.35px;
          color: $grey-8;
          margin-right: 8px;
          span {
            &.active {
              color: $main-purple-dark-1;
            }
          }
        }
        .lang2 {
          font-family: "Pretendard-Regular";
          font-size: 12px;
          letter-spacing: -0.3px;
          color: $grey-5;
          span {
            &.active {
              color: $main-purple-dark-1;
            }
          }
        }
      }
    }
  }
  .checked-area {
    width: 100%;
    overflow: hidden;
    border-top: 1px solid $grey-1;
    height: 48px;
    position: sticky;
    bottom: 12px;
    right: 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: $white;
    .checked-list {
      width: 100%;
      overflow-x: scroll;
      display: flex;
      align-items: center;
      margin-left: 16px;
      column-gap: 5px;
      .checked-item {
        display: flex;
        align-items: center;
        background: $white;
        border: 1px solid $grey-1;
        border-radius: 4px;
        flex: 0 0 auto;
        padding: 4px 1px 4px 11px;
        .brand-name {
          font-family: "Pretendard-Regular";
          font-size: 13px;
          letter-spacing: -0.39px;
          color: $grey-8;
        }
        .delete-btn {
          width: 24px;
          height: 24px;
          background: url(../../../assets/img/icTagDelete24.svg) no-repeat
            center/ contain;
        }
      }
    }
    .view-more-btn {
      width: 24px;
      height: 24px;
      background: url(../../../assets/img/icRoundExtend24.svg) no-repeat center /
        contain;
      border: none;
      margin: 12px;
      cursor: pointer;
    }
  }
  .checked-popup-wrap {
    background: rgba(0, 0, 0, 0.75);
    position: fixed;
    left: 50%;
    top: 0;
    transform: translateX(-50%);
    min-width: 375px;
    max-width: 750px;
    width: 100%;
    height: calc(var(--vh, 1vh) * 100 - 107.5px);
    .checked-popup {
      animation: slideUpAni 0.15s linear;
      position: absolute;
      left: 0;
      bottom: 0;
      background: $white;
      width: 100%;
      height: 75%;
      padding: 26px 16px 0;
      border-radius: 10px 10px 0 0;
      .row1 {
        display: flex;
        justify-content: space-between;
        margin-bottom: 12px;
        .title {
          font-family: "Pretendard-SemiBold";
          font-size: 20px;
          letter-spacing: -0.5px;
          color: $new-black;
        }
        .close-btn {
          width: 24px;
          height: 24px;
          background: url(../../../assets/img/icRoundDown24.svg) no-repeat
            center / contain;
          cursor: pointer;
        }
      }
      .list {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        row-gap: 7px;
        column-gap: 5px;
        .checked-item {
          width: fit-content;
          display: flex;
          align-items: center;
          background: $white;
          border: 1px solid $grey-1;
          border-radius: 4px;
          flex: 0 0 auto;
          padding: 4px 1px 4px 11px;
          .brand-name {
            font-family: "Pretendard-Regular";
            font-size: 13px;
            letter-spacing: -0.39px;
            color: $grey-8;
          }
          .delete-btn {
            width: 24px;
            height: 24px;
            background: url(../../../assets/img/icTagDelete24.svg) no-repeat
              center/ contain;
          }
        }
      }
    }
  }
}

// =====================================
.review-detail {
  min-height: calc(100vh - 56px);
  .review-info {
    padding: 18px 16px;
    border-bottom: 1px solid $grey-1;
    .name-regtime-buttons-line {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 10px;
      .name-regtime {
        font-family: "Pretendard-Regular";
        font-size: 14px;
        color: $grey-8;
        .regtime {
          color: $grey-5;
          letter-spacing: -0.35px;
        }
      }
      .buttons {
        display: flex;
        column-gap: 12px;
        .btn {
          color: $grey-5;
          font-family: "Pretendard-Regular";
          font-size: 14px;
          letter-spacing: -0.35px;
        }
      }
    }
    .review-point {
      display: flex;
      align-items: center;
      margin-bottom: 17px;
      .star {
        &.active {
          background: url(../../../assets/img/icStrar18On.svg) no-repeat center /
            contain;
        }
        width: 18px;
        height: 18px;
        background: url(../../../assets/img/icStrar18Off.svg) no-repeat center /
          contain;
      }
    }
    .satisfaction {
      margin-bottom: 12px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .col {
        display: flex;
        align-items: center;
        font-family: "Pretendard-SemiBold";
        font-size: 12px;
        letter-spacing: -0.3px;
        color: $grey-8;
        .title {
          color: $main-purple-0;
          letter-spacing: -0.25px;
          font-size: 10px;
          border: 1px solid $main-purple-light-4;
          padding: 4px 9px;
          border-radius: 10px;
          margin-right: 5px;
        }
      }
    }
    .repair-info {
      border: 1px solid $grey-1;
      padding: 20px 18px 16px;
      border-radius: 10px;
      .row {
        display: flex;
        margin-bottom: 9px;
        font-size: 14px;
        letter-spacing: -0.35px;
        color: $grey-8;
        &.row3 {
          margin-bottom: 16px;
        }
        &.row4 {
          margin-bottom: 12px;
        }
        .name {
          width: 93px;
          font-family: "Pretendard-SemiBold";
        }
        .data {
          font-family: "Pretendard-Regular";
          .add-text {
            color: $grey-5;
          }
        }
      }
      .tags {
        display: flex;
        flex-wrap: wrap;
        column-gap: 4px;
        row-gap: 5px;
        .tag {
          font-family: "Pretendard-Regular";
          font-size: 13px;
          letter-spacing: -0.39px;
          color: $grey-8;
          padding: 8px 11px;
          border: 1px solid $grey-1;
          border-radius: 4px;
        }
      }
    }
  }
  .review-images {
    // border-top: 10px solid $grey-0;
    padding: 18px 16px;
    .before,
    .after {
      .slider-container {
        margin-bottom: 24px;
        .dots {
          width: fit-content;
          margin: 0 auto;
          li {
            &.slick-active {
              button {
                &::before {
                  background: url(../../../assets/img/icDot11Black.svg)
                    no-repeat center / contain;
                }
              }
            }
            display: inline-block;
            position: relative;
            width: 11px;
            height: 11px;
            margin-top: 8px;
            button {
              &::before {
                content: "";
                width: 11px;
                height: 11px;
                background: url(../../../assets/img/icDot11Gray.svg) no-repeat
                  center / contain;
                position: absolute;
                left: 0;
                top: 0;
              }
              all: unset;
              font-size: 0;
              line-height: 0;
              color: transparent;
              width: 11px;
              height: 11px;
            }
          }
        }
        .slider-item {
          width: 100%;
          aspect-ratio: 1.6/1;
          border-radius: 6px;
          overflow: hidden;
          .slider-item-img {
            width: 100%;
            height: 100%;
            background-position: center;
            background-repeat: no-repeat;
            background-size: cover;
            position: relative;
            .index-info {
              width: 38px;
              height: 22px;
              border-radius: 11px;
              background: rgba(48, 50, 52, 0.7);
              font-size: 11px;
              font-family: "Pretendard-SemiBold";
              color: $white;
              display: flex;
              justify-content: center;
              align-items: center;
              position: absolute;
              right: 10px;
              bottom: 12px;
            }
          }
        }
      }
      .title {
        font-family: "Pretendard-SemiBold";
        font-size: 16px;
        letter-spacing: -0.4px;
        color: $grey-9;
        margin-bottom: 14px;
      }
    }
    .before {
      margin-bottom: 24px;
    }
    .after {
      margin-bottom: 30px;
    }
    .message {
      font-family: "Pretendard-Regular";
      font-size: 14px;
      letter-spacing: -0.42px;
      color: $grey-8;
      margin-bottom: 16px;
      line-height: 21px;
    }
    .likes-btn {
      &.active {
        border: 1px solid $main-purple-0;
        color: $main-purple-dark-1;
        .likes-img {
          background: url(../../../assets/img/icThumb24On.svg) no-repeat center /
            contain;
        }
      }
      border: 1px solid $grey-1;
      background: $white;
      border-radius: 99px;
      padding: 6px 13px 7px 7px;
      display: flex;
      align-items: center;
      font-size: 12px;
      color: $grey-7;
      font-family: "Pretendard-Regular";
      .likes-img {
        width: 24px;
        height: 24px;
        background: url(../../../assets/img/icThumb24Off.svg) no-repeat center /
          contain;
        margin-right: 1px;
      }
      .likes-count {
        font-family: "Pretendard-Bold";
        margin-left: 6px;
      }
    }
  }
}

//review-image-slider-popup
.review-image-slider-popup {
  touch-action: none;
  width: 100vw;
  max-width: 750px;
  height: 100vh;
  @media only screen and (max-device-width: 750px) {
    height: calc(var(--vh, 1vh) * 100);
  }
  max-height: 100vh;
  overflow: hidden;
  background: $new-black;
  position: fixed;
  left: 50%;
  top: 0;
  transform: translateX(-50%);
  z-index: 100000000;
  font-family: "Pretendard-Medium";
  color: $white;
  .wrap {
    position: relative;
    width: 100%;
    height: 100%;
    overflow: hidden;
    display: flex;
    align-items: center;
    .slider-popup-header {
      width: 100%;
      height: 56px;
      position: absolute;
      left: 0;
      top: 0;
      z-index: 17;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0 16px;
      font-size: 17px;
      background: rgba(0, 0, 0, 0.5);
      .btn {
        &.right {
          background: url(../../../assets/img/icClose24White.svg) no-repeat
            center / contain;
          cursor: pointer;
        }
        width: 24px;
        height: 24px;
      }
    }

    .main-slider {
      width: 100%;
      .slider-container {
        touch-action: auto !important;
        :focus {
          outline: none;
        }
        // background: $new-black;
        // display: flex;
        // justify-content: center;
        // align-items: center;
        .slick-list {
          .slick-track {
            display: flex;
            flex-direction: row;
            align-items: center;
          }
        }
        .slider-item {
          width: 100%;
          .slider-item-img {
            width: 100%;
          }
        }
      }
    }

    .slider-popup-nav {
      width: 100%;
      height: fit-content;
      position: absolute;
      left: 0;
      bottom: 0;
      z-index: 50;
      padding: 14px 0 34px;
      background: rgba(0, 0, 0, 0.5);
      .img-category {
        display: flex;
        justify-content: center;
        margin-bottom: 17px;
        .btn {
          &.active {
            border: 1px solid $white;
          }
          all: unset;
          font-family: "Pretendard-SemiBold";
          font-size: 14px;
          padding: 6px 16px;
          border-radius: 14.5px;
          border: 1px solid transparent;
        }
      }
      .remote-wrap {
        display: flex;
        padding: 0 9px;
        column-gap: 9px;
        overflow-x: scroll;
        .scroll-item {
          &.active {
            &::before {
              background: transparent;
            }
          }
          cursor: pointer;
          flex: 0 0 auto;
          width: 48px;
          height: 48px;
          background-position: center;
          background-size: cover;
          background-repeat: no-repeat;
          position: relative;
          &::before {
            content: "";
            width: 100%;
            height: 100%;
            position: absolute;
            left: 0;
            top: 0;
            background: rgba(0, 0, 0, 0.6);
          }
        }
      }
    }
  }
}

// search-message-area
.search-message-area {
  width: 100%;
  height: 56px;
  position: sticky;
  left: 0;
  top: 0;
  z-index: 11;
  min-width: 375px;
  max-width: 750px;
  display: flex;
  column-gap: 16px;
  justify-content: space-between;
  align-items: center;
  padding: 8px 16px;
  background: $white;
  border-bottom: 1px solid $grey-1;
  .left-btn {
    flex: 0 0 auto;
    width: 24px;
    height: 24px;
    background: url(../../../assets/img/icBack24Black.svg) no-repeat center /
      contain;
  }
  .search-input-area {
    width: 100%;
    position: relative;
    .search-input {
      width: 100%;
      padding: 12px 64px 12px 12px;
      border: 1px solid $grey-1;
      font-family: "Pretendard-Regular";
      font-size: 14px;
      letter-spacing: -0.45px;
      color: $grey-9;
      outline: none;
      caret-color: $main-purple-dark-1;
      border-radius: 4px;
      background: $white;
      &::placeholder {
        color: $grey-5;
      }
    }
    .delete-btn {
      all: unset;
      width: 24px;
      height: 24px;
      background: url(../../../assets/img/icInputDelete24Gray.svg) no-repeat
        center / contain;
      position: absolute;
      bottom: 9px;
      right: 40px;
      cursor: pointer;
    }
    .search-btn {
      all: unset;
      width: 24px;
      height: 24px;
      background: url(../../../assets/img/icSearch24Black.svg) no-repeat center /
        contain;
      position: absolute;
      bottom: 9px;
      right: 12px;
      cursor: pointer;
    }
  }
}
