@import "./styles/index.scss";
:root {
  --vh: 100%;
}
body {
  background-color: $white;
  &.unscroll{
    overflow:hidden;
  }
}
.app {
  min-width: 375px;
  max-width: 750px;
  min-height: 100vh;
  // min-height: calc(var(--vh, 1vh) * 100);
  margin: 0 auto;
  background-color: $white;
  box-shadow: 0 2px 4px 0 rgba($grey-8, 0.2);
  // border: 1px solid;
}

