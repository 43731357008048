$main-purple-0: #6c40ff;
$main-purple-dark-1: #642ffa; // purple-700
$main-purple-dark-2: #3c23a1;
$main-purple-dark-3: #2e1d73;
$main-purple-light-1: #faf9ff;
$main-purple-light-2: #ccd1ff;
$main-purple-light-3: #9b99fd;
$main-purple-light-4: #7f76ff; // purple-5000

$white: #fff;
$grey-0: #f7f8f9;
$grey-1: #e8ebed; // grey-300
$grey-2: #c9cdd2;
$grey-3: #b3b8bd;
$grey-4: #9ca2a8;
$grey-5: #848b91;
$grey-6: #6c7279;
$grey-7: #52575c;
$grey-8: #383c40;
$grey-9: #212121; // grey-900
$new-black: #000000;

$red-500: #d32f2f;
$yellow-500: #f9a825;
$green-500: #4caf50;
$blue-500: #0b7aff;

$grey-100: #f7f8f9;
$grey-200: #f3f5f6;
$grey-300: #e8ebed;
$grey-400: #c9cdd2;
$grey-500: #9ca2a8;
$grey-600: #848b91;
$grey-700: #52575c;
$grey-800: #383c40;
$grey-900: #212121;

$purple-50: #faf9ff;
$purple-100: #fef3fe;
$purple-200: #e5e8ff;
$purple-300: #ccd1ff;
$purple-400: #a3a1fb;
$purple-500: #7f76ff;
$purple-600: #6c40ff;
$purple-700: #642ffa;
$purple-800: #4829c0;
