.tabmenu {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-around;
  min-width: 320px;
  // width: 44vw;
  font-size: 14px;
  font-family: "Pretendard-Medium";
  color: $grey-9;
  display: flex;

  .enter {
    margin-left: auto;
  }
}